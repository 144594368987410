import { Formik } from 'formik'
import { rootDomain } from '../../../constants/domain'
import { createScheme, required } from '../../../utils/schemas'
import { Button } from '../../Button'
import { Form, Field } from '../../Form'
import { Grid } from '../../Grid'
import { Text } from '../../Text'
import { Modal } from '../index'
import styles from './styles.module.scss'

const Schema = createScheme({
  name: required,
  subdomain: required,
})

type FormValues = {
  name: string
  subdomain: string
}

interface Props {
  saving: boolean
  onSubmit: (values: FormValues) => void
  onCancel: () => void
  name: string
  subdomain: string
}

function ModalProfileDomainEdit({
  saving,
  onSubmit,
  onCancel,
  name,
  subdomain,
}: Props) {
  const initialValue: FormValues = {
    name: name || '',
    subdomain: subdomain.replace(rootDomain, '') || '',
  }

  const handleSubmit = (values: FormValues) => {
    onSubmit({
      ...values,
      subdomain: `${values.subdomain}${rootDomain}`,
    })
  }

  return (
    <Modal
      title="Workspace Settings"
      onClose={onCancel}
      className={styles.modal}
    >
      <Formik
        initialValues={initialValue}
        validationSchema={Schema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Grid className={styles.form} columnGap={16}>
            <Grid.Item>
              <Field
                type="text"
                label="Name"
                name="name"
                placeholder="Enter name"
              />
            </Grid.Item>
            <div className={styles.row}>
              <Field
                type="text"
                label="Domain"
                name="subdomain"
                placeholder="Enter domain"
                fieldClassName={styles.input}
              />
              <div className={styles.brand}>
                <Text>{rootDomain}</Text>
              </div>
            </div>
            <Grid.Item className={styles.buttons}>
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button loading={saving} type="submit">
                Save
              </Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  )
}

export default ModalProfileDomainEdit

import { DocusealForm } from '@docuseal/react'
import { compact, isNil, last } from 'lodash'
import { PrintTypes } from 'print-js'
import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MainContent } from 'borrower/components/layout/MainContent'
import { prefix as urlPrefix } from 'borrower/path-to'
import { PageLoader } from 'components/LoaderOverlay'
import { Viewer } from 'components/Viewer/Viewer'
import {
  useDocument,
  useDownloadDocument,
  useDocumentVersion,
  printDocument,
  useDownloadDocumentAsPdf,
} from 'hooks/use-document'
import { useSession } from 'hooks/use-session'
import { getFileType } from 'utils/file'
import { Header } from './Header'
import styles from './styles.module.scss'

type RouterParams = Readonly<{
  loanId?: string
  applicationId?: string
  id: string
}>

const getRedirectUrl = ({
  loanId,
  applicationId,
  anchor,
}: {
  loanId?: string
  applicationId?: string
  anchor?: string
}) => {
  const url: (string | undefined)[] = []
  if (loanId) {
    url.push('loans', loanId)
  }
  if (applicationId) {
    url.push('applications', applicationId)
  }
  url.push('documents', anchor && `#${anchor}`)
  return `${urlPrefix}/${compact(url).join('/')}`
}

function Document() {
  const navigate = useNavigate()
  const { user } = useSession()
  const { loanId, applicationId, id } = useParams() as RouterParams
  const [wait, setWait] = useState<string | undefined>()
  const { data, isPending } = useDocument({ id, loanId })
  const versionId = data?.versions && (last(data.versions) || {}).id
  const { data: version, isPending: isVersionLoading } = useDocumentVersion({
    documentId: id,
    versionId,
    wait,
  })
  useEffect(() => {
    if (version) {
      setWait(isNil(version.hasThumbnails) ? 'thumbnails' : undefined)
    }
  }, [version])

  const { mutate: downloadDocument } = useDownloadDocument({
    versionId,
  })
  const handleDownload = async () => {
    const type = getFileType(version?.type) as PrintTypes
    if (['image', 'pdf'].includes(type)) {
      downloadDocument(id)
    } else {
      useDownloadDocumentAsPdf({ id, versionId })
    }
  }
  const handlePrint = () => {
    printDocument({ id, version })
  }
  const handleBack = () => {
    navigate(getRedirectUrl({ loanId, applicationId, anchor: id }))
  }

  return (
    <MainContent className="p-0">
      {!isPending && data ? (
        <div className={styles.page}>
          <div className={styles.content}>
            <Header
              document={data}
              version={version}
              onBack={handleBack}
              onDownload={handleDownload}
              onPrint={handlePrint}
            />
            {data?.esignatureSlug ? (
              <DocusealForm
                src={`https://docuseal.co/d/${data.esignatureSlug}`}
                email={user?.person?.email}
                externalId={user?.person?.id}
                onComplete={(data) => console.log(data)}
                sendCopyEmail={false}
                withTitle={false}
                withDownloadButton={false}
                withSendCopyButton={false}
                logo={`${import.meta.env.VITE_APP_API_URL}/client/${user?.client?.id}/image.png`}
                i18n={{ form_has_been_completed: 'Signing completed!' }}
              />
            ) : (
              <Viewer
                isVersionLoading={isVersionLoading && data.versions.length > 0}
                onDownload={handleDownload}
                version={version}
              />
            )}
          </div>
        </div>
      ) : (
        <PageLoader />
      )}
    </MainContent>
  )
}

export { Document }

import { omitBy, size, without } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ImportEntity } from 'admin/components/ImportEntity'
import { TopMenu } from 'admin/components/TopMenu'
import { MainContent } from 'admin/components/layout/MainContent'
import { useAddLoan } from 'admin/hooks/use-loans'
import { pathTo } from 'admin/path-to'
import { downloadLoans } from 'admin/services/csv/download-loans'
import { Button } from 'components/Button'
import { Download } from 'components/Download'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { LoadMore } from 'components/LoadMore'
import { PageLoader } from 'components/LoaderOverlay'
import { ModalAddLoan } from 'components/Modal/AddLoan'
import PageTop from 'components/PageTop/PageTop'
import { Panel } from 'components/Panel'
import { Search } from 'components/Search'
import { UserSelector } from 'components/UserSelector'
import { LOAN_IMPORT_BANNER } from 'constants/local-storage-keys'
import { useLoans, useLoansAdmins } from 'hooks/use-loans'
import { useMobile } from 'hooks/use-mobile'
import { usePagination } from 'hooks/use-pagination'
import { useSession } from 'hooks/use-session'
import { Loan } from 'types'
import { AlertLoans } from './AlertLoans'
import { EmptyServicing } from './EmptyServicing'
import { ServicingTable } from './ServicingTable'

const tabButtons: { id: 'servicing' | 'liquidated'; title: string }[] = [
  { id: 'servicing', title: 'Active' },
  { id: 'liquidated', title: 'Settled' },
]

export const Servicing = () => {
  const navigate = useNavigate()
  const { isAdmin } = useSession()
  const { isTablet } = useMobile()
  const [isLoading, setIsLoading] = useState(false)
  const [activeTab, setActiveTab] = useState<'servicing' | 'liquidated'>(
    'servicing'
  )
  const [selectedAdminIds, setSelectedAdminIds] = useState<string[]>([])
  const [adding, setAdding] = useState(false)
  const [alertData, setAlertData] = useState<string | null | boolean>(
    localStorage.getItem(LOAN_IMPORT_BANNER)
  )
  const { mutate: add, isPending: isAdding } = useAddLoan()
  const {
    result,
    visibleItems,
    search,
    filter,
    sort,
    isEmpty,
    setSort,
    setFilter,
    setSearch,
    setPagination,
    updateItem,
    resetPagination,
  } = usePagination<Loan>({
    property: 'loans',
    defaultFilter: { status: [activeTab] },
    useData: (params) => useLoans(params, { keepPreviousData: true }),
  })

  const { data: admins } = useLoansAdmins({
    search,
    filter: {
      status: ['servicing', 'liquidated'],
    },
  })

  const handleSelectAdmin = useCallback(
    (id) => {
      if (selectedAdminIds.includes(id)) {
        setSelectedAdminIds(without(selectedAdminIds, id))
      } else {
        setSelectedAdminIds([...selectedAdminIds, id])
      }
    },
    [selectedAdminIds]
  )

  useEffect(() => {
    setFilter({
      status: [activeTab],
      ...(selectedAdminIds.length ? { adminId: selectedAdminIds } : {}),
    })
  }, [selectedAdminIds, activeTab])

  useEffect(() => {
    if (!result.isFetching) {
      setIsLoading(false)
    }
  }, [result.isFetching])

  return (
    <MainContent>
      {isEmpty ? (
        <Flex stack gap={16}>
          <PageTop
            title="Loans"
            className="gap-6"
            hideTopMenu={!isAdmin && isTablet}
          />
          <EmptyServicing
            onClick={() => setAdding(true)}
            onImport={() => setAlertData(true)}
          />
        </Flex>
      ) : (
        <Flex stack gap={16}>
          <Flex stack gap={24}>
            <Flex justifyContent="flex-end" className="-mx-4">
              {isAdmin && !isTablet && <TopMenu />}
            </Flex>
            <Flex alignItems="flex-start" justifyContent="space-between">
              <Flex alignItems="center" flexWrap="wrap">
                <Header variant="h1">Loans</Header>
                <UserSelector
                  options={admins || []}
                  selected={selectedAdminIds}
                  onSelect={handleSelectAdmin}
                />
              </Flex>
            </Flex>
            {alertData && (
              <AlertLoans alertData={alertData} setAlertData={setAlertData} />
            )}
          </Flex>
          <Panel>
            <Flex
              gap={4}
              justifyContent="space-between"
              flexWrap="wrap"
              className="pb-4 sm:justify-end"
            >
              <Flex gap={8}>
                {tabButtons.map(({ id, title }) => (
                  <Button
                    key={id}
                    active={activeTab === id}
                    variant="panel"
                    onClick={() => {
                      setActiveTab(id)
                      setIsLoading(true)
                      setFilter({ status: [id] })
                      resetPagination()
                    }}
                  >
                    {title}
                  </Button>
                ))}
              </Flex>
              <Flex gap={8}>
                <Search search={search} onSearch={setSearch} />
                <ImportEntity
                  entityType="loan"
                  setAlertData={() => setAlertData(true)}
                />
                <Download
                  filename="loans"
                  download={() =>
                    downloadLoans(
                      { status: ['servicing', 'liquidated'], ...filter },
                      sort,
                      search
                    )
                  }
                />
                <Button variant="primary" onClick={() => setAdding(true)}>
                  Add Loan
                </Button>
              </Flex>
            </Flex>
            {isLoading && <PageLoader />}
            {!isLoading && (
              <>
                <ServicingTable
                  tab={activeTab}
                  loading={result.isPending}
                  data={visibleItems}
                  filter={filter}
                  onFilter={(newFilter) => {
                    setFilter(
                      omitBy(
                        { ...filter, ...newFilter },
                        (value) => !size(value)
                      )
                    )
                  }}
                  sort={sort}
                  showAdmins={selectedAdminIds.length > 0}
                  onSort={setSort}
                  onClick={(loan) =>
                    navigate(pathTo('servicingLoan', (loan as Loan).id))
                  }
                  onUpdateItem={updateItem}
                />
                <LoadMore
                  loading={result.isPending}
                  fetching={result.isFetching}
                  count={visibleItems.length}
                  meta={result.data?.meta}
                  onLoadMore={setPagination}
                />
              </>
            )}
          </Panel>
        </Flex>
      )}
      {adding ? (
        <ModalAddLoan
          include={['product']}
          saving={isAdding}
          onSave={(loan) => {
            add(
              { ...loan, status: 'servicing' },
              {
                onSuccess: (loan) => {
                  navigate(pathTo('servicingLoan', loan.id))
                  setAdding(false)
                },
              }
            )
          }}
          onCancel={() => {
            setAdding(false)
          }}
        />
      ) : null}
    </MainContent>
  )
}

import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { pathTo } from 'admin/path-to'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Table } from 'components/Table'
import { TextLink } from 'components/TextLink'
import { FundInvestor } from 'types'
import { formatUsd } from 'utils/currency'
import { formatDate } from 'utils/date'
import { formatPercent } from 'utils/percent'
import styles from './styles.module.scss'

type Props = {
  data?: FundInvestor[]
  loading: boolean
  onClick: (fundInvestor?: FundInvestor) => void
  onEdit: (id: string) => void
  onDelete: (id: string) => void
}

function TableFundFunding({
  data = [],
  loading,
  onClick,
  onDelete,
  onEdit,
}: Props) {
  const navigate = useNavigate()

  const columns: ColumnDef<FundInvestor>[] = useMemo(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
        cell: ({ getValue, row }) => {
          return (
            <TextLink
              className="link"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                navigate(pathTo('investor', row.original.id))
              }}
            >
              {(getValue() as string) || '-'}
            </TextLink>
          )
        },
      },
      {
        header: 'Class',
        accessorKey: 'fundInvestors.class',
        size: 120,
      },
      {
        header: 'Rate',
        accessorKey: 'fundInvestors.rate',
        size: 100,
        cell: ({ getValue }) => formatPercent(getValue() as string),
      },
      {
        header: 'Invested',
        id: 'contribution',
        size: 160,
        cell: ({ row }) => (
          <Flex stack gap={4}>
            <div>{formatUsd(row.original.fundInvestors.amount)}</div>
            <div className={styles.minor}>
              {formatDate(row.original.fundInvestors.dateInvested) || '-'}
            </div>
          </Flex>
        ),
      },
      {
        header: 'Returned',
        accessorKey: 'capitalReturn',
        size: 130,
        cell: ({ getValue }) => formatUsd(getValue() as string),
        meta: {
          align: 'right',
        },
      },
      {
        header: 'Balance',
        accessorKey: 'balance',
        size: 130,
        cell: ({ getValue }) => formatUsd(getValue() as string),
        meta: {
          align: 'right',
        },
      },
      {
        header: 'Income',
        accessorKey: 'income',
        size: 130,
        cell: ({ getValue }) => formatUsd(getValue() as string),
        meta: {
          align: 'right',
        },
      },

      {
        header: 'DRIP',
        accessorKey: 'reinvested',
        size: 100,
        cell: ({ getValue }) => formatUsd(getValue() as string),
        meta: {
          align: 'right',
        },
      },
      {
        header: '',
        accessorKey: 'fundInvestors.id',
        size: 40,
        cell: ({ getValue }) => {
          const id = getValue() as string
          return (
            <EllipsesActions>
              <EllipsesActions.Item icon onSelect={() => onEdit(id)}>
                <Icon name={IconName.edit} />
                Edit
              </EllipsesActions.Item>
              <EllipsesActions.Item
                icon
                onSelect={() => onDelete(id)}
                className="text-red-100"
              >
                <Icon name={IconName.delete} />
                Delete
              </EllipsesActions.Item>
            </EllipsesActions>
          )
        },
      },
    ],
    []
  )

  return (
    <Table
      className={styles.table}
      columns={columns}
      data={data}
      loading={loading}
      onClick={onClick}
    />
  )
}

export { TableFundFunding }

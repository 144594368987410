import clsx from 'clsx'
import { useState, CSSProperties } from 'react'
import { useOfferingDocuments } from 'admin/hooks/use-offering-documents'
import { useAddOfferingInvestor } from 'admin/hooks/use-offering-investor'
import { Summary } from 'borrower/pages/Offering/Summary'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { Indicator } from 'components/Indicator'
import { ModalInvestInOffering } from 'components/Modal/InvestInOffering'
import { Panel } from 'components/Panel'
import { useSession } from 'hooks/use-session'
import { LoanFundingSource, Offering } from 'types'
import { formatUsd } from 'utils/currency'
import { getRates } from 'utils/offering'
import { formatPercent } from 'utils/percent'
import { PanelClasses } from './PanelClasses'
import { PanelMyInvestments } from './PanelMyInvestments'
import { TabCollateral } from './TabCollateral'
import { TabDocuments } from './TabDocuments'
import { TabRisks } from './TabRisks'
import styles from './styles.module.scss'

interface Props {
  offering: Offering
  fundingSources: LoanFundingSource[] | undefined
}

const OfferingLoanView = ({ offering, fundingSources }: Props) => {
  const [tab, setTab] = useState('collateral')
  const { user } = useSession()
  const { data: documents } = useOfferingDocuments(offering.id)
  const { mutate: addInvestor, isPending: isAdding } = useAddOfferingInvestor(
    offering.id as string
  )
  const [addingInvestorClass, setAddingInvestorClass] = useState<string>()
  const rates = getRates(offering)
  const imageUrl = offering.image?.url

  const isClosed =
    offering.percentSubscribed >= 100 ||
    (offering.type !== 'Fund Offering' && offering.amount === 0)

  return (
    <div
      style={{ '--offer-image-url': `url(${imageUrl})` } as CSSProperties}
      className={clsx(
        'relative',
        `before:absolute before:content-[''] before:bg-cover before:-z-1 before:h-[250px] before:w-full`,
        imageUrl
          ? `before:bg-[image:var(--offer-image-url)] before:blur-md before:bg-center before:scale-110`
          : 'before:bg-grey-100',
        `after:absolute after:content-[''] after:bg-grey-50 after:h-full after:w-full after:-z-1 after:top-[250px]`
      )}
    >
      <Flex stack gap={24} className={styles.container}>
        <Flex
          stack
          className="bg-white-100 rounded-t border border-solid border-grey-200 mt-14"
        >
          <Flex className="flex-wrap sm:flex-nowrap p-6 sm:p-12 border-0 border-b border-solid border-grey-200">
            <Flex stack gap={32} className={styles.info}>
              <Flex stack gap={16}>
                <div className="text-grey-600 font-bold text-sm uppercase">
                  {user?.client.name}
                </div>
                <Header className="truncate md:whitespace-normal text-[44px]">
                  {offering.name}
                </Header>
              </Flex>
              <Flex gap={24} alignItems="flex-end" className="flex-wrap">
                <Flex stack gap={4}>
                  <div className="text-grey-900">Total Raise</div>
                  <div className="text-grey-900 text-5xl normal-nums">
                    {formatUsd(offering.amount)}
                  </div>
                </Flex>
                <Flex stack gap={4}>
                  <div className="text-grey-900">Projected Yield</div>
                  <div className="text-grey-900 text-5xl normal-nums">
                    {rates.map((rate) => formatPercent(rate)).join(' - ') ||
                      '-'}
                  </div>
                </Flex>
                <Flex
                  gap={8}
                  alignItems="center"
                  className="text-grey-900 text-5xl"
                >
                  <Indicator
                    color="green"
                    className={clsx(
                      'w-2.5 h-2.5 flex-shrink-0 rounded-[3px]',
                      isClosed ? 'bg-grey-300' : 'bg-green-50'
                    )}
                  />
                  {isClosed ? 'Closed' : 'Open'}
                </Flex>
              </Flex>
              <div>
                <Button
                  className={styles.classBtnInvest}
                  size="large"
                  onClick={() => setAddingInvestorClass('unknown')}
                  disabled={isClosed}
                >
                  Invest
                  <Icon name={IconName.arrowLeftLong} className="rotate-180" />
                </Button>
              </div>
            </Flex>
            <div
              className={styles.image}
              style={imageUrl ? { backgroundImage: `url(${imageUrl})` } : {}}
            />
          </Flex>
          {!!offering?.classes?.length && (
            <div className="pt-0 pb-0">
              <PanelClasses
                offering={offering}
                isClosed={isClosed}
                openInvestModal={setAddingInvestorClass}
              />
            </div>
          )}
          {!!fundingSources?.length && (
            <div className="p-6 sm:p-12 sm:pt-0 sm:pb-12">
              <PanelMyInvestments fundingSources={fundingSources} />
            </div>
          )}
        </Flex>
        <Panel className="p-6 sm:p-12">
          <Flex stack gap={54}>
            <Summary value={offering.overview} />

            <Flex
              stack
              className="px-4 py-5 border border-solid border-grey-200 rounded"
            >
              <Flex gap={8}>
                <Button
                  variant="panel"
                  active={tab === 'collateral'}
                  onClick={() => setTab('collateral')}
                >
                  Collateral
                </Button>
                {!!offering.risks?.length && (
                  <Button
                    variant="panel"
                    active={tab === 'risks'}
                    onClick={() => setTab('risks')}
                  >
                    Risks
                  </Button>
                )}
                {!!documents?.length && (
                  <Button
                    variant="panel"
                    active={tab === 'documents'}
                    onClick={() => setTab('documents')}
                  >
                    Documents
                  </Button>
                )}
              </Flex>

              {tab === 'collateral' && <TabCollateral offering={offering} />}
              {tab === 'risks' && offering.risks?.length && (
                <TabRisks offering={offering} />
              )}
              {tab === 'documents' && <TabDocuments offering={offering} />}
            </Flex>
          </Flex>
        </Panel>
        <div style={{ height: 80 }}></div>
        {addingInvestorClass && (
          <ModalInvestInOffering
            offering={offering}
            initialInvestmentClassId={addingInvestorClass}
            saving={isAdding}
            onCancel={() => setAddingInvestorClass(undefined)}
            onSave={addInvestor}
          />
        )}
      </Flex>
    </div>
  )
}

export { OfferingLoanView }

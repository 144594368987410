import clsx from 'clsx'
import { Formik, FormikProps, Field, ErrorMessage } from 'formik'
import { isUndefined, toNumber } from 'lodash'
import { useState, useMemo } from 'react'
import * as yup from 'yup'
import { SelectInvestor } from 'admin/components/SelectInvestor'
import { useAddInvestor, useInvestors } from 'admin/hooks/use-investors'
import { Investor } from 'admin/services/api/investors'
import { Alert } from 'components/Alert'
import { Button } from 'components/Button'
import { FieldIcon, Form, Option, Select } from 'components/Form'
import formStyles from 'components/Form/styles.module.scss'
import { Grid } from 'components/Grid'
import { Modal } from 'components/Modal'
import { ModalAddPerson } from 'components/Modal/AddPerson'
import { devEmailDomains } from 'constants/domain'
import { useSession } from 'hooks/use-session'
import { LoanFundingSource } from 'types'
import { formatUsd } from 'utils/currency'
import { createScheme, required } from 'utils/schemas'
import { EditFundingBar } from './EditFundingBar'
import { FundingBar } from './FundingBar'
import styles from './styles.module.scss'

interface Props {
  loanAmount: number
  loanRate: number | string
  releasedAmount: number
  committedAmount: number
  fundingSource?: LoanFundingSource
  tranches: { name: string; rate: string }[]
  saving: boolean
  onSave: (fundingSource: Partial<LoanFundingSource>) => void
  onCancel: () => void
}

type FormValues = {
  investorId: string
  class?: string
  rate: string
  amount: string
}

const amountComparison = (currentAmount: number) =>
  yup
    .number()
    .required('The field is required')
    .min(
      currentAmount,
      `Amount must exceed current released amount ${formatUsd(currentAmount, { showZero: true })}`
    )

function ModalFunding({
  loanAmount,
  loanRate,
  releasedAmount,
  committedAmount,
  fundingSource,
  tranches,
  saving,
  onSave,
  onCancel,
}: Props) {
  const { user } = useSession()
  const isDevEmail = devEmailDomains.includes(
    user?.admin?.email?.split('@')[1] as string
  )
  const [personName, setPersonName] = useState<string>('')
  const [isAddingPerson, setIsAddingPerson] = useState<boolean>(false)
  const [addedPersonId, setAddedPersonId] = useState<string>('')

  const addPerson = useAddInvestor()
  const { data: investors, isPending } = useInvestors({
    pagination: { page: 0, size: 100 },
    details: true,
  })
  const isEdit = !!fundingSource

  const Schema = useMemo(
    () =>
      createScheme({
        investorId: required,
        ...(isEdit
          ? { amount: amountComparison(fundingSource?.investedBalance || 0) }
          : {}),
      }),
    [isEdit, fundingSource]
  )

  const initialValue: FormValues = {
    investorId: fundingSource?.investorId || addedPersonId || '',
    class: fundingSource?.class || undefined,
    rate: fundingSource?.rate
      ? fundingSource.rate.toString()
      : loanRate.toString(),
    amount: fundingSource?.committedAmount?.toString() || '',
  }
  const onSubmit = (values: FormValues) => {
    onSave({
      ...values,
      rate: parseFloat(values.rate),
      amount: parseFloat(values.amount),
    })
  }
  const handleRateChange = (option: Option, form: FormikProps<any>) => {
    const selectedClassRate = isUndefined(option.value)
      ? loanRate.toString()
      : tranches.find(({ name }) => name === option.value)?.rate?.toString() ||
        ''
    form.setFieldValue('rate', selectedClassRate)
  }
  const handleAddInvestor = (personName?: string) => {
    setPersonName(personName || '')
    setIsAddingPerson(true)
  }

  return (
    <Modal
      loading={isPending}
      title={isEdit ? 'Edit Funding' : 'Add Funding'}
      onClose={onCancel}
      className={styles.modal}
    >
      <Formik
        initialValues={initialValue}
        validationSchema={Schema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values }) => {
          const isOverfunded =
            loanAmount -
              (isEdit ? 0 : committedAmount) -
              toNumber(values.amount) <
            0

          return (
            <Form>
              {isEdit ? (
                <EditFundingBar
                  loanAmount={loanAmount}
                  releasedAmount={releasedAmount}
                  committedAmount={committedAmount}
                  amount={toNumber(values.amount) || 0}
                />
              ) : (
                <FundingBar
                  loanAmount={loanAmount}
                  releasedAmount={releasedAmount}
                  committedAmount={committedAmount}
                  amount={toNumber(values.amount) || 0}
                />
              )}
              <Grid className={styles.form} columnGap={16}>
                <Grid.Item xs={12} className={styles.rowWithLink}>
                  <div className={formStyles.inputWithLabel}>
                    <Field name="investorId">
                      {({ meta: { touched, error }, form, field }) => (
                        <>
                          <label
                            htmlFor="investorId"
                            className={clsx(formStyles.label, {
                              [formStyles.errorLabel]: touched && error,
                            })}
                          >
                            Investor
                          </label>
                          <SelectInvestor
                            defaultOptions={investors?.investors}
                            value={field.value}
                            className={clsx({
                              [formStyles.errorField]: touched && error,
                            })}
                            disabled={isEdit && !isDevEmail}
                            onSelect={(id) =>
                              form.setFieldValue('investorId', id)
                            }
                            onCreate={(name) => handleAddInvestor(name)}
                          />
                          <ErrorMessage
                            component="div"
                            name="investorId"
                            className={formStyles.errorMessage}
                          />
                        </>
                      )}
                    </Field>
                  </div>
                </Grid.Item>
                <Grid.Item xs={4}>
                  <Select
                    label="Class"
                    name="class"
                    options={[
                      { label: 'None', value: undefined },
                      ...tranches.map(({ name }) => ({
                        label: name,
                        value: name,
                      })),
                    ]}
                    portal
                    onChange={handleRateChange}
                  />
                </Grid.Item>
                <Grid.Item xs={4}>
                  <FieldIcon type="percentage" label="Rate" name="rate" />
                </Grid.Item>
                <Grid.Item xs={4}>
                  <FieldIcon type="currency" label="Amount" name="amount" />
                </Grid.Item>
                {isOverfunded && (
                  <Grid.Item xs={12} className="mb-4">
                    <Alert severity="warning">
                      Total funding exceeds the total loan amount of{' '}
                      {formatUsd(loanAmount)}
                    </Alert>
                  </Grid.Item>
                )}
                <Grid.Item xs={12} className={styles.buttons}>
                  <Button variant="tertiary" onClick={onCancel}>
                    Cancel
                  </Button>
                  <Button type="submit" loading={saving}>
                    Save
                  </Button>
                </Grid.Item>
              </Grid>
            </Form>
          )
        }}
      </Formik>
      {isAddingPerson && (
        <ModalAddPerson
          saving={addPerson.isPending}
          personName={personName}
          onSave={(
            investor: Omit<Investor, 'id'> & { sendInvitation?: boolean }
          ) =>
            addPerson.mutate(investor, {
              onSuccess: (person) => {
                setAddedPersonId(person.id)
                setIsAddingPerson(false)
              },
            })
          }
          onCancel={() => {
            setIsAddingPerson(false)
          }}
        />
      )}
    </Modal>
  )
}

export { ModalFunding }

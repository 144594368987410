import axios, { AxiosResponse } from 'axios'
import { request } from 'services/request'
import { LoanDocument, DocumentVersion } from 'types'

const getDocument = async (
  id: string,
  loanId?: string
): Promise<LoanDocument> => {
  const {
    data: { document },
  } = await request.get(`/document/${id}`, {
    params: { loanId },
  })
  return document
}

const getDocumentVersion = async (
  documentId: string,
  versionId: string,
  wait?: string | boolean
): Promise<DocumentVersion> => {
  const {
    data: { version },
  } = await request.get(`/document/${documentId}/version/${versionId}`, {
    params: { wait },
  })
  return version
}

const getDocumentUploadUrl = async ({
  id,
  filename,
  type,
}: {
  id: string
  filename: string
  type: string
}): Promise<{ url: string }> => {
  const {
    data: { upload },
  } = await request.post(`/document/${id}`, {
    filename,
    type,
  })
  return { url: upload }
}

const uploadDocument = (
  url: string,
  file: File,
  onProgress?: (progressEvent: ProgressEvent) => void
): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = async () => {
      const response = await axios.put(url, reader.result, {
        onUploadProgress: onProgress,
        headers: {
          'Content-Type': '',
        },
      })
      resolve(response)
    }
    reader.onerror = reject
    reader.readAsArrayBuffer(file)
  })
}

const getLoanDocumentsDownload = async (loanId?: string): Promise<string> => {
  const {
    data: { url },
  } = await request.get(`/loan/${loanId}/document/download`)
  return url
}

export {
  getDocument,
  getDocumentVersion,
  getDocumentUploadUrl,
  uploadDocument,
  getLoanDocumentsDownload,
}

import { useState } from 'react'
import { Avatar } from 'components/Avatar'
import { Badge } from 'components/Badge'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { IconInput } from 'components/IconInput'
import { Text } from 'components/Text'
import { TextLink } from 'components/TextLink'
import { Phase, PhaseRecipient } from 'types'
import { formatPercent } from 'utils/percent'
import { ModalAddRecipient } from './ModalRecipient'
interface Props {
  recipients: PhaseRecipient[]
  defaultRecipient?: Phase['defaultRecipient']
  defaultRecipientPercent: number
  onChange: (id: string, percent: number) => void
  onAdd: ({ id, name }: { id: string; name: string }) => void
  onDelete: (id: string) => void
}

const Recipients = ({
  recipients,
  defaultRecipient,
  defaultRecipientPercent,
  onChange,
  onAdd,
  onDelete,
}: Props) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between">
        <Text variant="s" className="font-bold mt-6 py-2">
          Recipients
        </Text>
        {(defaultRecipient || recipients.length > 0) && (
          <Text variant="s" className="font-bold mt-6 py-2 mr-[6.8rem]">
            Rate
          </Text>
        )}
      </Flex>
      {defaultRecipient || recipients.length ? (
        <>
          <table className="w-full border-spacing-0 border-0 border-t border-solid border-grey-200">
            <tbody>
              {recipients.map((recipient) => (
                <tr key={recipient.id} className="group">
                  <td className="border-0 border-b border-solid border-grey-200 h-12">
                    <Flex gap={8} alignItems="center">
                      <Avatar id={recipient.id} name={recipient.name} />{' '}
                      {recipient.name}
                    </Flex>
                  </td>
                  <td className="border-0 border-b border-solid border-grey-200 h-12 w-24">
                    <IconInput
                      type="percentage"
                      value={formatPercent(recipient.percentage, {
                        maxDecimals: 10,
                      })}
                      fullAccuracy={true}
                      onChange={(e) =>
                        onChange(recipient.id, Number(e.target.value || 0))
                      }
                    />
                  </td>
                  <td className="border-0 border-b border-solid border-grey-200 h-12 w-10 text-center">
                    <TextLink
                      className="text-red-100 hidden group-hover:block"
                      onClick={() => onDelete(recipient.id)}
                    >
                      <Icon name={IconName.minusCircle} size="md" />
                    </TextLink>
                  </td>
                </tr>
              ))}
              {defaultRecipient && (
                <tr key={defaultRecipient.id} className="group">
                  <td className="border-0 border-b border-solid border-grey-200 h-12">
                    <Flex gap={8} alignItems="center">
                      <Avatar
                        id={defaultRecipient.id}
                        name={defaultRecipient.name}
                      />{' '}
                      {defaultRecipient.name}
                      <Badge color="gray">Surplus and Deficit</Badge>
                    </Flex>
                  </td>
                  <td className="border-0 border-b border-solid border-grey-200 h-12 w-24">
                    <IconInput
                      type="percentage"
                      value={defaultRecipientPercent}
                      fullAccuracy={true}
                      disabled
                    />
                  </td>
                  <td className="border-0 border-b border-solid border-grey-200 h-12 w-10 text-center" />
                </tr>
              )}
            </tbody>
          </table>
          <TextLink
            className="px-3 py-4 mb-4"
            onClick={() => setIsModalVisible(true)}
          >
            <Icon name={IconName.plus} size="sm" />
            Add to Spread
          </TextLink>
        </>
      ) : (
        <Flex
          alignItems="center"
          justifyContent="center"
          className="border border-solid border-grey-200 rounded h-32 mb-4"
        >
          <Button variant="secondary" onClick={() => setIsModalVisible(true)}>
            Add spread recipient
          </Button>
        </Flex>
      )}
      {isModalVisible && (
        <ModalAddRecipient
          unavailableRecipientIds={recipients.map(({ id }) => id)}
          onAdd={(recipient) => {
            onAdd(recipient)
            setIsModalVisible(false)
          }}
          onClose={() => setIsModalVisible(false)}
        />
      )}
    </>
  )
}

export { Recipients }

import { Formik } from 'formik'
import { isArray } from 'lodash'
import { NewVendor, Role, roles, Vendor } from 'admin/services/api/vendors'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Field, Form, Phone, Select } from 'components/Form'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'
import { TextLink } from 'components/TextLink'
import { PersonType } from 'types'
import { createScheme, required } from 'utils/schemas'

interface Props {
  onCancel: () => void
  onBack?: () => void
  onSave: (vendor: Partial<NewVendor>) => void
  saving?: boolean
  type: PersonType
  vendor?: Partial<Omit<Vendor, 'role'>> & { role?: Role | Role[] }
  cancelText?: string
  submitText?: string
}
function FormVendor({
  onCancel,
  onBack,
  onSave,
  saving,
  vendor,
  type: selectedType,
  cancelText = 'Cancel',
  submitText = 'Submit',
}: Props) {
  const filteredRoles =
    vendor?.role && isArray(vendor.role)
      ? roles.filter((role) => vendor.role?.includes(role))
      : roles

  const initialValue: Partial<NewVendor> = {
    companyName: '',
    name: '',
    email: '',
    phone: '',
    role: filteredRoles[0],
    type: selectedType,
  }

  const FormSchema = createScheme({
    role: required,
  })

  return (
    <Formik
      initialValues={{ ...initialValue }}
      onSubmit={onSave}
      validationSchema={FormSchema}
      enableReinitialize
    >
      <Form modal>
        <div>
          <Grid columnGap={24}>
            <Grid.Item xs={6}>
              <Field
                name="name"
                label={selectedType === 'entity' ? 'Company name' : 'Full Name'}
                placeholder={
                  selectedType === 'entity'
                    ? 'The Rock Lending Inc.'
                    : 'The Rock'
                }
              />
            </Grid.Item>
            <Grid.Item xs={6}>
              <Field
                name="email"
                label="Email"
                placeholder="D.Johnson@TheRockLending.com"
              />
            </Grid.Item>
            <Grid.Item xs={6}>
              <Phone name="phone" label="Phone" />
            </Grid.Item>

            <Grid.Item xs={6}>
              <Select
                name="role"
                label="Role"
                portal
                options={filteredRoles.map((role) => ({
                  value: role,
                  label: role,
                }))}
              />
            </Grid.Item>
          </Grid>
        </div>
        <Flex justifyContent="space-between">
          {onBack && (
            <Flex justifyContent="flex-start">
              <TextLink variant="invert" onClick={onBack}>
                <Icon name={IconName.arrowLeft} size="sm" />
                Back
              </TextLink>
            </Flex>
          )}
          <Flex className="flex-grow" gap={16} justifyContent="flex-end">
            <Button variant="tertiary" onClick={onCancel}>
              {cancelText}
            </Button>
            <Button loading={saving} type="submit">
              {submitText}
            </Button>
          </Flex>
        </Flex>
      </Form>
    </Formik>
  )
}
export { FormVendor }

import { useLoanCharge } from 'admin/hooks/use-loan-charge'
import { MetaFields } from 'components/MetaFields'
import { Charge } from 'types/charge'
import { formatUsd } from 'utils/currency'
import { formatDate } from 'utils/date'
import { Modal } from '../index'
import { TableChargesHistory } from './TableChargesHistory'
import styles from './styles.module.scss'

interface Props {
  loanId: string
  charge: Charge
  onCancel: () => void
}

function ChargesHistory({ loanId, charge, onCancel }: Props) {
  const { data: chargeDetails, isPending } = useLoanCharge(loanId, charge.id)

  return (
    <Modal
      title={charge.name}
      onClose={onCancel}
      crossClose
      asChild
      loading={isPending}
      className={styles.modal}
    >
      <div className={styles.content}>
        <MetaFields
          fields={[
            {
              key: 'Payable To',
              value:
                [charge.person?.companyName, charge.person?.name]
                  .filter(Boolean)
                  .join(' - ') || '-',
            },
            {
              key: 'Charge Date',
              value: formatDate(charge.dateCharged) || '-',
            },
            {
              key: 'Due Date',
              value: formatDate(charge.dateDue as string) || '-',
            },
            { key: 'Amount', value: formatUsd(charge.amount) || '-' },
          ]}
        />
        <div className={styles.table}>
          <TableChargesHistory
            data={chargeDetails?.transactions}
            loading={isPending}
          />
        </div>
      </div>
    </Modal>
  )
}

export default ChargesHistory

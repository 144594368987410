import { get } from 'lodash'
import { useState } from 'react'
import { ImportEntity } from 'admin/components/ImportEntity'
import { TableInvestorTransactions } from 'admin/components/table/TableInvestorTransactions'
import { InvestorDetails } from 'admin/services/api/investors'
import { downloadInvestorTransactions } from 'admin/services/csv/download-investor-transactions'
import { Button } from 'components/Button'
import { Download } from 'components/Download'
import { LoadMore } from 'components/LoadMore'
import { ModalDelete } from 'components/Modal/Delete'
import { ModalInvestorPayment } from 'components/Modal/ModalInvestorPayment'
import { Panel } from 'components/Panel'
import { INVESTOR_TRANSACTION_IMPORT_BANNER } from 'constants/local-storage-keys'
import {
  useInvestorTransactions,
  useAddInvestorPayment,
  useDeleteInvestorPayment,
} from 'hooks/use-investor-transactions'
import { usePagination } from 'hooks/use-pagination'
import { useSession } from 'hooks/use-session'
import { Transaction } from 'types'
import styles from '../Loan/styles.module.scss'
import { AlertFunding } from './AlertFunding'

interface Props {
  investor: InvestorDetails
}

function TabTransactions({ investor }: Props) {
  const { user } = useSession()
  const settings = get(user, 'client.settings', {
    autoInvestorAccounting: undefined,
  })

  const [deletingId, setDeletingId] = useState<string>()
  const [adding, setAdding] = useState<boolean>(false)
  const { visibleItems, result, setPagination, resetPagination } =
    usePagination<Transaction>({
      property: 'transactions',
      useData: (params) =>
        useInvestorTransactions({ ...params, id: investor.id }),
    })
  const { mutate: add, isPending: saving } = useAddInvestorPayment(investor.id)
  const { mutate: remove, isPending: deleting } = useDeleteInvestorPayment(
    investor.id
  )

  const showAddPayment = settings.autoInvestorAccounting !== 'Yes'

  const [alertData, setAlertData] = useState<string | null | boolean>(
    localStorage.getItem(INVESTOR_TRANSACTION_IMPORT_BANNER)
  )

  return (
    <div className={styles.tabContent}>
      <Panel
        title="Transactions"
        actionChildren={
          <>
            <ImportEntity
              entityType="transaction"
              loanId={investor.id}
              setAlertData={() => setAlertData(true)}
            />
            <Download
              filename="transactions"
              download={() => downloadInvestorTransactions(investor.id)}
            />
            {showAddPayment ? (
              <Button onClick={() => setAdding(true)}>Add Transaction</Button>
            ) : null}
          </>
        }
      >
        {alertData && (
          <AlertFunding alertData={alertData} setAlertData={setAlertData} />
        )}
        <TableInvestorTransactions
          data={visibleItems}
          loading={result.isPending}
          onDelete={(paymentId) => setDeletingId(paymentId)}
        />
        <LoadMore
          loading={result.isPending}
          fetching={result.isFetching}
          count={visibleItems.length}
          meta={result.data?.meta}
          onLoadMore={setPagination}
        />
        {adding && (
          <ModalInvestorPayment
            saving={saving}
            onSave={(data) => {
              resetPagination()
              add(data, { onSuccess: () => setAdding(false) })
            }}
            onCancel={() => setAdding(false)}
          />
        )}
        {deletingId && (
          <ModalDelete
            resource="payment"
            loading={deleting}
            onDelete={() => {
              resetPagination()
              remove(deletingId, {
                onSuccess: () => setDeletingId(undefined),
              })
            }}
            onCancel={() => setDeletingId(undefined)}
          />
        )}
      </Panel>
    </div>
  )
}

export default TabTransactions

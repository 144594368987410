import clsx from 'clsx'
import { useMemo, useState, useEffect } from 'react'
import { useOfferingDocuments } from 'admin/hooks/use-offering-documents'
import { useAddOfferingInvestor } from 'admin/hooks/use-offering-investor'
import { PanelMyInvestments } from 'borrower/pages/Offering/PanelMyInvestments'
import { Summary } from 'borrower/pages/Offering/Summary'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { Indicator } from 'components/Indicator'
import { ModalInvestInOffering } from 'components/Modal/InvestInOffering'
import { Panel } from 'components/Panel'
import { useSession } from 'hooks/use-session'
import { LoanFundingSource, Offering } from 'types'
import { getRates } from 'utils/offering'
import { formatPercent } from 'utils/percent'
import { TabDocuments } from './TabDocuments'
import { TabPortfolio } from './TabPortfolio'
import styles from './styles.module.scss'

interface Props {
  offering: Offering
  fundingSources: LoanFundingSource[] | undefined
}

const OfferingFundView = ({ offering, fundingSources }: Props) => {
  const [tab, setTab] = useState<'fund-portfolio' | 'documents' | null>(null)
  const { user } = useSession()
  const { data: documents } = useOfferingDocuments(offering.id)
  const { mutate: addInvestor, isPending: isAdding } = useAddOfferingInvestor(
    offering?.id as string
  )
  const [addingInvestorClass, setAddingInvestorClass] = useState<string>()
  const rates = getRates(offering)
  const targetYield = rates
    .map((rate) => formatPercent(rate, { decimals: 2 }))
    .join(' - ')
  const imageUrl = offering.image?.url
  const tabs = useMemo(
    () =>
      [
        offering.fund?.showPortfolio && 'fund-portfolio',
        !!documents?.length && 'documents',
      ].filter(Boolean),
    [offering.fund?.showPortfolio, documents]
  )

  useEffect(() => {
    if (!tab && tabs.length) {
      setTab(tabs[0] as 'fund-portfolio' | 'documents')
    }
  }, [tab, tabs])

  return (
    <div className="bg-grey-50">
      <div
        className={`relative mb-[-170px] bg-cover bg-no-repeat bg-center ${imageUrl ? '' : 'bg-grey-100'}`}
        style={
          imageUrl
            ? { height: 250, backgroundImage: `url(${imageUrl})` }
            : { height: 250 }
        }
      >
        {imageUrl && (
          <div className="absolute w-full h-full bg-black-100 opacity-40" />
        )}
      </div>
      <Flex stack gap={24} className={styles.container}>
        <Panel className="relative z-[1] p-6 sm:px-12 sm:py-8">
          <Flex className="flex-wrap sm:flex-nowrap">
            <Flex stack gap={24} className="w-full basis-1/2 sm:basis-full">
              <Flex stack gap={16}>
                <div className="text-grey-600 font-bold text-sm uppercase">
                  {user?.client.name}
                </div>
                <Header className="truncate md:whitespace-normal text-[44px]">
                  {offering.name}
                </Header>
              </Flex>
              <Flex
                alignItems={targetYield ? 'flex-end' : 'center'}
                gap={32}
                className="h-full"
              >
                {targetYield && (
                  <Flex stack gap={4}>
                    <div className="text-grey-900">Projected Yield</div>
                    <div className="text-grey-900 text-5xl normal-nums">
                      {targetYield}
                    </div>
                  </Flex>
                )}
                <Flex
                  gap={8}
                  alignItems="center"
                  className="text-grey-900 text-5xl"
                >
                  <Indicator
                    className={clsx(
                      'w-2.5 h-2.5 flex-shrink-0 rounded-[3px]',
                      offering.percentSubscribed >= 100
                        ? 'bg-grey-300'
                        : 'bg-green-50'
                    )}
                  />
                  {offering.percentSubscribed >= 100 ? 'Closed' : 'Open'}
                </Flex>
              </Flex>
            </Flex>
            <Flex className="w-full h-fit p-8 rounded bg-grey-50 sm:max-w-80">
              <Button
                size="large"
                onClick={() => setAddingInvestorClass('unknown')}
                className="w-full"
                disabled={offering.percentSubscribed >= 100}
              >
                Invest
                <Icon name={IconName.arrowLeftLong} className="rotate-180" />
              </Button>
            </Flex>
          </Flex>
        </Panel>
        <Panel className="p-6 sm:p-12">
          <Flex stack gap={54}>
            {!!fundingSources?.length && (
              <PanelMyInvestments fundingSources={fundingSources} />
            )}

            {!!offering.overview && <Summary value={offering.overview} />}
            {tabs.length > 0 && (
              <Flex
                stack
                className="px-4 py-5 border border-solid border-grey-200 rounded"
              >
                <Flex gap={8}>
                  {tabs.includes('fund-portfolio') && (
                    <Button
                      variant="panel"
                      active={tab === 'fund-portfolio'}
                      onClick={() => setTab('fund-portfolio')}
                    >
                      Fund Portfolio
                    </Button>
                  )}
                  {tabs.includes('documents') && (
                    <Button
                      variant="panel"
                      active={tab === 'documents'}
                      onClick={() => setTab('documents')}
                    >
                      Documents
                    </Button>
                  )}
                </Flex>
                {tab === 'fund-portfolio' && (
                  <TabPortfolio fundId={offering.fundId as string} />
                )}
                {tab === 'documents' && <TabDocuments offering={offering} />}
              </Flex>
            )}
          </Flex>
        </Panel>
        <div style={{ height: 80 }}></div>
        {addingInvestorClass && (
          <ModalInvestInOffering
            offering={offering}
            initialInvestmentClassId={addingInvestorClass}
            saving={isAdding}
            onCancel={() => setAddingInvestorClass(undefined)}
            onSave={addInvestor}
          />
        )}
      </Flex>
    </div>
  )
}

export { OfferingFundView }

import { useOfferingDocuments } from 'admin/hooks/use-offering-documents'
import { PageLoader } from 'components/LoaderOverlay'
import { TableDocuments } from 'components/TableDocuments'
import { useDownloadDocument } from 'hooks/use-document'
import { Offering } from 'types'

interface Props {
  offering: Offering
}

function TabDocuments({ offering }: Props) {
  const { data: documents, isPending } = useOfferingDocuments(offering.id)
  const { mutate: downloadDocument } = useDownloadDocument()
  const handleDownload = (id: string) => downloadDocument(id)

  return isPending ? (
    <PageLoader />
  ) : (
    <TableDocuments
      data={documents?.map((doc) => ({
        ...doc,
        isCustom: false,
      }))}
      hideStatus
      hideEllipsesMenu
      showDownload
      onDownload={handleDownload}
    />
  )
}

export { TabDocuments }

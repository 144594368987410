import { Formik } from 'formik'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Button } from 'components/Button'
import { Form, Field } from 'components/Form'
import { Header } from 'components/Header'
import { Logo } from 'components/Logo'
import { Text } from 'components/Text'
import { subdomain } from 'constants/domain'
import { useCreateUserAccount } from 'hooks/use-users'
import { createScheme, email, mergeRules, required } from 'utils/schemas'
import styles from './styles.module.scss'

interface Props {
  pathTo: any
  investor?: boolean
}

const CreateAccountScheme = createScheme({
  email: mergeRules(email, required),
  name: required,
})

type FormType = {
  companyName?: string
  name: string
  email: string
}

const initialValues: FormType = {
  email: '',
  name: '',
  companyName: '',
}

function CreateAccount({ pathTo, investor = false }: Props) {
  const [isAccountCreated, setIsAccountCreated] = useState(false)

  const create = useCreateUserAccount({ investor })

  const handleSubmit = ({ email, name, companyName }: FormType) => {
    create.mutate(
      { email, name, companyName, subdomain },
      {
        onSuccess: () => setIsAccountCreated(true),
      }
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.formBox}>
        <Logo color="blue" to={pathTo('signIn')} />
        {isAccountCreated ? (
          <div className={styles.form}>
            <Header variant="h1" className={styles.title}>
              You’ve created an account!
            </Header>
            <Text className={styles.description}>
              Check your email for a link to verify your account and get
              logged-in.
            </Text>
          </div>
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={CreateAccountScheme}
            onSubmit={handleSubmit}
            validateOnChange={false}
          >
            <Form className={styles.form} method="post">
              <Header variant="h1" className={styles.title}>
                Create Account
              </Header>
              <Text className={styles.description}>
                Please tell us a bit about yourself. We&apos;ll send you an
                email to confirm your account and setup your password.
              </Text>

              <Field name="companyName" label="Company Name" />
              <Field name="name" label="Your Name" />
              <Field name="email" label="Your Email" autoComplete="email" />
              <Button type="submit">Create</Button>
              <div className={styles.divider} />
              <NavLink to={pathTo('signIn')} className="link">
                Have an existing account? Click here to login
              </NavLink>
            </Form>
          </Formik>
        )}

        <div />
      </div>
    </div>
  )
}

export { CreateAccount }

import { fields } from 'admin/pages/Application/fields'
import { Flex } from 'components/Flex'
import { Select } from 'components/Form'

export const SelectFieldType = () => {
  return (
    <Select
      label="Field type"
      name="type"
      portal
      options={fields.map(({ name, type, icon }) => ({
        label: (
          <Flex alignItems="center" gap={8}>
            <img className="h-5 w-5 flex-shrink-0" src={icon} alt="" /> {name}
          </Flex>
        ),
        value: type,
      }))}
    />
  )
}

import { compact, max, min, uniq } from 'lodash'
import { useState } from 'react'
import {
  useFundClasses,
  useAddFundClass,
  useEditFundClass,
  useDeleteFundClass,
} from 'admin/hooks/use-fund-class'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { ModalDelete } from 'components/Modal/Delete'
import { Panel } from 'components/Panel'
import { TextLink } from 'components/TextLink'
import { Fund, Class } from 'types'
import { formatPercent } from 'utils/percent'
import { ModalClass } from './ModalClass'
import styles from './styles.module.scss'

interface Props {
  fund: Fund
}

function PanelClass({ fund }: Props) {
  const [addingClass, setAddingClass] = useState(false)
  const [editingClass, setEditingClass] = useState<Class>()
  const [deletingClass, setDeletingClass] = useState<Class>()
  const { data: classes, isPending } = useFundClasses(fund.id)
  const { mutate: add, isPending: isAdding } = useAddFundClass(fund.id)
  const { mutate: edit, isPending: isEditing } = useEditFundClass(fund.id)
  const { mutate: deleteClass, isPending: isDeleting } = useDeleteFundClass(
    fund.id
  )

  const getRate = (classItem: Class) => {
    if (classItem.type === 'Tiered Rate') {
      const rates = classItem?.tiers?.map(({ percentage }) => percentage)
      const ratesRange = uniq(compact([min(rates), max(rates)])).map((rate) =>
        formatPercent(rate, { decimals: 2 })
      )
      return ratesRange.join(' - ')
    }

    return formatPercent(classItem.rate, { decimals: 2 })
  }

  return (
    <Panel title="Classes" loading={isPending}>
      <Flex stack>
        {classes?.length && !isPending ? (
          classes.map((classItem) => (
            <Flex
              key={classItem.id}
              alignItems="flex-start"
              className="border border-solid border-grey-200 rounded p-4"
            >
              <Flex gap={4} className="flex-grow flex-shrink-0">
                <div className="font-bold">{classItem.name}</div>
                {classItem.type === 'Tiered Rate' && (
                  <div className="text-sm text-grey-700 bg-grey-75 px-2 py-0.5 rounded font-bold">
                    Tiered
                  </div>
                )}
              </Flex>
              <Flex
                stack
                gap={4}
                className="basis-28 flex-grow-0 flex-shrink-0"
              >
                <div className="text-grey-700">Rate</div>
                <div>{getRate(classItem)}</div>
              </Flex>
              <div className="basis-8 flex-grow-0 flex-shrink-0">
                <EllipsesActions>
                  <EllipsesActions.Item
                    icon
                    onSelect={() => setEditingClass(classItem)}
                  >
                    <Icon name={IconName.edit} />
                    Edit
                  </EllipsesActions.Item>
                  <EllipsesActions.Item
                    icon
                    onSelect={() => setDeletingClass(classItem)}
                    className="text-red-100"
                  >
                    <Icon name={IconName.delete} />
                    Delete
                  </EllipsesActions.Item>
                </EllipsesActions>
              </div>
            </Flex>
          ))
        ) : (
          <Flex
            alignItems="center"
            justifyContent="center"
            className={styles.empty}
          >
            <TextLink onClick={() => setAddingClass(true)}>
              Setup your first class
            </TextLink>
          </Flex>
        )}
      </Flex>
      <TextLink
        className={styles.addClass}
        onClick={() => setAddingClass(true)}
      >
        <Icon name={IconName.plus} size="sm" />
        Add Class
      </TextLink>
      {(addingClass || editingClass) && (
        <ModalClass
          saving={isAdding || isEditing}
          classItem={editingClass}
          onSave={(classItem) =>
            addingClass
              ? add(classItem, {
                  onSuccess: () => setAddingClass(false),
                })
              : edit(
                  { id: editingClass?.id, ...classItem },
                  {
                    onSuccess: () => setEditingClass(undefined),
                  }
                )
          }
          onCancel={() => {
            setAddingClass(false)
            setEditingClass(undefined)
          }}
        />
      )}
      {deletingClass && (
        <ModalDelete
          resource="class"
          name={deletingClass.name}
          loading={isDeleting}
          onDelete={() => {
            deleteClass(deletingClass.id)
            setDeletingClass(undefined)
          }}
          onCancel={() => setDeletingClass(undefined)}
        />
      )}
    </Panel>
  )
}

export { PanelClass }

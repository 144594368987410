import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { pathTo } from 'borrower/path-to'
import { Flex } from 'components/Flex'
import { PageLoader } from 'components/LoaderOverlay'
import { Panel } from 'components/Panel'
import { TableDocuments } from 'components/TableDocuments'
import { useUploadDocument } from 'hooks/use-document'
import { useLoanDocuments } from 'hooks/use-loan-documents'
import { LoanDocument, Loan } from 'types'
import { openBrowseFile } from 'utils/file'
import styles from './styles.module.scss'

interface Section {
  name: string
  section: string
  guarantorId?: string
  borrowerId?: string
  documents: LoanDocument[]
}
interface Props {
  loan: Loan
}

export const TabDocuments = ({ loan }: Props) => {
  const navigate = useNavigate()
  const { data: documents, isPending } = useLoanDocuments({
    id: loan.id,
  })
  const { mutate: uploadDocument } = useUploadDocument()
  const handleUpload = (id: string) => {
    const doc = documents?.find(({ id: docId }) => id === docId)
    openBrowseFile({
      onChoose: (files) => {
        uploadDocument({ id, name: doc?.name, file: files[0] })
      },
    })
  }
  const handleDropRow = (id: string, files: FileList) => {
    const doc = documents?.find(({ id: docId }) => id === docId)
    uploadDocument({ id, name: doc?.name, file: files[0] })
  }
  const handleView = (id: string) => {
    navigate(pathTo('loanDocument', loan.id, id))
  }

  const sections: Section[] = useMemo(() => {
    return [
      ...loan.borrowers.map(({ id, name }) => ({
        name: `Borrower${loan.borrowers.length > 1 ? ` - ${name}` : ''}`,
        section: 'Borrower',
        borrowerId: id,
        documents: (documents || []).filter(
          ({ section, borrowerId }) =>
            section === 'Borrower' && borrowerId === id
        ),
      })),
      ...loan.guarantors.map(({ id, name }) => ({
        name: `Guarantor${loan.guarantors.length > 1 ? ` - ${name}` : ''}`,
        section: 'Guarantor',
        guarantorId: id,
        documents: (documents || []).filter(
          ({ section, guarantorId }) =>
            section === 'Guarantor' && guarantorId === id
        ),
      })),
      {
        name: 'Collateral',
        section: 'Collateral',
        documents: (documents || []).filter(
          ({ section }) => section === 'Collateral'
        ),
      },
      {
        name: 'Loan',
        section: 'Loan',
        documents: (documents || []).filter(
          ({ section }) => section === 'Loan'
        ),
      },
      {
        name: 'Closing',
        section: 'Closing',
        documents: (documents || []).filter(
          ({ section }) => section === 'Closing'
        ),
      },
      {
        name: 'Other',
        section: 'Other',
        documents: (documents || []).filter(
          ({ section }) =>
            ![
              'Borrower',
              'Guarantor',
              'Collateral',
              'Loan',
              'Closing',
            ].includes(section)
        ),
      },
    ].filter(({ documents }) => documents.length > 0)
  }, [loan, documents])

  return isPending ? (
    <PageLoader />
  ) : (
    <Flex stack gap={16}>
      {sections.length ? (
        sections.map(({ name, documents }) => (
          <Panel key={name} title={name}>
            <TableDocuments
              minWidth={750}
              disabled={false}
              hideEllipsesMenu
              data={documents}
              onUpload={handleUpload}
              onFileDrop={handleDropRow}
              onView={handleView}
            />
          </Panel>
        ))
      ) : (
        <div className={styles.emptyDocuments}>None have been created yet</div>
      )}
    </Flex>
  )
}

import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { MainContent } from 'admin/components/layout/MainContent'
import { pathTo } from 'admin/path-to'
import { downloadPayments } from 'admin/services/csv/download-payments'
import { Download } from 'components/Download'
import {
  Filter,
  filterValueToTableFilter,
  filterValueToUrl,
  IFilterConfig,
  IFilterValue,
  urlToFilterValue,
} from 'components/Filter'
import { Flex } from 'components/Flex'
import { PageTop } from 'components/PageTop'
import { Panel } from 'components/Panel'
import PaymentHistory from './PaymentHistory'

const filterConfig: IFilterConfig[] = [
  {
    id: 'date',
    type: 'date',
    label: 'Date',
  },
  // {
  //   id: 'status',
  //   type: 'select',
  //   label: 'Status',
  //   options: [
  //     { value: 'cleared', label: 'Cleared' },
  //     { value: 'pending', label: 'Pending' },
  //     { value: 'reversed', label: 'Reversed' },
  //   ],
  // },
  {
    id: 'type',
    type: 'select',
    label: 'Type',
    options: [
      { value: 'Funding', label: 'Funding' },
      { value: 'Other', label: 'Other' },
      { value: 'To Borrower', label: 'To Borrower' },
      { value: 'Regular Payment', label: 'Regular Payment' },
      { value: 'Special Payment', label: 'Special Payment' },
      { value: 'Payoff', label: 'Payoff' },
      { value: 'Deposit', label: 'Deposit' },
      { value: 'Withdrawal', label: 'Withdrawal' },
      { value: 'Distribution', label: 'Distribution' },
    ],
  },
]

function PaymentHistoryReport() {
  const [searchParams, setSearchParams] = useSearchParams()
  const filtersValue = useMemo(
    () =>
      searchParams.get('filter')
        ? urlToFilterValue(searchParams.get('filter') as string)
        : [],
    [searchParams]
  )

  const handleFilterChange = useCallback(
    (value: IFilterValue) => {
      setSearchParams({ filter: filterValueToUrl(value) }, { replace: true })
    },
    [setSearchParams]
  )
  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop
          title="Payment History"
          breadcrumbs={{ title: 'Reports', link: pathTo('reports') }}
        >
          <Filter
            config={filterConfig}
            value={filtersValue}
            onApply={handleFilterChange}
          />
          <Download
            filename="payments"
            download={() =>
              downloadPayments({
                filter: filterValueToTableFilter(filtersValue),
              })
            }
          />
        </PageTop>
        <Panel>
          <PaymentHistory filtersValue={filtersValue} />
        </Panel>
      </Flex>
    </MainContent>
  )
}

export { PaymentHistoryReport }

import clsx from 'clsx'
import { Formik, FormikProps, Field, ErrorMessage } from 'formik'
import { isNil, isUndefined } from 'lodash'
import { useState } from 'react'
import { SelectInvestor } from 'admin/components/SelectInvestor'
import { useAddInvestor, useInvestors } from 'admin/hooks/use-investors'
import { Investor } from 'admin/services/api/investors'
import { Button } from 'components/Button'
import { FieldIcon, Form, Option, Select } from 'components/Form'
import formStyles from 'components/Form/styles.module.scss'
import { Grid } from 'components/Grid'
import { Modal } from 'components/Modal'
import { ModalAddPerson } from 'components/Modal/AddPerson'
import { LoanFundingSource } from 'types'
import { createScheme, required } from 'utils/schemas'
import styles from './styles.module.scss'

interface Props {
  fundingSource?: LoanFundingSource
  tranches: { name: string; rate: string }[]
  tranchesWeightedAverage?: string
  saving: boolean
  onSave: (fundingSource: Partial<LoanFundingSource>) => void
  onCancel: () => void
}

type FormValues = {
  investorId: string
  class?: string
  rate: string
  amount: string
}

const Schema = createScheme({
  investorId: required,
})

function ModalFunding({
  fundingSource,
  tranches,
  tranchesWeightedAverage = '',
  saving,
  onSave,
  onCancel,
}: Props) {
  const [personName, setPersonName] = useState<string>('')
  const [isAddingPerson, setIsAddingPerson] = useState<boolean>(false)
  const [addedPersonId, setAddedPersonId] = useState<string>('')

  const addPerson = useAddInvestor()
  const { data: investors, isPending } = useInvestors({
    pagination: { page: 0, size: 100 },
  })
  const isEdit = !!fundingSource
  const initialValue: FormValues = {
    investorId: fundingSource?.investorId || addedPersonId || '',
    class: fundingSource?.class || undefined,
    rate: fundingSource?.rate
      ? fundingSource.rate.toString()
      : tranchesWeightedAverage,
    amount: fundingSource?.amount ? fundingSource.amount.toString() : '',
  }
  const onSubmit = (values: FormValues) => {
    onSave({
      ...values,
      rate: parseFloat(values.rate),
      amount: parseFloat(values.amount),
    })
  }
  const handleRateChange = (option: Option, form: FormikProps<any>) => {
    const selectedClassRate = isUndefined(option.value)
      ? tranchesWeightedAverage
      : tranches.find(({ name }) => name === option.value)?.rate?.toString() ||
        ''
    form.setFieldValue('rate', selectedClassRate)
  }
  const handleAddInvestor = (personName?: string) => {
    setPersonName(personName || '')
    setIsAddingPerson(true)
  }

  return (
    <Modal
      loading={isPending}
      title={isEdit ? 'Edit Funding' : 'Add Funding'}
      onClose={onCancel}
      className={styles.modal}
    >
      <Formik
        initialValues={initialValue}
        validationSchema={Schema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        <Form>
          <Grid className={styles.form} columnGap={16}>
            <Grid.Item xs={12} className={styles.rowWithLink}>
              <div className={formStyles.inputWithLabel}>
                <Field name="investorId">
                  {({ meta: { touched, error }, form, field }) => (
                    <>
                      <label
                        htmlFor="investorId"
                        className={clsx(formStyles.label, {
                          [formStyles.errorLabel]: touched && error,
                        })}
                      >
                        Investor
                      </label>
                      <SelectInvestor
                        defaultOptions={investors?.investors}
                        value={field.value}
                        className={clsx({
                          [formStyles.errorField]: touched && error,
                        })}
                        disabled={isEdit}
                        onSelect={(id) => form.setFieldValue('investorId', id)}
                        onCreate={(name) => handleAddInvestor(name)}
                      />
                      <ErrorMessage
                        component="div"
                        name="investorId"
                        className={formStyles.errorMessage}
                      />
                    </>
                  )}
                </Field>
              </div>
            </Grid.Item>
            <Grid.Item xs={4}>
              <Select
                label="Class"
                name="class"
                options={[
                  { label: 'None', value: undefined },
                  ...tranches.map(({ name }) => ({
                    label: name,
                    value: name,
                  })),
                ]}
                portal
                onChange={handleRateChange}
              />
            </Grid.Item>
            <Grid.Item xs={4}>
              <FieldIcon type="percentage" label="Rate" name="rate" />
            </Grid.Item>
            <Grid.Item xs={4}>
              <FieldIcon
                type="currency"
                label="Amount"
                name="amount"
                disabled={!isNil(fundingSource?.dateFunded)}
              />
            </Grid.Item>
            <Grid.Item xs={12} className={styles.buttons}>
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit" loading={saving}>
                Save
              </Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
      {isAddingPerson && (
        <ModalAddPerson
          saving={addPerson.isPending}
          personName={personName}
          onSave={(
            investor: Omit<Investor, 'id'> & { sendInvitation?: boolean }
          ) =>
            addPerson.mutate(investor, {
              onSuccess: (person) => {
                setAddedPersonId(person.id)
                setIsAddingPerson(false)
              },
            })
          }
          onCancel={() => {
            setIsAddingPerson(false)
          }}
        />
      )}
    </Modal>
  )
}

export { ModalFunding }

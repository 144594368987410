import { useMutation } from '@tanstack/react-query'
import {
  createBorrowerAccount,
  createInvestorAccount,
} from 'borrower/services/api/users'
import { handleErrorResponse } from 'services/request'
import { message } from 'utils/message'

const useCreateUserAccount = ({ investor }: { investor: boolean }) => {
  return useMutation({
    mutationFn: ({
      name,
      email,
      subdomain,
      companyName,
    }: {
      name: string
      email: string
      subdomain: string
      companyName?: string
    }) =>
      investor
        ? createInvestorAccount({ name, email, subdomain, companyName })
        : createBorrowerAccount({ name, email, subdomain, companyName }),
    onSuccess: () => {
      message.success('Account created')
    },
    onError: handleErrorResponse,
  })
}

export { useCreateUserAccount }

import { isEqual, isUndefined, omitBy, without } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { ImportEntity } from 'admin/components/ImportEntity'
import { TablePaymentsDue } from 'admin/components/table'
import {
  usePaymentsDue,
  usePaymentsDueCount,
  useProcessPayments,
} from 'admin/hooks/use-payments-due'
import { AlertPayments } from 'admin/pages/Payments/AlertPayments'
import { EmptyPayments } from 'admin/pages/Payments/EmptyPayments'
import { pathTo } from 'admin/path-to'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { LoadMore } from 'components/LoadMore'
import { LoaderOverlay } from 'components/LoaderOverlay'
import { ModalPayment } from 'components/Modal/Payment'
import { ModalPaymentProcessing } from 'components/Modal/PaymentProcessing'
import { Panel } from 'components/Panel'
import { Search } from 'components/Search'
import { PAYMENT_IMPORT_BANNER } from 'constants/local-storage-keys'
import { useLoan } from 'hooks/use-loans'
import { usePagination } from 'hooks/use-pagination'
import { Filter, PaymentDue } from 'types'

function DuePaymentsPanel() {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const tab = (searchParams.get('tab') || 'all') as 'all' | 'manual' | 'pad'
  const [payoutModal, setPayoutModal] = useState(false)
  const [isFetchingVisible, setIsFetchingVisible] = useState(false)
  const [makePaymentDue, setMakePaymentDue] = useState<PaymentDue>()
  const [checkboxes, setCheckboxes] = useState<{
    master: boolean
    ids: string[]
  }>({
    master: false,
    ids: [],
  })
  const [alertData, setAlertData] = useState<string | null | boolean>(
    localStorage.getItem(PAYMENT_IMPORT_BANNER)
  )

  const {
    visibleItems,
    result,
    search,
    filter,
    isEmpty,
    setFilter,
    setSearch,
    setPagination,
    resetPagination,
  } = usePagination<PaymentDue>({
    property: 'due',
    useData: (params) =>
      usePaymentsDue({
        ...params,
        filter: {
          ...(params.filter || {}),
        } as Filter,
      }),
  })

  const { data: paymentsDueSummary } = usePaymentsDueCount(
    {
      search,
      filter,
      include: !checkboxes.master ? checkboxes.ids : [],
      exclude: checkboxes.master ? checkboxes.ids : [],
    },
    {
      enabled: checkboxes.master || !!checkboxes.ids.length,
    }
  )
  const { data: loan, isLoading: isLoanLoading } = useLoan({
    id: makePaymentDue?.loan.id,
  })
  const { mutate: process, isPending: processing } = useProcessPayments()

  const handleCheckboxChange = useCallback(
    (id: string) => {
      if (id === 'master' && checkboxes.master) {
        setCheckboxes({ master: false, ids: [] })
      } else if (id === 'master' && !checkboxes.master) {
        setCheckboxes({ master: true, ids: [] })
      } else if (checkboxes.ids.includes(id)) {
        setCheckboxes({ ...checkboxes, ids: without(checkboxes.ids, id) })
      } else {
        setCheckboxes({ ...checkboxes, ids: [...checkboxes.ids, id] })
      }
    },
    [checkboxes, visibleItems]
  )

  const handleFilterChange = useCallback(
    (newFilter = {}) => {
      const nextFilter = omitBy({ ...filter, ...newFilter }, (value) =>
        isUndefined(value)
      )
      if (!isEqual(filter || {}, nextFilter)) {
        setFilter(nextFilter as Filter)
      }
    },
    [filter]
  )

  useEffect(() => {
    switch (tab) {
      case 'pad':
        handleFilterChange({ banking: [true] })
        break
      case 'manual':
        handleFilterChange({ banking: [false] })
        break
      default:
        handleFilterChange({ banking: undefined })
    }
    setCheckboxes({ master: false, ids: [] })
  }, [tab, handleFilterChange])

  useEffect(() => {
    setIsFetchingVisible(true)
  }, [tab])

  useEffect(() => {
    if (!result.isFetching) {
      setIsFetchingVisible(false)
    }
  }, [result.isFetching])

  const selectedCount = useMemo(() => {
    if (checkboxes.master) {
      return paymentsDueSummary?.count || 0
    }
    return checkboxes.ids.length
  }, [checkboxes, paymentsDueSummary])

  useEffect(() => {
    if ((result.data?.meta?.page || 0) === 0) {
      setCheckboxes({ master: false, ids: [] })
    }
  }, [result.data?.meta?.page])

  return (
    <>
      {alertData && (
        <AlertPayments alertData={alertData} setAlertData={setAlertData} />
      )}
      {isEmpty ? (
        <EmptyPayments onImport={() => setAlertData(true)} />
      ) : (
        <Panel>
          <Flex
            gap={4}
            justifyContent="space-between"
            flexWrap="wrap"
            className="pb-4"
          >
            <Flex gap={8}>
              {[
                { id: 'all', title: 'All' },
                { id: 'manual', title: 'Manual' },
                { id: 'pad', title: 'Pre-Authorized Debit' },
              ].map(({ id, title }) => (
                <Button
                  key={id}
                  active={tab === id}
                  variant="panel"
                  onClick={() =>
                    navigate(
                      {
                        pathname: pathTo('payments', 'due'),
                        search: createSearchParams({ tab: id }).toString(),
                      },
                      { replace: true }
                    )
                  }
                >
                  {title}
                </Button>
              ))}
            </Flex>
            <Flex gap={8}>
              <Search search={search} onSearch={setSearch} />

              <ImportEntity
                entityType="payment"
                setAlertData={() => setAlertData(true)}
              />
              <Button
                variant="primary"
                disabled={selectedCount === 0}
                onClick={() => setPayoutModal(true)}
              >
                Process Payment
              </Button>
            </Flex>
          </Flex>
          <TablePaymentsDue
            data={isFetchingVisible ? [] : visibleItems}
            loading={
              result.isPending || (isFetchingVisible && result.isFetching)
            }
            filter={filter}
            onFilter={(newFilter) => {
              handleFilterChange({ ...filter, ...newFilter })
            }}
            checkboxes={checkboxes}
            selectedCount={selectedCount}
            onCheck={handleCheckboxChange}
            onRowClick={(paymentDue) => setMakePaymentDue(paymentDue)}
          />
          <LoadMore
            loading={
              result.isPending || (isFetchingVisible && result.isFetching)
            }
            fetching={result.isFetching}
            count={visibleItems.length}
            meta={result.data?.meta}
            onLoadMore={setPagination}
          />
        </Panel>
      )}

      {payoutModal && !!paymentsDueSummary && (
        <ModalPaymentProcessing
          count={paymentsDueSummary.count}
          search={search}
          filter={filter}
          include={!checkboxes.master ? checkboxes.ids : []}
          exclude={checkboxes.master ? checkboxes.ids : []}
          processing={processing}
          onProcess={(batchId, pad) => {
            process(
              { batchId, pad },
              {
                onSuccess: () => {
                  resetPagination()
                  setPayoutModal(false)
                  setCheckboxes({ master: false, ids: [] })
                },
              }
            )
          }}
          onCancel={() => setPayoutModal(false)}
        />
      )}
      {makePaymentDue && loan && (
        <ModalPayment
          loan={loan}
          onCancel={() => setMakePaymentDue(undefined)}
        />
      )}
      {isLoanLoading && <LoaderOverlay />}
    </>
  )
}

export { DuePaymentsPanel }

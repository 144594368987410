import { useNavigate, useParams } from 'react-router-dom'
import { pathTo } from 'borrower/path-to'
import { PageTopFields } from 'components/PageTopFields'
import { Tabs } from 'components/Tabs'
import { Application as ApplicationType } from 'types'
import { formatAddress } from 'utils/address'
import { TabDocuments } from './TabDocuments'
import { TabLoanApplication } from './TabLoanApplication'
import { TabScopeOfWork } from './TabScopeOfWork'
import styles from './styles.module.scss'

interface Props {
  application: ApplicationType
}

export const Application = ({ application }: Props) => {
  const { tab } = useParams() as { tab: string }
  const navigate = useNavigate()

  const isApplicationTabVisible =
    !!application.applicationId && application.signature

  return (
    <>
      <PageTopFields
        fields={[
          {
            key: 'ID',
            value: application.number,
          },
          {
            key: 'Property Address',
            value: formatAddress(application.addresses?.[0]),
          },
        ]}
      />
      <Tabs
        key={tab}
        defaultActiveId={
          tab || (isApplicationTabVisible ? 'loanApplication' : 'documents')
        }
        onTabSelect={(tabId) =>
          navigate(pathTo('applicationSubmitted', application.id, tabId))
        }
        className={styles.tabs}
      >
        {isApplicationTabVisible ? (
          <Tabs.Pane tab="Application" id="loanApplication">
            <TabLoanApplication
              loanId={application.id}
              applicationId={application.applicationId as string}
              isDraft={application.isDraft}
            />
          </Tabs.Pane>
        ) : null}
        <Tabs.Pane tab="Documents" id="documents">
          <TabDocuments application={application} />
        </Tabs.Pane>
        <Tabs.Pane
          tab="Scope of Work"
          id="scopeOfWork"
          className={styles.tabWithNavigation}
        >
          <TabScopeOfWork application={application} />
        </Tabs.Pane>
      </Tabs>
    </>
  )
}

import { ColumnDef } from '@tanstack/react-table'
import clsx from 'clsx'
import { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { pathTo } from 'admin/path-to'
import { Badge } from 'components/Badge'
import PaymentStatusBadge from 'components/Badge/PaymentStatusBadge'
import { Checkbox } from 'components/Checkbox'
import { EllipsesActions } from 'components/EllipsesActions'
import { Icon, IconName } from 'components/Icon'
import { Table } from 'components/Table'
import { Tooltip } from 'components/Tooltip'
import { Payment, Payout, Transaction } from 'types'
import { formatUsd } from 'utils/currency'
import { formatDate } from 'utils/date'
import { getFundingType } from 'utils/loan-transactions'
import styles from './styles.module.scss'
interface Props {
  selectedCount: number
  checkboxes: {
    master: boolean
    ids: string[]
  }
  hasFilterUrl: boolean
  onCheck: (id: string) => void
  onReverse: (id: string) => void
  data?: Payout[]
  loading?: boolean
  isCompleted?: boolean
}

function TablePayouts({
  data = [],
  checkboxes,
  hasFilterUrl,
  isCompleted,
  selectedCount,
  onReverse,
  onCheck,
  loading,
}: Props) {
  const columns: ColumnDef<Payout>[] = useMemo(
    () => [
      {
        header: () => (
          <div className="flex gap-2 -ml-0.5">
            <Checkbox
              checked={checkboxes.master}
              indeterminate={!!checkboxes.ids.length}
              onChange={() => onCheck('master')}
              disabled={isCompleted && !checkboxes.ids.length}
            />
            {selectedCount > 0 ? `${selectedCount} Selected` : 'Date'}
          </div>
        ),
        accessorKey: 'date',
        size: 130,
        cell: ({ getValue, row }) => {
          const { id } = row.original
          const status = row.original.payment?.status
          const isDisabled =
            status === 'pending' ||
            (isCompleted &&
              (!row.original.person?.banking || row.original.amount <= 0))

          return (
            <div className="flex gap-2 -ml-0.5">
              <Checkbox
                disabled={isDisabled}
                checked={
                  !isDisabled &&
                  ((checkboxes.master && !checkboxes.ids.includes(id)) ||
                    (!checkboxes.master && checkboxes.ids.includes(id)))
                }
                onChange={() => onCheck(id)}
              />
              {formatDate(getValue() as string)}
            </div>
          )
        },
        meta: {
          plainHeader: 'Date',
        },
      },
      {
        header: 'Loan',
        id: 'loan',
        cell: ({ row }) => {
          const { loan } = row.original
          return (
            loan && (
              <NavLink
                to={pathTo('servicingLoanTab', loan.id, 'payments')}
                className="link"
              >
                {loan.name}
              </NavLink>
            )
          )
        },
      },
      {
        header: 'Recipient',
        accessorKey: 'person',
        cell: ({ getValue, row }) => {
          const { name, id, banking, isFund, isInvestor } =
            (getValue() as any) || {}
          return (
            name && (
              <>
                <NavLink
                  to={pathTo(
                    isFund ? 'fund' : isInvestor ? 'investor' : 'vendors',
                    id
                  )}
                  className="link"
                >
                  {name}
                </NavLink>
                {row.original.payment?.status !== 'pending' && !!banking ? (
                  <>
                    <Tooltip content="Valid banking info">
                      <Icon
                        name={IconName.dollar}
                        size="md"
                        className={clsx('ml-1', styles.info)}
                      />
                    </Tooltip>
                  </>
                ) : (
                  <div className="w-[18px]" />
                )}
              </>
            )
          )
        },
      },
      {
        header: 'Type',
        id: 'type',
        size: 120,
        cell: ({ row }) =>
          getFundingType(row.original as unknown as Transaction),
      },
      {
        header: 'Class',
        accessorKey: 'fundingSourceClass',
        size: 90,
      },
      {
        header: 'Amount',
        accessorKey: 'amount',
        size: 150,
        cell: ({ getValue }) => formatUsd(getValue() as number),
        meta: {
          align: 'right',
        },
      },
      {
        header: 'Status',
        size: 90,
        cell: ({ row }) =>
          isCompleted ? (
            <Badge color="green">Paid Out</Badge>
          ) : (
            <PaymentStatusBadge
              status={
                (row.original?.payment?.status ||
                  'cleared') as Payment['status']
              }
            />
          ),
      },
      ...(isCompleted
        ? [
            {
              header: '',
              accessorKey: 'id',
              size: 40,
              cell: ({ getValue }) => {
                const id: string = getValue() as string

                return (
                  <EllipsesActions>
                    <EllipsesActions.Item icon onSelect={() => onReverse(id)}>
                      Reverse
                    </EllipsesActions.Item>
                  </EllipsesActions>
                )
              },
            },
          ]
        : []),
    ],
    [selectedCount, isCompleted, checkboxes, onCheck, onReverse]
  )

  return (
    <Table
      columns={columns}
      data={data}
      loading={loading}
      noResultsEmptyScreen={hasFilterUrl}
    />
  )
}

export default TablePayouts

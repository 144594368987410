import React, { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import { LoanBudgetDraw } from 'pages/LoanBudgetDraw/LoanBudgetDraw'
import { Route } from 'routes/routes'
import {
  AccountSettings,
  Borrowers,
  Borrower,
  Loans,
  Loan,
  LoanApplication,
  Vendors,
  Vendor,
  Products,
  Product,
  LoanProduct,
  Document,
  Members,
  Roles,
  MyInfo,
  MyNotifications,
  Investors,
  Investor,
  Reports,
  ArchivedLoanReport,
  Servicing,
  Payouts,
  Payments,
  TransactionsReport,
  PaymentHistoryReport,
  InsuranceExpirationReport,
  TrustBalanceReport,
  DrawRequestsReport,
  Tax1098Report,
  Tax1099Report,
  UnpaidChargesReport,
  PaidChargesReport,
  UnpaidInterestReport,
  Dashboard,
  Offerings,
  Offering,
  Receipt,
  Statement,
  Quotes,
  Quote,
  QuotePreview,
  ProductDetails,
  Funds,
  Fund,
  FundDistribution,
  CompanySettings,
  GeneralSettings,
  PortalSettings,
  Notifications,
  DocumentTemplates,
  Workspace,
  LenderOfRecord,
  Application,
  InvestorStatement,
  LoanSpreadAllocation,
  SettingsAutomations,
  SettingsAutomationsLog,
  CustomFields,
} from './pages'
import { TrustTransactionsReport } from './pages/Report/TrustTransactions/TrustTransactionsReport'
import { pathTo, routerPath } from './path-to'

const LazyReportCustom = lazy(() => import('./pages/ReportCustom/ReportCustom'))
const LazyMarketplace = lazy(() => import('./pages/Marketplace/Marketplace'))
const LazyTasks = lazy(() => import('./pages/Tasks/Tasks'))
const LazyLoanUnitSheet = lazy(
  () => import('./pages/LoanUnitSheet/LoanUnitSheet')
)

export const routes: Route[] = [
  {
    path: routerPath.root,
    component: () => <Navigate replace to={routerPath.dashboard} />,
  },
  {
    path: routerPath.dashboard,
    component: () => <Dashboard />,
  },
  /* borrowers */
  {
    path: routerPath.borrowers,
    component: () => <Borrowers />,
  },
  {
    path: [routerPath.borrower, routerPath.borrowerTab],
    component: () => <Borrower />,
  },
  {
    path: [routerPath.personDocument],
    component: () => <Document />,
  },
  /* investors */
  {
    path: routerPath.investors,
    component: () => <Investors />,
  },
  {
    path: [routerPath.investor, routerPath.investorTab],
    component: () => <Investor />,
  },
  /* origination and servicing loans */
  {
    path: routerPath.loans,
    component: () => <Loans />,
  },
  {
    path: routerPath.servicing,
    component: () => <Servicing />,
  },
  {
    path: [routerPath.loan, routerPath.servicingLoan],
    component: () => <Loan />,
  },
  {
    path: [routerPath.loanProduct, routerPath.servicingLoanProduct],
    component: () => <LoanProduct />,
  },
  {
    path: [
      routerPath.loanTab,
      routerPath.servicingLoanTab,
      routerPath.loanTabEntityId,
      routerPath.servicingLoanTabEntityId,
    ],
    component: () => <Loan />,
  },
  {
    path: [routerPath.loanUnitSheet, routerPath.servicingLoanUnitSheet],
    component: () => (
      <React.Suspense fallback={null}>
        <LazyLoanUnitSheet />
      </React.Suspense>
    ),
  },
  {
    path: [routerPath.loanDocument, routerPath.servicingLoanDocument],
    component: () => <Document />,
  },
  {
    path: [routerPath.loanDraw, routerPath.servicingLoanDraw],
    component: () => <LoanBudgetDraw />,
  },
  {
    path: routerPath.receipt,
    isCustomLayout: true,
    component: () => <Receipt />,
  },
  {
    path: routerPath.loanApplicationPrintable,
    isCustomLayout: true,
    component: () => <LoanApplication />,
  },
  {
    path: routerPath.loanSpreadAllocation,
    component: () => <LoanSpreadAllocation />,
  },
  /* statement */
  {
    path: routerPath.statement,
    isCustomLayout: true,
    component: () => <Statement />,
  },
  /* vendors */
  {
    path: routerPath.vendors,
    component: () => <Vendors />,
  },
  {
    path: routerPath.vendor,
    component: () => <Vendor />,
  },
  /* reports */
  {
    path: routerPath.reports,
    component: () => <Reports />,
  },
  {
    path: routerPath.reportCustom,
    component: () => (
      <React.Suspense fallback={null}>
        <LazyReportCustom />
      </React.Suspense>
    ),
  },
  {
    path: routerPath.report,
    component: () => <ArchivedLoanReport />,
  },
  {
    path: routerPath.transactionReport,
    component: () => <TransactionsReport />,
  },
  {
    path: routerPath.paymentReport,
    component: () => <PaymentHistoryReport />,
  },
  {
    path: routerPath.insuranceReport,
    component: () => <InsuranceExpirationReport />,
  },
  {
    path: routerPath.trustBalanceReport,
    component: () => <TrustBalanceReport />,
  },
  {
    path: [routerPath.drawRequestsReport, routerPath.drawRequestsReportTab],
    component: () => <DrawRequestsReport />,
  },
  {
    path: routerPath.trustTransactionsReport,
    component: () => <TrustTransactionsReport />,
  },
  {
    path: routerPath.tax1098Report,
    component: () => <Tax1098Report />,
  },
  {
    path: routerPath.tax1099Report,
    component: () => <Tax1099Report />,
  },
  {
    path: routerPath.unpaidChargesReport,
    component: () => <UnpaidChargesReport />,
  },
  {
    path: routerPath.paidChargesReport,
    component: () => <PaidChargesReport />,
  },
  {
    path: routerPath.unpaidInterestReport,
    component: () => <UnpaidInterestReport />,
  },
  /* products */
  {
    path: routerPath.products,
    component: () => <Products />,
  },
  {
    path: routerPath.product,
    component: () => <Product />,
  },
  {
    path: [routerPath.newProduct, routerPath.editProduct],
    component: () => <ProductDetails />,
  },
  /* payouts */
  {
    path: [routerPath.payouts, routerPath.payoutsTab],
    component: () => <Payouts />,
  },
  /* payments */
  {
    path: [routerPath.payments, routerPath.paymentsTab],
    component: () => <Payments />,
  },
  /* offerings */
  {
    path: routerPath.offerings,
    component: () => <Offerings />,
  },
  {
    path: routerPath.offering,
    component: () => <Offering />,
  },
  {
    path: routerPath.offeringDocument,
    component: () => <Document />,
  },
  /* quotes */
  {
    path: [routerPath.quotes, routerPath.quotesTab],
    component: () => <Quotes />,
  },
  {
    path: routerPath.quote,
    component: () => <Quote />,
  },
  {
    path: routerPath.quotePreview,
    component: () => <QuotePreview />,
  },
  {
    path: routerPath.funds,
    component: () => <Funds />,
  },
  {
    path: routerPath.fund,
    component: () => <Fund />,
  },
  {
    path: routerPath.fundDistribution,
    component: () => <FundDistribution />,
  },
  {
    path: routerPath.settingsApplication,
    component: () => <Application />,
  },
  /* settings */
  {
    path: routerPath.account,
    component: () => <AccountSettings />,
  },
  {
    path: routerPath.accountMyAccount,
    component: () => <MyInfo />,
  },
  {
    path: routerPath.accountMyNotification,
    component: () => <MyNotifications />,
  },
  {
    path: routerPath.settings,
    component: () => <CompanySettings />,
  },
  {
    path: routerPath.settingsTeam,
    component: () => <Members />,
  },
  {
    path: routerPath.settingsRoles,
    component: () => <Roles />,
  },
  {
    path: routerPath.settingsGeneralSettings,
    component: () => <GeneralSettings />,
  },
  {
    path: routerPath.settingsPortalSettings,
    component: () => <PortalSettings />,
  },
  {
    path: routerPath.settingsNotifications,
    component: () => <Notifications />,
  },
  {
    path: routerPath.settingsDocTemplates,
    component: () => <DocumentTemplates />,
  },
  {
    path: routerPath.settingsWorkspace,
    component: () => <Workspace />,
  },
  {
    path: routerPath.settingsLenderOfRecord,
    component: () => <LenderOfRecord />,
  },
  {
    path: routerPath.settingsCustomFields,
    component: () => <CustomFields />,
  },
  {
    path: routerPath.settingsAutomations,
    component: () => <SettingsAutomations />,
  },
  {
    path: routerPath.settingsAutomationsLog,
    component: () => <SettingsAutomationsLog />,
  },
  {
    path: routerPath.investorStatement,
    isCustomLayout: true,
    component: () => <InvestorStatement />,
  },
  {
    path: routerPath.marketplace,
    component: () => (
      <React.Suspense fallback={null}>
        <LazyMarketplace />
      </React.Suspense>
    ),
  },
  {
    path: routerPath.tasks,
    component: () => (
      <React.Suspense fallback={null}>
        <LazyTasks />
      </React.Suspense>
    ),
  },
  {
    path: '*',
    component: () => <Navigate to={pathTo('dashboard')} replace />,
  },
]

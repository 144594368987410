import { DatePicker } from 'components/DatePicker'
import { IFilterType } from 'components/Filter/types'
import { IconInput } from 'components/IconInput'
import { Select, Option } from 'components/Select'

interface IProps {
  type: IFilterType
  inRange?: boolean
  options?: Option[]
  value: null | string | string[]
  onChange: (value: string | null) => void
}

export const FiltersListValue = ({
  type,
  inRange,
  options = [],
  value,
  onChange,
}: IProps) => {
  if (type === 'date') {
    return (
      <DatePicker
        className="!w-52"
        selectsRange={inRange}
        value={(value as string) || ''}
        onChange={(date) => onChange(date || null)}
      />
    )
  }
  if (type === 'select') {
    return (
      <Select
        className="w-52"
        options={options}
        isMulti
        value={value}
        onChange={(option) => {
          onChange(
            (option as unknown as Option[]).map(
              (option) => option.value
            ) as unknown as string
          )
        }}
      />
    )
  }
  return (
    <IconInput
      className="!w-52"
      type={type}
      value={value as string}
      onChange={(e) => onChange(e.target.value)}
    />
  )
}

import clsx from 'clsx'
import { orderBy } from 'lodash'
import {
  ReactNode,
  useMemo,
  useCallback,
  useRef,
  useEffect,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { Flex } from 'components/Flex'
import { Tooltip } from 'components/Tooltip'

interface Props {
  associatedWith?: { id: string; name: string }[]
  maxWidth?: number
  getUrlById?: (id: string) => string
}

const badgesGap = 8

const Badge = ({
  children,
  className,
  maxWidth,
  url,
}: {
  children: ReactNode
  className?: string
  maxWidth?: number
  url?: string
}) => {
  const [isTruncated, setIsTruncated] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const handleClick = useCallback(
    (e) => {
      if (url) {
        navigate(url)
        e.stopPropagation()
      }
    },
    [navigate, url]
  )
  useEffect(() => {
    setIsTruncated(
      !!ref.current && ref.current.scrollWidth > ref.current.clientWidth
    )
  }, [children])

  return (
    <div
      ref={ref}
      className={clsx(
        'bg-purple-50 text-purple-200 text-sm font-bold rounded-sm leading-5 px-1 truncate',
        url && 'cursor-pointer hover:bg-purple-100 hover:text-white-100',
        className
      )}
      style={{ maxWidth }}
      onClick={handleClick}
    >
      <Tooltip content={isTruncated ? children : ''}>
        <span>{children}</span>
      </Tooltip>
    </div>
  )
}

const AssociatedBadges = ({
  associatedWith = [],
  maxWidth = 188,
  getUrlById,
}: Props) => {
  const associatedList = useMemo(
    () =>
      orderBy(
        associatedWith?.map(({ id, name }) => ({
          name,
          url: getUrlById?.(id),
        })) || [],
        'name',
        'asc'
      ),
    [associatedWith]
  )

  if (associatedList.length === 1) {
    return (
      <Flex style={{ maxWidth }}>
        <Badge url={associatedList[0].url}>{associatedList[0].name}</Badge>
      </Flex>
    )
  }
  if (associatedList.length === 2) {
    return (
      <Flex gap={badgesGap} alignItems="center" style={{ maxWidth }}>
        <Badge url={associatedList[0].url}>{associatedList[0].name}</Badge>
        <Badge url={associatedList[1].url}>{associatedList[1].name}</Badge>
      </Flex>
    )
  }
  if (associatedList.length > 2) {
    return (
      <Flex gap={badgesGap} alignItems="center" style={{ maxWidth }}>
        <Badge url={associatedList[0].url}>{associatedList[0].name}</Badge>
        <Badge className="flex-shrink-0">+{associatedList.length - 1}</Badge>
      </Flex>
    )
  }

  return null
}

export { AssociatedBadges }

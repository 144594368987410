import { useBatchId, useGetPaymentFile } from 'admin/hooks/use-payments-due'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Modal } from 'components/Modal'
import { useSession } from 'hooks/use-session'
import { Filter } from 'types'
import styles from './styles.module.scss'

interface Props {
  search?: string
  filter?: Filter
  include: string[]
  exclude: string[]
  count: number
  processing: boolean
  onProcess: (batchId: string, pad: boolean) => void
  onCancel: () => void
}

function PaymentProcessing({
  search,
  filter,
  include,
  exclude,
  count,
  processing,
  onProcess,
  onCancel,
}: Props) {
  const { user } = useSession()

  const { data: batch, isPending } = useBatchId({
    search,
    filter,
    include,
    exclude,
  })
  const { mutate: download } = useGetPaymentFile()
  const handleDownload = (type: 'nacha' | 'pdf' | 'csv') => {
    download({ batchId: batch?.batch as string, type })
  }

  const isCollectionEnabled =
    batch?.countBanking && user?.client.paymentsEnabled

  return (
    <Modal
      title="Process Payments"
      onClose={onCancel}
      className={styles.modal}
      crossClose
    >
      <Flex stack gap={32}>
        <p className={styles.text}>
          Please confirm that {count} payments will be processed.
          {(isCollectionEnabled &&
            ` This batch contains ${batch?.countBanking} payments that can be collected with Pre-Authorized Debit.`) ||
            ''}
        </p>

        <Flex stack gap={16}>
          <div
            className={isPending ? styles.downloadDisabled : styles.download}
            onClick={!isPending ? () => handleDownload('nacha') : undefined}
          >
            <div>Download NACHA file</div>{' '}
            <Icon
              name={isPending ? IconName.loaderSpinner : IconName.download}
            />
          </div>
          <div
            className={isPending ? styles.downloadDisabled : styles.download}
            onClick={!isPending ? () => handleDownload('pdf') : undefined}
          >
            <div>Download PDF file</div>{' '}
            <Icon
              name={isPending ? IconName.loaderSpinner : IconName.download}
            />
          </div>
          <div
            className={isPending ? styles.downloadDisabled : styles.download}
            onClick={!isPending ? () => handleDownload('csv') : undefined}
          >
            <div>Download CSV file</div>{' '}
            <Icon
              name={isPending ? IconName.loaderSpinner : IconName.download}
            />
          </div>
        </Flex>

        <div className={styles.buttons}>
          <Button variant="tertiary" onClick={onCancel}>
            Cancel
          </Button>
          {(!isCollectionEnabled && (
            <Button
              type="submit"
              disabled={!batch}
              loading={processing}
              onClick={() => onProcess(batch?.batch as string, false)}
            >
              Record Payments
            </Button>
          )) ||
            undefined}
          {(isCollectionEnabled && (
            <Button
              type="submit"
              disabled={!batch}
              loading={processing}
              onClick={() => onProcess(batch?.batch as string, false)}
              variant="secondary"
            >
              Record Only
            </Button>
          )) ||
            undefined}
          {(isCollectionEnabled && batch?.countBanking === batch?.count && (
            <Button
              type="submit"
              disabled={!batch}
              loading={processing}
              onClick={() => onProcess(batch?.batch as string, true)}
              color="positive"
            >
              Collect Payments
            </Button>
          )) ||
            undefined}
          {(isCollectionEnabled && batch?.countBanking !== batch?.count && (
            <Button
              type="submit"
              disabled={!batch}
              loading={processing}
              iconLeft={<Icon name={IconName.lightning} size="sm" />}
              onClick={() => onProcess(batch?.batch as string, true)}
              color="positive"
            >
              Record All + Collect {batch?.countBanking} Payments
            </Button>
          )) ||
            undefined}
        </div>
      </Flex>
    </Modal>
  )
}

export default PaymentProcessing

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { differenceInMinutes, parseISO } from 'date-fns'
import { last } from 'lodash'
import printJS, { PrintTypes } from 'print-js'
import { KEY_DOCUMENTS } from 'constants/query-keys'
import {
  getDocument,
  getDocumentUploadUrl,
  getDocumentVersion,
  uploadDocument,
  getLoanDocumentsDownload,
} from 'services/api/document'
import { generatePdf } from 'services/api/pdf'
import { handleErrorResponse } from 'services/request'
import { DocumentVersion } from 'types'
import { getFileType } from 'utils/file'
import { message } from 'utils/message'

const useDocument = ({ id, loanId }: { id: string; loanId?: string }) => {
  return useQuery({
    queryKey: [KEY_DOCUMENTS, id],
    queryFn: () => getDocument(id, loanId),
    refetchInterval: (query) =>
      query?.state?.data?.updatedAt &&
      Math.abs(
        differenceInMinutes(parseISO(query.state.data.updatedAt), new Date())
      ) < 2
        ? 5000
        : false,
  })
}

const useDownloadDocument = ({ versionId }: { versionId?: string } = {}) => {
  return useMutation({
    mutationFn: async (documentId: string) => {
      const hideToast = message.progress('Downloading document…', 10000)
      const document = await getDocument(documentId)
      hideToast()
      return document
    },
    onSuccess: ({ versions }) => {
      const version = versionId
        ? versions.find(({ id }) => id === versionId)
        : last(versions)
      const url = version?.downloadUrl
      if (url) {
        window.location.href = url
      }
    },
    onError: handleErrorResponse,
  })
}

const useDocumentVersion = ({
  documentId,
  versionId,
  wait,
}: {
  documentId: string
  versionId?: string
  wait?: string
}) => {
  return useQuery({
    queryKey: [KEY_DOCUMENTS, documentId, versionId, wait],
    queryFn: () => getDocumentVersion(documentId, versionId as string, wait),
    enabled: !!versionId,
  })
}

const useUploadDocument = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({
      id,
      name,
      file,
    }: {
      id: string
      name?: string
      file: File
    }) => {
      const uploadToast = message.upload(name || file.name)
      uploadToast.show()
      const { url } = await getDocumentUploadUrl({
        id,
        filename: file.name,
        type: file.type,
      })
      const { headers } = await uploadDocument(url, file, (progressEvent) => {
        uploadToast.progress(progressEvent.loaded / progressEvent.total)
      })
      uploadToast.processing()
      const versionId = headers['x-amz-version-id']
      const version = await getDocumentVersion(id, versionId, true)
      uploadToast.complete()
      return version
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_DOCUMENTS] })
    },
    onError: handleErrorResponse,
  })
}

const printDocument = async ({
  id,
  version,
}: {
  id: string
  version?: DocumentVersion
}) => {
  let printable = version?.printUrl
  let type = getFileType(version?.type) as PrintTypes
  if (['docx'].includes(getFileType(version?.type) as string)) {
    type = 'pdf' as PrintTypes
    const hideToast = message.progress('Preparation for printing…', 10000)
    printable = await generatePdf(
      `/convert/document/${id}/version/${version?.id}`
    )
    hideToast()
  }

  printJS({
    printable,
    type,
  })
}

const useDownloadDocumentAsPdf = async ({
  id,
  versionId,
}: {
  id: string
  versionId?: string
}) => {
  const hideToast = message.progress('Downloading document…', 10000)
  const url = await generatePdf(`/convert/document/${id}/version/${versionId}`)
  hideToast()
  if (url) {
    window.location.href = url
  }
}

const useDownloadLoanDocuments = async ({ loanId }: { loanId: string }) => {
  const hideToast = message.progress('Downloading documents…', 20000)
  const url = await getLoanDocumentsDownload(loanId)
  hideToast()
  if (url) {
    window.location.href = url
  }
}

export {
  useDocument,
  useDownloadDocument,
  useDocumentVersion,
  useUploadDocument,
  printDocument,
  useDownloadDocumentAsPdf,
  useDownloadLoanDocuments,
}

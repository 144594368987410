import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { isArray } from 'lodash'
import { KEY_LOAN_BUDGET } from 'constants/query-keys'
import {
  getBudgetDraws,
  getBudgetDraw,
  addBudgetDraw,
  submitBudgetDraw,
  updateBudgetDraw,
  deleteBudgetDraw,
  getBudgetItemActivities,
  addBudgetItemActivity,
  getBudgetItemActivity,
  getBudgetItemActivityUploadUrl,
  updateBudgetItemActivity,
  deleteBudgetItemActivity,
  deleteBudgetItemActivityImage,
} from 'services/api/budget-draw'
import { uploadDocument } from 'services/api/document'
import { handleErrorResponse } from 'services/request'
import { BudgetActivity, BudgetDraw } from 'types'
import { message } from 'utils/message'

const useBudgetDraws = (loanId: string, addressId: string) => {
  return useQuery({
    queryKey: [KEY_LOAN_BUDGET, loanId, addressId, 'draw'],
    queryFn: () => getBudgetDraws(loanId, addressId),
  })
}

const useBudgetDraw = (loanId: string, addressId: string, drawId: string) => {
  return useQuery({
    queryKey: [KEY_LOAN_BUDGET, loanId, addressId, 'draw', drawId],
    queryFn: () => getBudgetDraw(loanId, addressId, drawId),
  })
}

const useAddBudgetDraw = (loanId: string, addressId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () => addBudgetDraw(loanId, addressId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [KEY_LOAN_BUDGET, loanId, addressId, 'draw'],
      })
    },
    onError: handleErrorResponse,
  })
}

const useUpdateBudgetDraw = (loanId: string, addressId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (draw: Partial<BudgetDraw & { message?: string }>) =>
      updateBudgetDraw(loanId, addressId, draw),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [KEY_LOAN_BUDGET, loanId, addressId, 'draw'],
      })
    },
    onError: handleErrorResponse,
  })
}

const useSubmitBudgetDraw = (
  loanId: string,
  addressId: string,
  drawId: string
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () => submitBudgetDraw(loanId, addressId, drawId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [KEY_LOAN_BUDGET, loanId, addressId, 'draw'],
      })
    },
    onError: handleErrorResponse,
  })
}

const useDeleteBudgetDraw = (loanId: string, addressId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (drawId: string) => deleteBudgetDraw(loanId, addressId, drawId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [KEY_LOAN_BUDGET, loanId, addressId, 'draw'],
      })
    },
    onError: handleErrorResponse,
  })
}

const useBudgetItemActivities = (
  loanId: string,
  addressId: string,
  budgetItemId: string
) => {
  return useQuery({
    queryKey: [
      KEY_LOAN_BUDGET,
      loanId,
      addressId,
      'draw',
      'budgetItem',
      budgetItemId,
      'activity',
    ],
    queryFn: () => getBudgetItemActivities(loanId, addressId, budgetItemId),
  })
}

const useAddBudgetItemActivity = (
  loanId: string,
  addressId: string,
  budgetItemId: string
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (activity?: Partial<BudgetActivity>) =>
      addBudgetItemActivity(loanId, addressId, budgetItemId, activity),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [KEY_LOAN_BUDGET, loanId, addressId],
      })
    },
    onError: handleErrorResponse,
  })
}

const useBudgetItemActivity = (
  loanId: string,
  addressId: string,
  activityId?: string
) => {
  return useQuery({
    queryKey: [
      KEY_LOAN_BUDGET,
      loanId,
      addressId,
      'draw',
      'activity',
      activityId,
    ],
    queryFn: () =>
      getBudgetItemActivity(loanId, addressId, activityId as string),
    enabled: !!activityId,
  })
}

const useUpdateBudgetItemActivity = (loanId: string, addressId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (activity: Partial<BudgetActivity>) =>
      updateBudgetItemActivity(loanId, addressId, activity),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [KEY_LOAN_BUDGET, loanId, addressId],
      })
    },
    onError: handleErrorResponse,
  })
}

const useAddBudgetItemActivityImage = (
  loanId: string,
  addressId: string,
  imagesCount: number
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({
      files,
      activityId,
    }: {
      files: FileList
      activityId: string
    }) => {
      return Promise.all(
        Array.from(files).map(async (file) => {
          let images: BudgetActivity['images'] = []
          let retries = 0
          const name = file.name.replace(/\.[^/.]+$/, '')
          const uploadToast = message.upload(name)
          uploadToast.show()
          const uploadUrl = await getBudgetItemActivityUploadUrl(
            loanId,
            addressId,
            activityId
          )
          await uploadDocument(uploadUrl, file, (progressEvent) => {
            uploadToast.progress(progressEvent.loaded / progressEvent.total)
          })
          uploadToast.processing()
          while (
            isArray(images) &&
            images.length <= imagesCount &&
            retries < 15
          ) {
            await new Promise((resolve) => setTimeout(resolve, 1000))
            const data = await getBudgetItemActivity(
              loanId,
              addressId,
              activityId
            )
            images = data.images
            retries++
          }

          uploadToast.complete()
          return true
        })
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [KEY_LOAN_BUDGET, loanId, addressId, 'draw'],
      })
    },
    onError: handleErrorResponse,
  })
}

const useDeleteBudgetItemActivityImage = (
  loanId: string,
  addressId: string,
  activityId: string
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (imageId: string) =>
      deleteBudgetItemActivityImage(loanId, addressId, activityId, imageId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [KEY_LOAN_BUDGET, loanId, addressId, 'draw'],
      })
    },
    onError: handleErrorResponse,
  })
}

const useDeleteBudgetItemActivity = (loanId: string, addressId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (activityId: string) =>
      deleteBudgetItemActivity(loanId, addressId, activityId),
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: [KEY_LOAN_BUDGET, loanId, addressId],
        })
      }, 50)
    },
    onError: handleErrorResponse,
  })
}

export {
  useBudgetDraws,
  useBudgetDraw,
  useAddBudgetDraw,
  useUpdateBudgetDraw,
  useSubmitBudgetDraw,
  useDeleteBudgetDraw,
  useBudgetItemActivities,
  useBudgetItemActivity,
  useAddBudgetItemActivity,
  useUpdateBudgetItemActivity,
  useAddBudgetItemActivityImage,
  useDeleteBudgetItemActivityImage,
  useDeleteBudgetItemActivity,
}

import clsx from 'clsx'
import { uniqueId } from 'lodash'
import { useMemo, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Text } from 'components/Text'
import BorrowerIcon from 'images/icon-field-borrower.svg'
import GuarantorIcon from 'images/icon-field-guarantor.svg'
import { ApplicationSchemeField } from 'types'
import { PageField } from './PageField'

interface Props {
  field: ApplicationSchemeField
  type: 'borrower' | 'guarantor'
  onDrop: (
    field: ApplicationSchemeField,
    target: ApplicationSchemeField
  ) => void
  onEdit: (field: ApplicationSchemeField) => void
  onDelete: (field: ApplicationSchemeField) => void
  onToggleField: (field: ApplicationSchemeField) => void
}

function PageBorrower({
  field,
  type,
  onDrop,
  onEdit,
  onDelete,
  onToggleField,
}: Props) {
  const ref = useRef<HTMLDivElement>(null)
  const itemId = useMemo(() => uniqueId('borrower-item'), [])
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: ['item', 'signature-item'],
      drop: (item, monitor) => {
        if (!monitor.didDrop()) {
          onDrop(item as ApplicationSchemeField, field)
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver({ shallow: true }),
        canDrop: monitor.canDrop(),
      }),
    }),
    [onDrop]
  )
  const [{ isDragging }, drag] = useDrag(
    {
      type: 'item',
      item: () => field,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      options: {
        dropEffect: 'move',
      },
      canDrag: () => !field.readonly,
    },
    [field]
  )

  drag(drop(ref))

  return (
    <Flex
      ref={ref}
      stack
      gap={8}
      className={clsx(
        '-mx-2 border-0 border-b-[3px] border-solid border-white-100 px-2 pb-4',
        isOver && canDrop && '!border-blue-100',
        isDragging && 'hidden'
      )}
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        className={clsx(
          'group -mx-2 cursor-pointer border-0 border-b-[3px] border-solid border-white-100 px-2 pb-[9px] pt-3'
        )}
      >
        <Flex className="px-2" gap={12} alignItems="center">
          <Icon
            name={IconName.dndHandler}
            className="flex-shrink-0 text-grey-500"
          />
          <img
            src={field.type === 'borrower' ? BorrowerIcon : GuarantorIcon}
            className="flex-shrink-0"
            alt=""
          />
          <Flex stack gap={0}>
            <Text variant="l">{field.label}</Text>
            <div className="text-grey-700">
              Questions in this group will be repeated for each borrower added
              by the applicant.
            </div>
          </Flex>
        </Flex>
        <Flex alignItems="center">
          <div className="invisible group-hover:visible"></div>
        </Flex>
      </Flex>
      <Flex stack className="pl-9">
        <Flex stack gap={0}>
          <div className="rounded-t border border-solid border-grey-200 bg-grey-50 px-4 py-3 uppercase text-black-100">
            <Text variant="xs">For company {field.label}</Text>
          </div>
          <div className="border border-t-0 border-solid border-grey-200 px-4">
            {field.entityFields?.map((field) => (
              <PageField
                key={field.id}
                type={field.disableDropOut ? `corporate_${itemId}` : 'item'}
                accept={['item', `corporate_${itemId}`]}
                denyFieldTypes={['borrower', 'guarantor']}
                field={field}
                onDrop={(field, target) =>
                  onDrop(field, {
                    ...target,
                    fullId: `${type}-entity-${target.id}`,
                  })
                }
                onEdit={(field) =>
                  onEdit({ ...field, fullId: `${type}-entity-${field.id}` })
                }
                onDelete={field.disableDropOut ? undefined : onDelete}
                onToggleField={(field) =>
                  onToggleField({
                    ...field,
                    fullId: `${type}-entity-${field.id}`,
                  })
                }
              />
            ))}
          </div>
        </Flex>
        <Flex stack gap={0}>
          <div className="rounded-t border border-solid border-grey-200 bg-grey-50 px-4 py-3 uppercase text-black-100">
            <Text variant="xs">For individual {field.label}</Text>
          </div>
          <div className="border border-t-0 border-solid border-grey-200 px-4">
            {field.individualFields?.map((field) => (
              <PageField
                key={field.id}
                type={field.disableDropOut ? `individual_${itemId}` : 'item'}
                accept={['item', `individual_${itemId}`]}
                denyFieldTypes={['borrower', 'guarantor']}
                field={field}
                onDrop={(field, target) =>
                  onDrop(field, {
                    ...target,
                    fullId: `${type}-individual-${target.id}`,
                  })
                }
                onEdit={(field) =>
                  onEdit({ ...field, fullId: `${type}-individual-${field.id}` })
                }
                onDelete={field.disableDropOut ? undefined : onDelete}
                onToggleField={(field) =>
                  onToggleField({
                    ...field,
                    fullId: `${type}-individual-${field.id}`,
                  })
                }
              />
            ))}
          </div>
        </Flex>
      </Flex>
    </Flex>
  )
}

export { PageBorrower }

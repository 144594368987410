const AssociatedLabels = (
  name: string,
  associatedWith?: { id: string; name: string }[]
) => (
  <>
    <span className="text-grey-900 font-bold">{name}</span>
    {associatedWith && (
      <span className="text-grey-600 pl-3">
        {associatedWith.map(({ name }) => name).sort()?.[0]}
      </span>
    )}
  </>
)

export { AssociatedLabels }

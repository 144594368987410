import { format } from 'date-fns'
import { snakeCase } from 'lodash'
import { useState, useEffect, useMemo } from 'react'
import {
  useLoanPaymentCalculate,
  useSubmitLoanPayment,
} from 'admin/hooks/use-loan-payment'
import { Button } from 'components/Button'
import { ErrorMessage } from 'components/ErrorMessage'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { NoteField } from 'components/NoteField'
import { useSession } from 'hooks/use-session'
import { Loan, LoanFundingSource } from 'types'
import { sumDecimal } from 'utils/math'
import { Modal } from '../index'
import { Distribution } from './Distribution'
import { PaymentInfo } from './PaymentInfo'
import { PaymentKpi } from './PaymentKpi'
import { Source } from './Source'
import {
  calculatedDistributionToState,
  calculatedSourceToState,
  getInitialDistributionState,
  getInitialSourceState,
  getBalance,
  isBalanced,
  stateToPayload,
} from './helpers'
import styles from './styles.module.scss'
import { IDistribution, IPaymentInfo, ISource } from './types'

interface Props {
  loan: Loan
  type?: string
  budgetDrawId?: string
  isScopeOfWork?: boolean
  isReleaseFunding?: boolean
  selectedFundingSources?: LoanFundingSource[]
  onCancel: () => void
  onSubmit?: () => void
}

function Payment({
  loan,
  type,
  budgetDrawId,
  isScopeOfWork,
  isReleaseFunding,
  selectedFundingSources,
  onCancel,
  onSubmit,
}: Props) {
  const [error, setError] = useState(false)
  const [note, setNote] = useState('')
  const { user } = useSession()
  const [isSubmittingPad, setIsSubmittingPad] = useState(false)
  const [paymentInfo, setPaymentInfo] = useState<IPaymentInfo>({
    type: type || '',
    method: '',
    dateReceived: !loan.dateDefault ? format(new Date(), 'yyyy-MM-dd') : '',
    dateRelease: !loan.dateDefault ? format(new Date(), 'yyyy-MM-dd') : '',
    datePaidto: format(new Date(), 'yyyy-MM-dd'),
  })
  const isCalculationEnabled = useMemo(
    () =>
      !!paymentInfo.dateReceived &&
      [
        'To Borrower',
        'Regular Payment',
        'Payoff',
        'Special Payment',
        'Funding',
      ].includes(paymentInfo.type),
    [paymentInfo.dateReceived, paymentInfo.type]
  )
  const [isReady, setIsReady] = useState(!isCalculationEnabled)
  const [source, setSource] = useState<ISource>(getInitialSourceState())
  const [distribution, setDistribution] = useState<IDistribution>(
    getInitialDistributionState()
  )
  const { data } = useLoanPaymentCalculate(
    {
      loanId: loan.id,
      budgetDrawId,
      type:
        paymentInfo.type === 'Special Payment'
          ? 'regular_payment'
          : (snakeCase(paymentInfo.type) as 'regular_payment' | 'payoff'),
      dateReceived: paymentInfo.dateReceived,
      paymentType: paymentInfo.type,
      selectedFundingSources: selectedFundingSources?.map(({ id }) => id),
    },
    {
      enabled: isCalculationEnabled,
    }
  )
  useEffect(() => {
    if ((data as any)?.source) {
      setSource(calculatedSourceToState((data as any).source))
    }
    if ((data as any)?.distribution) {
      setDistribution(calculatedDistributionToState((data as any).distribution))
    }
    // when we do a request set ready only when data is available, otherwise it will be set to true by default
    if (data) {
      setIsReady(true)
    }
  }, [data])

  const { mutate: submit, isPending: submitting } = useSubmitLoanPayment(
    loan.id
  )

  const handleSubmit = (pad?: boolean) => {
    if (isBalanced(source, distribution)) {
      setError(false)
      setIsSubmittingPad(!!pad)
      submit(
        {
          ...stateToPayload(paymentInfo, source, distribution),
          notes: note,
          budgetDrawId,
          pad,
        },
        {
          onSuccess: () => {
            onCancel()
            onSubmit?.()
          },
        }
      )
    } else {
      setError(true)
    }
  }

  const achAvailable =
    ['Regular Payment', 'Special Payment'].includes(paymentInfo.type) &&
    loan.banking?.enabled &&
    user?.client.paymentsEnabled

  return (
    <Modal
      onClose={onCancel}
      asChild
      loading={!isReady}
      className={styles.modal}
    >
      <div className={styles.content}>
        <Flex id="payment-scrollable-area" stack gap={20}>
          <PaymentKpi
            loan={loan}
            type={paymentInfo.type}
            amount={sumDecimal([
              source.borrower?.amount,
              source.trust?.amount,
              source.investments?.amount,
            ])}
            isScopeOfWork={isScopeOfWork}
            isFunding={paymentInfo?.type === 'Funding'}
          />
          <Flex stack gap={20} className="mx-5">
            <PaymentInfo
              loan={loan}
              isScopeOfWork={isScopeOfWork}
              isReleaseFunding={isReleaseFunding}
              paymentInfo={paymentInfo}
              onChange={(updatedPaymentInfo) =>
                setPaymentInfo({ ...paymentInfo, ...updatedPaymentInfo })
              }
            />
            <NoteField
              value={note}
              placeholder="Enter a private team note here."
              onChange={(value) => setNote(value)}
            />
            <div className="border-0 border-b border-solid border-grey-200" />
            <Source
              loan={loan}
              paymentType={paymentInfo.type}
              source={source}
              selectedFundingSources={selectedFundingSources}
              disabled={!paymentInfo.dateReceived}
              onChange={(nextSource) => {
                setSource(nextSource)
              }}
            />
            <div className="border-0 border-b border-solid border-grey-200" />
            <Distribution
              loan={loan}
              balance={getBalance(source, distribution)}
              paymentType={paymentInfo.type}
              distribution={distribution}
              dateReceived={paymentInfo.dateReceived}
              disabled={!paymentInfo.dateReceived}
              onChange={(nextDistribution) => setDistribution(nextDistribution)}
            />
          </Flex>
        </Flex>
        <div className={styles.footer}>
          {error ? (
            <ErrorMessage className={styles.error}>
              Sources and distributions must be equal.
            </ErrorMessage>
          ) : (
            <div />
          )}
          <div className={styles.buttons}>
            <Button variant="tertiary" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              type="submit"
              loading={submitting && !isSubmittingPad}
              onClick={() => handleSubmit(false)}
              variant={achAvailable ? 'secondary' : undefined}
              disabled={!paymentInfo.dateReceived}
            >
              {achAvailable
                ? 'Record Only'
                : paymentInfo.type === 'Funding'
                  ? 'Release Funds'
                  : 'Record Payment'}
            </Button>
            {achAvailable && (
              <Button
                type="submit"
                loading={submitting && isSubmittingPad}
                iconLeft={<Icon name={IconName.lightning} size="sm" />}
                onClick={() => handleSubmit(true)}
                color="positive"
                disabled={!paymentInfo.dateReceived}
              >
                Collect Payment
              </Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default Payment

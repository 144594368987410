import clsx from 'clsx'
import { Formik, Field, ErrorMessage, FormikProps } from 'formik'
import { clone, toNumber, toString, has } from 'lodash'
import { useCallback, useRef, useState } from 'react'
import { SelectInvestor } from 'admin/components/SelectInvestor'
import { useFundClasses } from 'admin/hooks/use-fund-class'
import {
  useAddFundInvestor,
  useEditFundInvestor,
} from 'admin/hooks/use-fund-investors'
import { useAddInvestor, useInvestors } from 'admin/hooks/use-investors'
import { Investor } from 'admin/services/api/investors'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Date, FieldIcon, Form, Select } from 'components/Form'
import formStyles from 'components/Form/styles.module.scss'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'
import { Modal } from 'components/Modal'
import { ModalAddPerson } from 'components/Modal/AddPerson'
import { Text } from 'components/Text'
import { TextLink } from 'components/TextLink'
import { FundInvestor } from 'types'
import { createScheme, required } from 'utils/schemas'
import styles from './styles.module.scss'

interface Props {
  fundInvestor?: FundInvestor['fundInvestors']
  fundId: string
  onClose: () => void
}

type FormValues = {
  investorId: string
  class?: string
  dateInvested: string
  amount: string
  rate: string
}

const Schema = createScheme({
  investorId: required,
  dateInvested: required,
  amount: required,
  rate: required,
})

function ModalFunding({ fundInvestor, fundId, onClose }: Props) {
  const [personName, setPersonName] = useState<string>('')
  const [isAddingPerson, setIsAddingPerson] = useState<boolean>(false)
  const [addedPersonId, setAddedPersonId] = useState<string>('')
  const [hasWarning, setHasWarning] = useState<boolean>(false)
  const [changedRateEqualsClassRate, setChangedRateEqualsClassRate] =
    useState<boolean>(false)
  const formRef = useRef<FormikProps<FormValues>>(null)
  const { mutate: addFundInvestor, isPending: isAddingFundInvestor } =
    useAddFundInvestor(fundId)
  const { mutate: editFundInvestor, isPending: isEditingFundInvestor } =
    useEditFundInvestor(fundId, fundInvestor?.id as string)
  const addPerson = useAddInvestor()
  const { data: investors, isPending: isLoadingInvestors } = useInvestors({
    filter: { isFund: null },
  })
  const { data: classes, isPending: isLoadingClasses } = useFundClasses(fundId)

  const isEdit = !!fundInvestor
  const initialValue: FormValues = {
    investorId: fundInvestor?.investorId || addedPersonId || '',
    class: fundInvestor?.class || undefined,
    dateInvested: fundInvestor?.dateInvested || '',
    amount: fundInvestor?.amount ? fundInvestor.amount.toString() : '',
    rate: fundInvestor?.rate || '',
  }
  const onSubmit = (values: FormValues) => {
    if (isEdit) {
      editFundInvestor(
        {
          class: values.class,
          rate: values.rate,
        },
        {
          onSuccess: () => onClose(),
        }
      )
    } else {
      addFundInvestor(
        {
          ...values,
          amount: parseFloat(values.amount),
        },
        {
          onSuccess: () => onClose(),
        }
      )
    }
  }
  const handleAddInvestor = (personName?: string) => {
    setPersonName(personName || '')
    setIsAddingPerson(true)
  }
  const classesOptions = (classes || []).map(({ name }) => ({
    label: name,
    value: name,
  }))

  const handleFormChange = useCallback(
    (nextValues: { amount?: string; class?: string; rate?: string }) => {
      const isRateTouched = formRef.current?.getFieldMeta('rate').touched
      const values = formRef.current?.values
      const state = {
        ...values,
        ...nextValues,
      }
      const classItem = classes?.find(({ name }) => name === state.class)

      if (state.class && (!state.rate || !isRateTouched || !hasWarning)) {
        if (classItem?.type === 'Fixed') {
          formRef.current?.setFieldValue('rate', classItem?.rate)
        }
        if (
          classItem?.type === 'Tiered Rate' &&
          Array.isArray(classItem.tiers)
        ) {
          const tier =
            clone(classItem.tiers)
              .reverse()
              .find(({ amount }) => toNumber(state.amount) >= amount) ||
            classItem.tiers[0]
          formRef.current?.setFieldValue('rate', tier.percentage)
        }
      }

      if (!hasWarning && nextValues.rate && state.class) {
        setHasWarning(true)
      } else if (isRateTouched && nextValues.rate === '') {
        setHasWarning(false)
      }

      if (nextValues.class && hasWarning) {
        if (classItem?.type === 'Fixed') {
          setChangedRateEqualsClassRate(
            toString(classItem?.rate) === state.rate
          )
        }
        if (
          classItem?.type === 'Tiered Rate' &&
          Array.isArray(classItem.tiers)
        ) {
          const tier =
            clone(classItem.tiers)
              .reverse()
              .find(({ amount }) => toNumber(state.amount) >= amount) ||
            classItem.tiers[0]
          setChangedRateEqualsClassRate(
            toString(tier.percentage) === state.rate
          )
        }
      } else if (has(nextValues, 'class') && !nextValues.class && hasWarning) {
        setChangedRateEqualsClassRate(true)
      }
    },
    [classes, hasWarning]
  )

  const resetRate = useCallback(() => {
    const values = formRef.current?.values

    if (values?.class) {
      const classItem = classes?.find(({ name }) => name === values.class)
      if (classItem?.type === 'Fixed') {
        formRef.current?.setFieldValue('rate', classItem?.rate)
      }
      if (classItem?.type === 'Tiered Rate' && Array.isArray(classItem.tiers)) {
        const tier =
          clone(classItem.tiers)
            .reverse()
            .find(({ amount }) => toNumber(values.amount) >= amount) ||
          classItem.tiers[0]
        formRef.current?.setFieldValue('rate', tier.percentage)
      }
    }
    setHasWarning(false)
  }, [classes])

  return (
    <Modal
      loading={isLoadingInvestors || isLoadingClasses}
      title={isEdit ? 'Edit Funding' : 'Add Funding'}
      onClose={onClose}
      className={styles.modal}
    >
      {hasWarning && !changedRateEqualsClassRate && (
        <Flex
          gap={8}
          className="px-3 py-2.5 rounded bg-yellow-50 text-yellow-200"
        >
          <Icon name={IconName.info} size="lg" />
          <Text className="mt-0.5">
            <b>Note:</b> By overriding the class rate, future changes to the
            class interest rate will no longer apply to this funding.{' '}
            <TextLink onClick={resetRate} className="!text-yellow-200">
              Reset Rate
            </TextLink>
          </Text>
        </Flex>
      )}
      <Formik
        initialValues={initialValue}
        validationSchema={Schema}
        onSubmit={onSubmit}
        enableReinitialize
        innerRef={formRef}
      >
        <Form>
          <Grid className={styles.form} columnGap={16}>
            <Grid.Item xs={12} className={styles.rowWithLink}>
              <div className={formStyles.inputWithLabel}>
                <Field name="investorId" disabled={isEdit}>
                  {({ meta: { touched, error }, form, field }) => (
                    <>
                      <label
                        htmlFor="investorId"
                        className={clsx(formStyles.label, {
                          [formStyles.errorLabel]: touched && error,
                        })}
                      >
                        Investor
                      </label>
                      <SelectInvestor
                        defaultOptions={investors?.investors}
                        excludeFunds
                        value={field.value}
                        className={clsx({
                          [formStyles.errorField]: touched && error,
                        })}
                        disabled={isEdit}
                        onSelect={(id) => form.setFieldValue('investorId', id)}
                        onCreate={(name) => handleAddInvestor(name)}
                      />
                      <ErrorMessage
                        component="div"
                        name="investorId"
                        className={formStyles.errorMessage}
                      />
                    </>
                  )}
                </Field>
              </div>
            </Grid.Item>
            <Grid.Item xs={6}>
              <Date
                label="Invested Date"
                name="dateInvested"
                disabled={isEdit}
              />
            </Grid.Item>
            <Grid.Item xs={6}>
              <FieldIcon
                type="currency"
                label="Amount"
                name="amount"
                disabled={isEdit}
                onChange={(event) =>
                  handleFormChange({ amount: event.target.value })
                }
              />
            </Grid.Item>
            <Grid.Item xs={6}>
              <Select
                label="Class"
                name="class"
                options={[
                  { label: 'None', value: undefined },
                  ...classesOptions,
                ]}
                portal
                onChange={(option) =>
                  handleFormChange({ class: option.value as string })
                }
              />
            </Grid.Item>
            <Grid.Item xs={6}>
              <FieldIcon
                type="percentage"
                name="rate"
                label="Rate"
                onChange={(event) =>
                  handleFormChange({ rate: event.target.value })
                }
              />
            </Grid.Item>

            <Grid.Item xs={12} className={styles.buttons}>
              <Button variant="tertiary" onClick={onClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                loading={isAddingFundInvestor || isEditingFundInvestor}
              >
                Save
              </Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
      {isAddingPerson && (
        <ModalAddPerson
          saving={addPerson.isPending}
          personName={personName}
          onSave={(
            investor: Omit<Investor, 'id'> & { sendInvitation?: boolean }
          ) =>
            addPerson.mutate(investor, {
              onSuccess: (person) => {
                setAddedPersonId(person.id)
                setIsAddingPerson(false)
              },
            })
          }
          onCancel={() => {
            setIsAddingPerson(false)
          }}
        />
      )}
    </Modal>
  )
}

export { ModalFunding }

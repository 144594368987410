import { useNavigate, useParams } from 'react-router-dom'
import { MainContent } from 'admin/components/layout/MainContent'
import { pathTo } from 'admin/path-to'
import { Flex } from 'components/Flex'
import { PageTop } from 'components/PageTop'
import { Tabs } from 'components/Tabs'
import { ProductPanel } from './ProductPanel'

function CustomFields() {
  const navigate = useNavigate()
  const { tab } = useParams() as { tab: 'product' | 'borrower' }

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop
          breadcrumbs={{ title: 'Company Settings', link: pathTo('settings') }}
          title="Custom Data Fields"
        />
        <Tabs
          tab={tab}
          defaultActiveId="product"
          onTabSelect={(tab) => {
            navigate(pathTo('settingsCustomFields', tab), { replace: true })
          }}
        >
          <Tabs.Pane tab="Product" id="product" className="pt-5">
            <ProductPanel />
          </Tabs.Pane>
          <></>
        </Tabs>
      </Flex>
    </MainContent>
  )
}

export { CustomFields }

import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { pathTo } from 'admin/path-to'
import { FundingInvestorStatusBadge } from 'components/Badge'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Table } from 'components/Table'
import { TextLink } from 'components/TextLink'
import { LoanFundingSource } from 'types'
import { formatUsd } from 'utils/currency'
import { formatDate } from 'utils/date'
import { formatPercent } from 'utils/percent'
import styles from './styles.module.scss'

type Props = {
  data?: LoanFundingSource[]
  loading: boolean
  onEdit: (id: string) => void
  onDelete: (id: string) => void
}

function TableFunding({ data = [], loading, onEdit, onDelete }: Props) {
  const navigate = useNavigate()
  const columns: ColumnDef<LoanFundingSource>[] = useMemo(
    () => [
      {
        header: 'Investor',
        accessorKey: 'investor.name',
        cell: ({ getValue, row }) => (
          <TextLink
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              navigate(
                pathTo(
                  row.original.investor?.isFund ? 'fund' : 'investor',
                  row.original.investorId
                )
              )
            }}
          >
            {(getValue() as string) || '-'}
          </TextLink>
        ),
      },
      {
        header: 'Yield',
        accessorKey: 'class',
        size: 120,
        cell: ({ row, getValue }) => (
          <Flex stack gap={4}>
            <div>{formatPercent(row.original.rate as number)}</div>
            <div className={styles.minor}>{getValue() as string}</div>
          </Flex>
        ),
      },
      {
        header: 'Invested',
        accessorKey: 'dateInvested',
        size: 150,
        cell: ({ row, getValue }) => (
          <Flex stack gap={4}>
            <div>{formatUsd(row.original.originalAmount)}</div>
            <div className={styles.minor}>
              {formatDate(getValue() as string)}
            </div>
          </Flex>
        ),
      },
      {
        header: 'Returned',
        accessorKey: 'principalBalance',
        size: 130,
        cell: ({ getValue }) => formatUsd(getValue() as string),
        meta: {
          align: 'right',
        },
      },
      {
        header: 'Balance',
        accessorKey: 'amount',
        size: 130,
        cell: ({ getValue }) => formatUsd(getValue() as string),
        meta: {
          align: 'right',
        },
      },
      {
        header: 'Income',
        accessorKey: 'interestBalance',
        size: 130,
        cell: ({ getValue }) => formatUsd(getValue() as string),
        meta: {
          align: 'right',
        },
      },
      {
        header: 'Status',
        id: 'status',
        size: 120,
        cell: ({ row }) => {
          const { dateFunded, amount } = row.original
          return (
            <FundingInvestorStatusBadge
              dateFunded={dateFunded}
              amount={amount}
            />
          )
        },
      },
      {
        header: '',
        id: 'actions',
        size: 40,
        cell: ({ row }) => {
          const id = row.original.id as string

          return (
            <EllipsesActions>
              <EllipsesActions.Item icon onSelect={() => onEdit(id)}>
                <Icon name={IconName.edit} />
                Edit
              </EllipsesActions.Item>
              <EllipsesActions.Item
                icon
                onSelect={() => onDelete(id)}
                className="text-red-100"
              >
                <Icon name={IconName.delete} />
                Delete
              </EllipsesActions.Item>
            </EllipsesActions>
          )
        },
      },
    ],
    []
  )

  return (
    <Table
      className={styles.table}
      columns={columns}
      data={data}
      loading={loading}
    />
  )
}

export { TableFunding }

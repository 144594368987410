import { flatten } from 'lodash'
import { PanelEntityBanking } from 'admin/components/PanelEntityBanking'
import { useUpdateVendorBanking } from 'admin/hooks/use-banking'
import { Vendor, VendorDetails } from 'admin/services/api/vendors'
import { Grid } from 'components/Grid'
import PanelEmail from 'components/Panel/PanelEmail'
import { Email } from 'types'
import PanelVendorLinkedAccounts from './PanelLinkedAccounts'
import PanelPersonalInformation from './PanelPersonalInformation'
import styles from './styles.module.scss'

interface Props {
  vendor: Vendor
}

function TabGeneral({ vendor }: Props) {
  const isIndividual = !vendor.type || vendor.type === 'individual'
  const { mutate: updateBanking, isPending: savingBanking } =
    useUpdateVendorBanking(vendor.id)

  return (
    <>
      <Grid gap={16}>
        <Grid.Item sm={12} md={6} className={styles.panels}>
          <PanelPersonalInformation vendor={vendor} />
          <PanelEntityBanking
            banking={vendor.banking}
            title="Banking Info"
            updateBanking={updateBanking}
            isSaving={savingBanking}
          />
        </Grid.Item>
        <Grid.Item sm={12} md={6} className={styles.panels}>
          <PanelEmail
            id={vendor.id}
            type={isIndividual ? 'individual' : 'entity'}
            personType="vendor"
            isInvited={['invited', 'active'].includes(vendor.status)}
            emails={vendor.emails as Email[]}
            readonlyEmails={flatten(
              vendor.managers?.map((m) => m.emails || [])
            )}
          />
          <PanelVendorLinkedAccounts vendor={vendor as VendorDetails} />
        </Grid.Item>
      </Grid>
    </>
  )
}

export default TabGeneral

import {
  useMutation,
  useQuery,
  useQueryClient,
  keepPreviousData,
} from '@tanstack/react-query'
import { size } from 'lodash'
import {
  getPayouts,
  reversePayout,
  getPayoutFilters,
  payoutsCount,
  releasePayouts,
  getPayoutsNachaFileUrl,
} from 'admin/services/api/payouts'
import { KEY_PAYOUTS } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { Search } from 'types'
import { Filter, Pagination } from 'types/table'
import { message } from '../../utils/message'

const usePayouts = ({
  search,
  filter,
  pagination,
}: {
  search?: string
  filter?: Filter
  pagination?: Pagination
} = {}) => {
  const nextFilter = size(filter) ? filter : undefined
  return useQuery({
    queryKey: [KEY_PAYOUTS, search, nextFilter, pagination],
    queryFn: () => getPayouts({ search, filter: nextFilter, page: pagination }),
    placeholderData: keepPreviousData,
  })
}

const useReversePayout = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: reversePayout,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_PAYOUTS] })
      message.success('Payout reversed')
    },
    onError: handleErrorResponse,
  })
}

const usePayoutFilters = (columns: string[]) => {
  return useQuery({
    queryKey: [KEY_PAYOUTS, 'filters'],
    queryFn: () => getPayoutFilters(columns),
  })
}

const usePayoutsCount = (
  params: {
    search?: string
    filter?: Filter
    include: string[]
    exclude: string[]
  },
  options: { enabled: boolean }
) => {
  const nextFilter = size(params.filter) ? params.filter : undefined
  return useQuery({
    queryKey: [
      KEY_PAYOUTS,
      params.search,
      nextFilter,
      params.include.join(''),
      params.exclude.join(''),
    ],
    queryFn: () => payoutsCount(params),
    placeholderData: keepPreviousData,
    ...options,
  })
}

const useReleasePayouts = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (data: {
      date: string
      search?: string
      filter?: Filter
      exclude: string[]
      include: string[]
    }) => releasePayouts(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_PAYOUTS] })
      message.success('Payouts released')
    },
    onError: handleErrorResponse,
  })
}

const useGetPayoutNachaFile = () => {
  return useMutation({
    mutationFn: (params: {
      search?: Search
      filter?: Filter
      include: string[]
      exclude: string[]
    }) => {
      message.success(`Generating NACHA file...`)
      return getPayoutsNachaFileUrl(params)
    },
    onSuccess: ({ download }) => {
      if (download) {
        window.location.href = download
      }
    },
    onError: handleErrorResponse,
  })
}

export {
  usePayouts,
  useReversePayout,
  usePayoutFilters,
  usePayoutsCount,
  useReleasePayouts,
  useGetPayoutNachaFile,
}

import { capitalize } from 'lodash'
import { Icon, IconName } from 'components/Icon'
import { Tooltip } from 'components/Tooltip'
import { Payment } from 'types/payment'
import { ValueOf } from 'utils/typescript'
import { Badge, BadgeProps } from './index'

interface Props {
  status: Payment['status']
  info?: string
  isCollected?: boolean
}

const statusToColor: Record<
  Payment['status'],
  ValueOf<Pick<BadgeProps, 'color'>>
> = {
  cleared: 'green',
  pending: 'yellow',
  reversed: 'red',
}

function PaymentStatusBadge({ status, info, isCollected }: Props) {
  return (
    <Badge color={statusToColor[status]}>
      {isCollected && (
        <>
          <Tooltip content={info || 'Payment processed'}>
            <Icon name={IconName.lightning} size="sm" />
          </Tooltip>{' '}
        </>
      )}
      {capitalize(status)}
    </Badge>
  )
}

export default PaymentStatusBadge

import { without } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
import { MainContent } from 'admin/components/layout/MainContent'
import { TablePayouts } from 'admin/components/table/TablePayouts'
import {
  usePayouts,
  useReversePayout,
  usePayoutFilters,
  usePayoutsCount,
  useReleasePayouts,
  useGetPayoutNachaFile,
} from 'admin/hooks/use-payout'
import { pathTo } from 'admin/path-to'
import { downloadPayouts } from 'admin/services/csv/download-payouts'
import { Button } from 'components/Button'
import { Download } from 'components/Download'
import {
  Filter,
  filterValueToTableFilter,
  filterValueToUrl,
  IFilterConfig,
  IFilterValue,
  urlToFilterValue,
} from 'components/Filter'
import { Flex } from 'components/Flex'
import { LoadMore } from 'components/LoadMore'
import { ModalConfirm } from 'components/Modal/Confirm'
import { ModalPayout } from 'components/Modal/Payout'
import { PageTop } from 'components/PageTop'
import { Panel } from 'components/Panel'
import { Search } from 'components/Search'
import { usePagination } from 'hooks/use-pagination'
import { Payout } from 'types'
import { EmptyPayouts } from './EmptyPayouts'

const Payouts = () => {
  const { tab = 'ready' } = useParams() as { tab: 'ready' | 'completed' }
  const [searchParams, setSearchParams] = useSearchParams()
  const filtersValue = useMemo(
    () =>
      searchParams.get('filter')
        ? urlToFilterValue(searchParams.get('filter') as string)
        : [],
    [searchParams]
  )
  const {
    visibleItems,
    result,
    search,
    isEmpty,
    setSearch,
    setPagination,
    resetPagination,
  } = usePagination<Payout>({
    property: 'transactions',
    useData: (params) =>
      usePayouts({
        ...params,
        filter: {
          ...filterValueToTableFilter(filtersValue),
          completed: tab === 'completed' ? [true] : [false],
        },
      }),
  })
  const navigate = useNavigate()
  const [isFetchingVisible, setIsFetchingVisible] = useState(false)
  const [payoutModal, setPayoutModal] = useState(false)
  const [reversePayoutId, setReversePayoutId] = useState<string>()
  const [checkboxes, setCheckboxes] = useState<{
    master: boolean
    ids: string[]
  }>({
    master: false,
    ids: [],
  })

  const { data: payoutsSummary } = usePayoutsCount(
    {
      search,
      filter: filterValueToTableFilter(filtersValue),
      include: !checkboxes.master ? checkboxes.ids : [],
      exclude: checkboxes.master ? checkboxes.ids : [],
    },
    {
      enabled: checkboxes.master || !!checkboxes.ids.length,
    }
  )

  const { data: mostRecentCompletedPayout } = usePayouts({
    search,
    filter: {
      completed: [true],
    },
    pagination: {
      page: 0,
      size: 1,
    },
  })

  const { mutate: releasePayouts, isPending: releasingPayouts } =
    useReleasePayouts()
  const { mutate: getNachaFile } = useGetPayoutNachaFile()
  const { data: availableFilters } = usePayoutFilters(['funding_source_class'])
  const { mutate: reversePayout, isPending: reversingPayout } =
    useReversePayout()

  const filterConfig: IFilterConfig[] = useMemo(
    () => [
      {
        id: 'date',
        type: 'date',
        label: 'Date',
      },
      {
        id: 'type',
        type: 'select',
        label: 'Type',
        options: [
          { value: 'Charge', label: 'Charge' },
          { value: 'Interest', label: 'Interest' },
          {
            value: 'Returned Capital',
            label: 'Returned Capital',
          },
          { value: 'Spread', label: 'Spread' },
        ],
      },
      {
        id: 'fundingSourceClass',
        type: 'select',
        label: 'Class',
        options: availableFilters?.fundingSourceClass?.map(
          (fundingSourceClass: string) => ({
            value: fundingSourceClass,
            label: fundingSourceClass,
          })
        ),
      },
      // {
      //   id: 'status',
      //   type: 'select',
      //   label: 'Status',
      //   options: [
      //     { value: 'Cleared', label: 'Cleared' },
      //     { value: 'Pending', label: 'Pending' },
      //   ],
      // },
    ],
    [availableFilters]
  )

  const handleCheckboxChange = useCallback(
    (id: string) => {
      // completed payouts master checkbox can't be selected and should always unselect on click
      if (id === 'master' && (checkboxes.master || tab === 'completed')) {
        setCheckboxes({ master: false, ids: [] })
      } else if (id === 'master' && !checkboxes.master) {
        setCheckboxes({ master: true, ids: [] })
      } else if (checkboxes.ids.includes(id)) {
        setCheckboxes({ ...checkboxes, ids: without(checkboxes.ids, id) })
      } else {
        setCheckboxes({ ...checkboxes, ids: [...checkboxes.ids, id] })
      }
    },
    [checkboxes, tab]
  )

  const selectedCount = useMemo(() => {
    if (checkboxes.master) {
      return payoutsSummary?.count || 0
    }
    return checkboxes.ids.length
  }, [checkboxes, payoutsSummary])

  useEffect(() => {
    if ((result.data?.meta?.page || 0) === 0) {
      setCheckboxes({ master: false, ids: [] })
    }
  }, [result.data])

  const handleReleasePayouts = ({ date }) => {
    releasePayouts(
      {
        date,
        search,
        filter: filterValueToTableFilter(filtersValue),
        include: !checkboxes.master ? checkboxes.ids : [],
        exclude: checkboxes.master ? checkboxes.ids : [],
      },
      {
        onSuccess: () => {
          resetPagination()
          setPayoutModal(false)
        },
      }
    )
  }

  const handleGenerateNacha = () => {
    getNachaFile(
      {
        search,
        filter: filterValueToTableFilter(filtersValue),
        include: !checkboxes.master ? checkboxes.ids : [],
        exclude: checkboxes.master ? checkboxes.ids : [],
      },
      {
        onSuccess: () => {},
      }
    )
  }

  const handleFilterChange = useCallback(
    (value: IFilterValue) => {
      setSearchParams({ filter: filterValueToUrl(value) }, { replace: true })
      resetPagination()
    },
    [setSearchParams]
  )

  useEffect(() => {
    setIsFetchingVisible(true)
    setCheckboxes({ master: false, ids: [] })
    resetPagination()
  }, [tab])

  useEffect(() => {
    if (!result.isFetching) {
      setIsFetchingVisible(false)
    }
  }, [result.isFetching])

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop title="Payouts" />
        {isEmpty && !mostRecentCompletedPayout?.meta.total ? (
          <EmptyPayouts />
        ) : (
          <Panel>
            <Flex
              gap={4}
              justifyContent="space-between"
              flexWrap="wrap"
              className="pb-4"
            >
              <Flex gap={8}>
                {[
                  { id: 'ready', title: 'Ready' },
                  { id: 'completed', title: 'Paid Out' },
                ].map(({ id, title }) => (
                  <Button
                    key={id}
                    active={tab === id}
                    variant="panel"
                    onClick={() =>
                      navigate({
                        pathname: pathTo('payoutsTab', id),
                        search: searchParams.toString(),
                      })
                    }
                  >
                    {title}
                  </Button>
                ))}
              </Flex>
              <Flex gap={8}>
                <Filter
                  config={filterConfig}
                  value={filtersValue}
                  onApply={handleFilterChange}
                />
                <Search search={search} onSearch={setSearch} />
                {tab === 'ready' ? (
                  <>
                    <Download
                      filename="payouts"
                      download={() =>
                        downloadPayouts(
                          {
                            ...filterValueToTableFilter(filtersValue),
                            completed: [false],
                          },
                          undefined,
                          search
                        )
                      }
                    />
                    <Button
                      variant="primary"
                      disabled={selectedCount === 0}
                      onClick={() => setPayoutModal(true)}
                    >
                      Pay out
                    </Button>
                  </>
                ) : (
                  <>
                    <Download
                      filename="payouts"
                      download={() =>
                        downloadPayouts(
                          {
                            ...filterValueToTableFilter(filtersValue),
                            completed: [true],
                          },
                          undefined,
                          search
                        )
                      }
                    />
                    <Button
                      variant="primary"
                      disabled={selectedCount === 0}
                      onClick={() => handleGenerateNacha()}
                    >
                      Download NACHA
                    </Button>
                  </>
                )}
              </Flex>
            </Flex>
            <TablePayouts
              key={tab}
              data={isFetchingVisible ? [] : visibleItems}
              selectedCount={selectedCount}
              loading={
                result.isPending || (isFetchingVisible && result.isFetching)
              }
              onReverse={setReversePayoutId}
              checkboxes={checkboxes}
              hasFilterUrl={!!filtersValue.length}
              onCheck={handleCheckboxChange}
              isCompleted={tab === 'completed'}
            />
            <LoadMore
              loading={
                result.isPending || (isFetchingVisible && result.isFetching)
              }
              fetching={result.isFetching}
              count={visibleItems.length || 0}
              meta={result.data?.meta}
              onLoadMore={setPagination}
            />
          </Panel>
        )}
      </Flex>
      {payoutModal && !!payoutsSummary && (
        <ModalPayout
          count={payoutsSummary.count}
          amount={payoutsSummary.amount}
          saving={releasingPayouts}
          onSave={handleReleasePayouts}
          onCancel={() => setPayoutModal(false)}
        />
      )}
      {reversePayoutId && (
        <ModalConfirm
          title="Reverse payment"
          text="Are you sure you want to reverse this payment?"
          loading={reversingPayout}
          onConfirm={() => {
            setCheckboxes({
              ...checkboxes,
              ids: without(checkboxes.ids, reversePayoutId),
            })
            reversePayout(reversePayoutId, {
              onSuccess: () => {
                resetPagination()
                setReversePayoutId(undefined)
              },
            })
          }}
          onCancel={() => setReversePayoutId(undefined)}
        />
      )}
    </MainContent>
  )
}

export { Payouts }

import {
  autoUpdate,
  flip,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
  size,
} from '@floating-ui/react'
import clsx from 'clsx'
import { useCallback, useState } from 'react'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Indicator } from 'components/Indicator'
import { PageLoader } from 'components/LoaderOverlay'
import { LoanModification } from 'types'
import { friendlyDate } from 'utils/date'

interface Props {
  modifications: LoanModification[]
  loading: boolean
  onOpen: () => void
  onEditModification: (id: string) => void
  onAddModification: () => void
}

const TabGeneralServicingModifyMenu = ({
  loading,
  onOpen,
  modifications,
  onEditModification,
  onAddModification,
}: Props) => {
  const [open, setOpen] = useState(false)

  const handleOpenChange = useCallback(
    (nextOpen: boolean) => {
      if (nextOpen) {
        onOpen()
      }
      setOpen(nextOpen)
    },
    [onOpen]
  )

  const { x, y, strategy, context, refs } = useFloating({
    open,
    onOpenChange: handleOpenChange,
    whileElementsMounted: autoUpdate,
    placement: 'bottom-end',
    middleware: [
      flip(),
      size({
        apply({ elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
          })
        },
      }),
    ],
  })

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [
      useClick(context, { event: 'click' }),
      useDismiss(context),
      useRole(context, { role: 'tree' }),
    ]
  )

  return (
    <>
      <Flex
        alignItems="center"
        gap={0}
        className="font-bold cursor-pointer"
        {...getReferenceProps({
          ref: refs.setReference,
          onClick(e) {
            e.stopPropagation()
          },
        })}
      >
        Modify <Icon name={IconName.arrowDownFilled} size="lg" />
      </Flex>
      {open && (
        <FloatingPortal>
          <Flex
            stack
            gap={0}
            className="min-w-[280px] bg-white-100 shadow-300 rounded pb-1 border-solid border-[1px] border-grey-200"
            onClick={(e) => e.stopPropagation()}
            {...getFloatingProps({
              ref: refs.setFloating,
              style: {
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                zIndex: 5,
              },
            })}
          >
            {loading ? (
              <PageLoader />
            ) : (
              <>
                {modifications?.map(
                  ({ id, isOrigination, isActive, dateEffective }) => (
                    <Flex
                      key={id}
                      gap={10}
                      alignItems="center"
                      className="m-1 py-1 px-2 rounded-sm hover:bg-grey-75 cursor-pointer"
                      {...getItemProps({
                        onClick: () => {
                          setOpen(false)
                          onEditModification(id)
                        },
                      })}
                    >
                      <Indicator
                        color="green"
                        className={clsx(
                          'w-2.5 h-2.5 flex-shrink-0 rounded-[3px]',
                          isActive
                            ? 'bg-green-50'
                            : 'w-3 h-3 bg-white-100 border-solid border-[2px] border-grey-400'
                        )}
                      />
                      <div className="leading-[18px]">
                        <div>
                          {isOrigination
                            ? 'Loan Origination'
                            : 'Loan Modification'}
                        </div>
                        <div className="text-sm text-grey-700">
                          {friendlyDate(dateEffective) || '-'}
                        </div>
                      </div>
                    </Flex>
                  )
                )}
                <div className="pt-1.5 border-solid border-0 border-t border-grey-200 text-grey-900">
                  <Flex
                    gap={8}
                    alignItems="center"
                    className="mx-1 py-1.5 px-2 rounded-sm hover:bg-grey-75 font-bold cursor-pointer"
                    {...getItemProps({
                      onClick: () => {
                        setOpen(false)
                        onAddModification()
                      },
                    })}
                  >
                    <Icon name={IconName.plusCircle} size="md" />
                    <div className="leading-5">New Loan Modification</div>
                  </Flex>
                </div>
              </>
            )}
          </Flex>
        </FloatingPortal>
      )}
    </>
  )
}

export { TabGeneralServicingModifyMenu }

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { format } from 'date-fns'
import {
  EntityType,
  getImportUploadUrl,
  deleteImportEntities,
} from 'admin/services/api/import'
import {
  BORROWER_IMPORT_BANNER,
  INVESTOR_IMPORT_BANNER,
  VENDOR_IMPORT_BANNER,
  LOAN_IMPORT_BANNER,
  PAYMENT_IMPORT_BANNER,
  FUNDING_IMPORT_BANNER,
  INVESTOR_TRANSACTION_IMPORT_BANNER,
} from 'constants/local-storage-keys'
import {
  KEY_BORROWERS,
  KEY_INVESTORS,
  KEY_LOAN_FUNDING_SOURCES,
  KEY_LOAN_PAYMENTS,
  KEY_LOANS,
  KEY_VENDORS,
} from 'constants/query-keys'
import { uploadDocument } from 'services/api/document'
import { handleErrorResponse } from 'services/request'
import { message } from 'utils/message'

const entityTypeToLocalStorageKey = (entityType: EntityType) => {
  switch (entityType) {
    case 'borrower':
      return BORROWER_IMPORT_BANNER
    case 'investor':
      return INVESTOR_IMPORT_BANNER
    case 'vendor':
      return VENDOR_IMPORT_BANNER
    case 'loan':
      return LOAN_IMPORT_BANNER
    case 'payment':
      return PAYMENT_IMPORT_BANNER
    case 'funding':
      return FUNDING_IMPORT_BANNER
    case 'transaction':
      return INVESTOR_TRANSACTION_IMPORT_BANNER
    default:
      throw 'Invalid entity type'
  }
}

const useImportEntities = (entityType: EntityType, loanId?: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (file: File) => {
      const uploadToast = message.upload(file.name)
      uploadToast.show()
      const { url } = await getImportUploadUrl(entityType, loanId)
      await uploadDocument(url, file, (progressEvent) => {
        uploadToast.progress(progressEvent.loaded / progressEvent.total)
      })
      uploadToast.complete()
      return true
    },
    onSuccess: () => {
      localStorage.setItem(
        entityTypeToLocalStorageKey(entityType),
        format(new Date(), 'MM/dd/yyyy')
      )
      let i = 0
      const keepChecking = setInterval(() => {
        switch (entityType) {
          case 'loan':
            queryClient.invalidateQueries({ queryKey: [KEY_LOANS] })
            queryClient.invalidateQueries({ queryKey: [KEY_BORROWERS] })
            break
          case 'borrower':
            queryClient.invalidateQueries({ queryKey: [KEY_BORROWERS] })
            break
          case 'investor':
            queryClient.invalidateQueries({ queryKey: [KEY_INVESTORS] })
            break
          case 'vendor':
            queryClient.invalidateQueries({ queryKey: [KEY_VENDORS] })
            break
          case 'funding':
            if (loanId) {
              queryClient.invalidateQueries({
                queryKey: [KEY_LOANS, loanId, KEY_LOAN_FUNDING_SOURCES],
              })
            } else {
              i = 1000
            }
            break
          case 'payment':
            if (loanId) {
              queryClient.invalidateQueries({
                queryKey: [KEY_LOAN_PAYMENTS, loanId],
              })
            } else {
              i = 1000
            }
            break
          case 'transaction':
            if (loanId) {
              queryClient.invalidateQueries({
                queryKey: [KEY_INVESTORS, loanId, 'transactions'],
              })
              queryClient.invalidateQueries({
                queryKey: [KEY_INVESTORS, loanId],
              })
            } else {
              i = 1000
            }
            break
        }
        i++

        if (i > 10) {
          clearInterval(keepChecking)
        }
      }, 3000)
    },
    onError: handleErrorResponse,
  })
}

const useDeleteImportEntities = (entityType: EntityType) => {
  return useMutation({
    mutationFn: () => deleteImportEntities(entityType),
    onSuccess: () => {
      localStorage.removeItem(entityTypeToLocalStorageKey(entityType))
    },
    onError: handleErrorResponse,
  })
}

const useCloseImportEntities = (entityType: EntityType) => {
  return () => localStorage.removeItem(entityTypeToLocalStorageKey(entityType))
}

export { useImportEntities, useDeleteImportEntities, useCloseImportEntities }

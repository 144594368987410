import { PropsWithChildren } from 'react'

function TableStickyFooter({ children }: PropsWithChildren) {
  return (
    <div className="sticky bottom-0 z-[1] -mx-5 flex justify-center border-0 border-t border-solid border-grey-200 bg-white-100 p-4">
      {children}
    </div>
  )
}

export { TableStickyFooter }

import { capitalize } from 'lodash'
import { LoanDocument } from 'types'
import { ValueOf } from 'utils/typescript'
import { Icon, IconName } from '../Icon'
import { Badge, BadgeProps } from './index'

interface StatusBadgeProps {
  status: LoanDocument['status']
  changeable?: boolean
  className?: string
}

const statusToColor: Record<
  LoanDocument['status'],
  ValueOf<Pick<BadgeProps, 'color'>>
> = {
  in_review: 'yellow',
  accepted: 'green',
  rejected: 'red',
  expired: 'red',
  pending: 'gray',
  requested: 'blue',
}

function DocumentStatusBadge({
  status,
  changeable,
  className,
}: StatusBadgeProps) {
  return (
    <Badge color={statusToColor[status]} className={className}>
      {capitalize(status.replace('_', ' '))}
      {changeable && <Icon name={IconName.arrowDown} />}
    </Badge>
  )
}

export default DocumentStatusBadge

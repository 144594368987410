import { capitalize } from 'lodash'
import { Fragment } from 'react'
import { displayField } from 'admin/pages/Application/helpers'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { Header } from 'components/Header'
import { Panel } from 'components/Panel'
import { Summary } from 'components/Summary'
import { ApplicationSchemeField, ApplicationSchemePage } from 'types'
import { friendlyDate } from 'utils/date'

interface Props {
  page: ApplicationSchemePage
  dateSubmitted: string
  className?: string
}

function PanelApplicationPage({ page, dateSubmitted, className }: Props) {
  return (
    <Panel key={page.id} title={page.header} className={className}>
      <Grid gap={24}>
        {page.fields
          .filter(({ enabled }) => enabled)
          .map((field) =>
            ['borrower', 'guarantor'].includes(field.type) ? (
              <Grid.Item key={field.id} sm={12}>
                <Flex stack>
                  <Header variant="h4">{capitalize(field.type)}</Header>
                  <Grid gap={24}>
                    {((field.value as any[]) || []).map((person) =>
                      Object.entries(person)
                        .filter(([key]) => !['type', 'person_id'].includes(key))
                        .map(([key, value], index) => {
                          const personField = (
                            person.type === 'entity'
                              ? field.entityFields
                              : field.individualFields
                          )?.find(({ id }) => id === key)
                          return personField?.enabled ? (
                            <Grid.Item key={key + index} sm={6}>
                              <Summary name={personField?.label}>
                                {displayField({
                                  ...personField,
                                  value,
                                } as ApplicationSchemeField)}
                              </Summary>
                            </Grid.Item>
                          ) : null
                        })
                    )}
                  </Grid>
                </Flex>
              </Grid.Item>
            ) : (
              <Fragment key={field.id}>
                {field.type === 'signature' && (
                  <Grid.Item sm={12}>
                    <Summary name="Disclosure">
                      {field.signatureConfirmation}
                    </Summary>
                  </Grid.Item>
                )}
                <Grid.Item sm={6}>
                  <Summary name={field.label}>{displayField(field)}</Summary>
                </Grid.Item>
                {field.type === 'signature' && (
                  <Grid.Item sm={6}>
                    <Summary name="Signed">
                      {friendlyDate(dateSubmitted)}
                    </Summary>
                  </Grid.Item>
                )}
              </Fragment>
            )
          )}
      </Grid>
    </Panel>
  )
}

export { PanelApplicationPage }

import { useEffect, useRef } from 'react'

const useEffectOnceWhen = (
  callback,
  deps: any[] = [],
  predicate = () => true
) => {
  const hasBeenCalledRef = useRef(false)

  useEffect(() => {
    if (!hasBeenCalledRef.current) {
      if (predicate()) {
        callback()
        hasBeenCalledRef.current = true
      }
    }
  }, [...deps, callback, predicate])
}

export { useEffectOnceWhen }

import { clsx } from 'clsx'
import { Formik } from 'formik'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, NavLink, useSearchParams } from 'react-router-dom'
import { pathTo as adminPathTo } from 'admin/path-to'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Form, Field } from 'components/Form'
import { Header } from 'components/Header'
import { Logo } from 'components/Logo'
import { Text } from 'components/Text'
import { subdomain } from 'constants/domain'
import { useClient } from 'hooks/use-client'
import { useSession } from 'hooks/use-session'
import { Credentials } from 'services/api/session'
import { getRedirectOnLoginUrl } from 'services/request'
import { createScheme, email, mergeRules, required } from 'utils/schemas'
import { getAuthIntegrations } from './helpers'
import styles from './styles.module.scss'

interface Props {
  admin?: boolean
  pathTo: any
}

const SignInSchema = createScheme({
  email: mergeRules(email, required),
  password: required,
})

const initialValues: Omit<Credentials, 'subdomain' | 'isAdmin'> = {
  email: '',
  password: '',
}

function SignIn({ pathTo, admin }: Props) {
  const navigate = useNavigate()
  const { data: client, isPending: isLoading } = useClient({
    enabled: true,
  })
  const [logins, setLogins] = useState<Array<any>>()
  const { signIn, isSigningIn, errors } = useSession()
  const handleSubmit = useCallback(
    ({ email, password }: Omit<Credentials, 'subdomain' | 'isAdmin'>) => {
      signIn({
        email,
        password,
        subdomain: subdomain,
        isAdmin: !!admin,
      })
    },
    [subdomain, admin]
  )
  const [searchParams] = useSearchParams()

  const getLogins = useCallback(async () => {
    const authIntegrations = await getAuthIntegrations()
    setLogins(authIntegrations)
  }, [])

  useEffect(() => {
    getLogins()
  }, [getLogins])

  useEffect(() => {
    const jwt = searchParams?.get('jwt')
    const redirect = searchParams?.get('redirect')
    if (jwt) {
      signIn({
        isAdmin: !!admin,
        jwt,
        subdomain,
      })
      searchParams.delete('jwt')
      searchParams.delete('redirect')
      const redirectUrl = redirect
        ? new URL(redirect, window.location.origin)
        : null
      const redirectUrlSearchParams = new URLSearchParams({
        ...Object.fromEntries(redirectUrl?.searchParams || []),
        ...Object.fromEntries(searchParams),
      })
      navigate(
        {
          pathname: redirectUrl ? redirectUrl.pathname : undefined,
          search: redirectUrlSearchParams.toString(),
        },
        { replace: true }
      )
    }
  }, [searchParams])

  const redirectUrl = encodeURIComponent(
    `${document.location.origin}${pathTo('signIn')}?redirect=${getRedirectOnLoginUrl()}`
  )

  return !client && !isLoading ? (
    <div className={styles.container}>
      <div className={styles.formBox}>
        <div className="content-center">
          <Header variant="h4" className={styles.description}>
            No site is configured at this address.
          </Header>
          <Button
            onClick={() =>
              (window.location.href = 'https://www.baselinesoftware.com')
            }
          >
            Back to Baseline
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.container}>
      <div className={styles.formBox}>
        <Logo color="blue" to={pathTo('signIn')} />
        <Formik
          initialValues={initialValues}
          validationSchema={SignInSchema}
          onSubmit={handleSubmit}
          validateOnChange={false}
        >
          <Form className={styles.form} method="post">
            <Header variant="h1" className={styles.title}>
              Log In
            </Header>
            <Text className={styles.description}>
              Welcome to our secure portal. Please enter your credentials below
              to sign-in and get started.
            </Text>
            {errors && errors.length > 0 && (
              <div className={styles.errors}>
                {errors.map((message, index) => (
                  <div key={index} className={styles.error}>
                    {message}
                  </div>
                ))}
              </div>
            )}
            {logins && (
              <>
                {logins?.map((auth) => (
                  <Button
                    key={auth.name}
                    iconLeft={
                      <img src={auth.logo_url} width={20} height={20} />
                    }
                    variant="secondary"
                    className={clsx(styles.authButton, {
                      [styles.lastAuthButton]:
                        logins[logins.length - 1] === auth,
                    })}
                    onClick={() => {
                      document.location.href = `https://api.unified.to/unified/integration/login/6670ecd29da376c87a94a653/${auth.type}?redirect=true&success_redirect=${redirectUrl}`
                    }}
                  >
                    Continue with {auth.name}
                  </Button>
                ))}
                <Flex gap={24} alignItems="center" className="w-full mt-4 mb-9">
                  <div className="w-full border-0 border-b border-solid border-grey-200" />
                  <Text variant="l" className="text-grey-700 text-nowrap">
                    or, sign in with your email
                  </Text>
                  <div className="w-full border-0 border-b border-solid border-grey-200" />
                </Flex>
              </>
            )}
            <Field name="email" label="Email" autoComplete="email" />
            <Field
              name="password"
              type="password"
              label="Password"
              autoComplete="password"
            />
            <Button loading={isSigningIn} type="submit">
              Log in
            </Button>
            <div className={styles.divider} />
            <Flex gap={16}>
              <NavLink to={pathTo('forgotPassword')} className="link">
                Forgot password?
              </NavLink>
              {!admin && (
                <a href={adminPathTo('dashboard')} className="link">
                  Admin
                </a>
              )}
            </Flex>
          </Form>
        </Formik>
        <div />
      </div>
      {admin && <div className={styles.background}></div>}
    </div>
  )
}

export { SignIn }

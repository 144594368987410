import { parseISO } from 'date-fns'
import { Formik } from 'formik'
import { useState } from 'react'
import { Button } from 'components/Button'
import { Form, Date } from 'components/Form'
import { Grid } from 'components/Grid'
import { createScheme, required } from 'utils/schemas'
import { Modal } from '../index'
import styles from './styles.module.scss'

interface IPayoffStatement {
  date: string
  expirationDate: string
}

const Schema = createScheme({
  date: required,
  expirationDate: required,
})

interface Props {
  onSubmit: (values: IPayoffStatement) => void
  onCancel: () => void
}

function ModalGeneratePayoff({ onSubmit, onCancel }: Props) {
  const [payoffStatement, setPayoffStatement] = useState<IPayoffStatement>({
    date: '',
    expirationDate: '',
  })

  return (
    <Modal title="Payoff Statement" onClose={onCancel} className={styles.modal}>
      <Formik
        initialValues={{
          date: payoffStatement.date,
          expirationDate: payoffStatement.expirationDate,
        }}
        validationSchema={Schema}
        enableReinitialize
        onSubmit={onSubmit}
      >
        <Form>
          <Grid className="mt-4" columnGap={16}>
            <Grid.Item xs={6}>
              <Date
                label="Payoff Date"
                name="date"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setPayoffStatement((payoffStatement) => ({
                    date: event.target.value,
                    expirationDate:
                      parseISO(payoffStatement.expirationDate).getTime() <
                      parseISO(event.target.value).getTime()
                        ? event.target.value
                        : payoffStatement.expirationDate,
                  }))
                }
              />
            </Grid.Item>
            <Grid.Item xs={6}>
              <Date
                label="Expiration Date"
                name="expirationDate"
                minDate={payoffStatement.date}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setPayoffStatement((payoffStatement) => ({
                    ...payoffStatement,
                    expirationDate: event.target.value,
                  }))
                }
              />
            </Grid.Item>
            <Grid.Item xs={12} className="flex justify-end gap-4">
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit">Generate</Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  )
}

export default ModalGeneratePayoff

import { CellContext, ColumnDef } from '@tanstack/react-table'
import { parse, isBefore } from 'date-fns'
import { isNil } from 'lodash'
import { Fragment, Dispatch, SetStateAction, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { pathTo } from 'admin/path-to'
import { ServicingLoanStatusBadge } from 'components/Badge'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { OwnerSelector, useOwnerSelector } from 'components/OwnerSelector'
import { Table } from 'components/Table'
import { TextLink } from 'components/TextLink'
import { Tooltip } from 'components/Tooltip'
import { useUpdateLoanOwners } from 'hooks/use-loans'
import { Filter, Loan, Sort } from 'types'
import { formatUsd } from 'utils/currency'
import { friendlyDate } from 'utils/date'
import styles from './styles.module.scss'

type Props = {
  data?: Loan[]
  tab: 'servicing' | 'liquidated'
  onClick: (loan?: Loan) => void
  filter: Filter | undefined
  showAdmins?: boolean
  onFilter: Dispatch<SetStateAction<Filter>>
  sort?: Sort | undefined
  onSort?: (sort: Sort | string | undefined) => void
  loading: boolean
  onUpdateItem: (loan: Loan) => void
}

export const ServicingTable = ({
  data = [],
  tab,
  sort,
  onSort,
  filter,
  showAdmins = false,
  onFilter,
  loading,
  onClick,
  onUpdateItem,
}: Props) => {
  const { options } = useOwnerSelector()
  const { mutate: updateOwners } = useUpdateLoanOwners()

  const navigate = useNavigate()
  const columns: ColumnDef<Loan>[] = useMemo(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
        meta: {
          sortable: true,
        },
      },
      {
        header: 'Origination Date',
        accessorKey: 'dateClosing',
        cell: ({ getValue }) => friendlyDate(getValue() as string) || '-',
        meta: {
          sortable: true,
        },
      },
      {
        header: 'Borrower',
        accessorKey: 'borrowers',
        cell: ({ row }) =>
          row.original?.borrowers.map(({ name, id }, index) => (
            <Fragment key={id}>
              <TextLink
                className={styles.link}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  navigate(pathTo('borrower', id))
                }}
              >
                {name}
              </TextLink>
              {index === row.original?.borrowers.length - 1 ? '' : ', '}
            </Fragment>
          )) || '-',
      },
      {
        header: 'Principal Balance',
        accessorKey: 'principalBalance',
        cell: ({ getValue }) => {
          const amount = getValue() as number | null
          return isNil(amount) ? '-' : formatUsd(amount, { showZero: true })
        },
        meta: {
          sortable: true,
          align: 'right',
        },
      },
      {
        header: 'Maturity Date',
        accessorKey: 'dateMaturity',
        cell: ({ getValue, row }) => {
          const dateMaturity = getValue() as string
          const isMatured =
            dateMaturity &&
            isBefore(
              parse(dateMaturity, 'yyyy-MM-dd', new Date()),
              new Date()
            ) &&
            row.original.status !== 'liquidated'
          return (
            <Flex gap={6} className={isMatured ? styles.matured : undefined}>
              {friendlyDate(getValue() as string) || '-'}
              {isMatured && (
                <Tooltip content="Matured">
                  <Icon name={IconName.info} size="sm" />
                </Tooltip>
              )}
            </Flex>
          )
        },
        meta: {
          sortable: true,
        },
      },
      {
        header: 'Account Owner',
        accessorKey: 'owners',
        size: 120,
        cell: (cellContext) => {
          const { row, hover, active, onActive } = cellContext as CellContext<
            Loan,
            unknown
          > & {
            hover: boolean
            active: boolean
            onActive: (active: boolean) => void
          }
          const selectedOwners = row.original.owners || []
          const missedOptions =
            selectedOwners?.filter(({ id }) => {
              return !options.find((option) => option.id === id)
            }) || []
          return (
            <OwnerSelector
              variant="small"
              readOnly={!hover && !active}
              selectedUsers={selectedOwners.map(({ id }) => id)}
              userOptions={[
                ...options,
                ...missedOptions.map((option) => ({ ...option, email: '-' })),
              ]}
              onChange={(owners) => {
                updateOwners(
                  { id: row.original.id, owners },
                  { onSuccess: onUpdateItem }
                )
              }}
              onOpen={onActive}
            />
          )
        },
      },
      {
        header: 'Status',
        id: 'status',
        size: 120,
        cell: ({ row }) => {
          const { daysLate, daysPastDue, status, dateDefault } = row.original
          return (
            <ServicingLoanStatusBadge
              status={status}
              daysLate={daysLate}
              daysPastDue={daysPastDue}
              dateDefault={dateDefault}
            />
          )
        },
        meta: {
          filter:
            tab === 'servicing'
              ? {
                  options: [
                    {
                      value: 'Performing',
                      label: 'Performing',
                    },
                    {
                      value: 'Due',
                      label: 'Due',
                    },
                    {
                      value: 'Late',
                      label: 'Late',
                    },
                    {
                      value: 'Default',
                      label: 'Default',
                    },
                  ],
                  selected: filter?.servicingStatus,
                  onChange: (servicingStatus) => onFilter({ servicingStatus }),
                }
              : undefined,
        },
      },
    ],
    [tab, showAdmins, options, updateOwners, filter, onFilter]
  )

  return (
    <Table
      columns={columns}
      data={data}
      sort={sort}
      onSort={onSort}
      loading={loading}
      onClick={onClick}
    />
  )
}

import { request } from 'services/request'
import { Search, Filter, Meta, Pagination, Payout } from 'types'

const getPayouts = async (params: {
  search?: Search
  filter?: Filter
  page?: Pagination
}): Promise<{ transactions: Payout[]; meta: Meta }> => {
  const {
    data: { transactions, pagination },
  } = await request.get(
    `/payout${(params.filter?.completed as boolean[])?.includes(true) ? '/completed' : ''}`,
    {
      params,
    }
  )
  return { transactions, meta: pagination }
}

const reversePayout = async (id: string) => {
  const { data } = await request.post(`/payout/${id}`, { status: 'cleared' })
  return { data }
}

const getPayoutFilters = async (
  columns: string[]
): Promise<Record<string, any>> => {
  const { data } = await request.get(
    `/payout/filters?columns=${columns.join(',')}`
  )
  return data
}

const payoutsCount = async ({
  search,
  filter,
  include,
  exclude,
}: {
  search?: Search
  filter?: Filter
  include: string[]
  exclude: string[]
}): Promise<{ amount: number; count: number }> => {
  const { data } = await request.put(
    '/payout',
    { include, exclude },
    { params: { search, filter } }
  )
  return data
}

const releasePayouts = async ({
  search,
  filter,
  ...rest
}: {
  date: string
  search?: Search
  filter?: Filter
  include: string[]
  exclude: string[]
}): Promise<{ transactions: Payout[]; meta: Meta }> => {
  const {
    data: { transactions, pagination },
  } = await request.post('/payout', rest, { params: { search, filter } })
  return { transactions, meta: pagination }
}

const getPayoutsNachaFileUrl = async ({
  search,
  filter,
  include,
  exclude,
}: {
  search?: Search
  filter?: Filter
  include: string[]
  exclude: string[]
}): Promise<{ download: string }> => {
  const { data } = await request.post(
    `/payout/completed/nacha`,
    { include, exclude },
    { params: { search, filter } }
  )
  return data
}

export {
  getPayouts,
  reversePayout,
  getPayoutFilters,
  payoutsCount,
  releasePayouts,
  getPayoutsNachaFileUrl,
}

import { memo } from 'react'
import { Icon, IconName } from 'components/Icon'
import styles from './styles.module.scss'

const BodyLoader = memo(() => (
  <tr>
    <td>
      <div className={styles.tableBodyContent}>
        <Icon name={IconName.loaderSpinner} className="spinner" />
        Loading...
      </div>
    </td>
  </tr>
))

BodyLoader.displayName = 'BodyLoader'

export { BodyLoader }

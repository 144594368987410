import { compact, uniqBy } from 'lodash'
import { useEffect, useState } from 'react'
import { Avatar } from 'components/Avatar'
import { DocumentStatusBadge, Badge } from 'components/Badge'
import { Button } from 'components/Button'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Header as TextHeader } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { Text } from 'components/Text'
import { Tooltip } from 'components/Tooltip'
import { DocumentVersion, LoanDocument } from 'types'
import { friendlyDate } from 'utils/date'
import { isPrintable } from 'utils/file'
import styles from './styles.module.scss'

interface Props {
  document: LoanDocument
  isLocked: boolean
  version?: DocumentVersion
  sharePersons?: { id: string; name: string; typeOfPerson: string }[]
  isSignature?: boolean
  onBack: () => void
  onEdit: (document: Partial<LoanDocument>) => void
  onRename: () => void
  onShare?: () => void
  onUpload: () => void
  onDownload: () => void
  onPrint: () => void
  onDelete: () => void
  onRequest?: () => void
  onSignature?: (startShare) => void
}

export function Header({
  document,
  isLocked,
  version,
  sharePersons,
  isSignature,
  onBack,
  onEdit,
  onRename,
  onShare,
  onUpload,
  onDownload,
  onPrint,
  onDelete,
  onRequest,
  onSignature,
}: Props) {
  const [status, setStatus] = useState(document.status)
  const statuses: LoanDocument['status'][] = compact([
    'pending',
    onRequest ? 'requested' : null,
    'in_review',
    'accepted',
    'rejected',
  ])
  const handleStatusChange = (nextStatus: LoanDocument['status']) => {
    setStatus(nextStatus)
    onEdit({ status: nextStatus })
  }
  useEffect(() => {
    setStatus(document.status)
  }, [document.status])

  return (
    <div className={styles.header}>
      <div className={styles.details}>
        <a className={styles.back} onClick={onBack}>
          <Icon name={IconName.arrowLeft} />
        </a>
        <div className={styles.fileInfo}>
          <Flex alignItems="center">
            <TextHeader variant="h3">{document.name}</TextHeader>
            {document.isShared && (
              <Tooltip
                className={styles.tooltip}
                placement="bottom-start"
                content={
                  sharePersons?.length ? (
                    uniqBy(sharePersons, 'id').map((person) => (
                      <Flex
                        className={styles.person}
                        alignItems="center"
                        key={person.id + person.typeOfPerson}
                        gap={8}
                      >
                        <Tooltip content={person.name}>
                          <Avatar id={person.id} name={person.name} />
                        </Tooltip>
                        <Text className="font-bold">{person.name}</Text>
                      </Flex>
                    ))
                  ) : (
                    <Text className="font-bold">
                      No one has access to this document
                    </Text>
                  )
                }
              >
                <div>
                  <Badge color="blue">
                    <Flex alignItems="center" gap={8}>
                      <Icon
                        className={styles.sharedIcon}
                        name={IconName.share}
                        size="sm"
                      />
                      <div className={styles.badge}>Shared</div>
                    </Flex>
                  </Badge>
                </div>
              </Tooltip>
            )}
          </Flex>
          {document.uploadedDate && (
            <div className={styles.metadata}>
              <div>Uploaded {friendlyDate(document.uploadedDate)}</div>
            </div>
          )}
        </div>
      </div>
      {isSignature && onSignature && onShare ? (
        <div className={styles.actions}>
          <Button variant="ghost" onClick={() => onSignature(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => onSignature(true)}>
            Send for Signature
          </Button>
        </div>
      ) : (
        <div className={styles.actions}>
          {isLocked ? (
            <DocumentStatusBadge className={styles.btnStatus} status={status} />
          ) : (
            <EllipsesActions
              trigger={
                <div>
                  <DocumentStatusBadge
                    className={styles.btnStatus}
                    status={status}
                    changeable
                  />
                </div>
              }
            >
              {statuses.map((status) => (
                <EllipsesActions.Item
                  key={status}
                  className={styles.statusDropdownItem}
                  icon
                  onSelect={() => handleStatusChange(status)}
                >
                  <DocumentStatusBadge status={status} />
                </EllipsesActions.Item>
              ))}
            </EllipsesActions>
          )}
          <EllipsesActions
            trigger={
              <div>
                <Button
                  className={styles.btnActions}
                  iconRight={<Icon name={IconName.arrowDown} />}
                >
                  Actions
                </Button>
              </div>
            }
          >
            <EllipsesActions.Item
              icon
              disabled={isLocked}
              onSelect={() => onRename()}
            >
              <Icon name={IconName.edit} />
              Rename
            </EllipsesActions.Item>
            {onRequest ? (
              <EllipsesActions.Item
                icon
                disabled={isLocked}
                onSelect={() => onRequest()}
              >
                <Icon name={IconName.requestDocument} />
                Request
              </EllipsesActions.Item>
            ) : (
              <></>
            )}
            <EllipsesActions.Item
              icon
              disabled={isLocked}
              onSelect={() => onUpload()}
            >
              <Icon name={IconName.upload} />
              Upload
            </EllipsesActions.Item>
            <EllipsesActions.Item
              disabled={!version}
              icon
              onSelect={() => onDownload()}
            >
              <Icon name={IconName.download} />
              Download
            </EllipsesActions.Item>
            <EllipsesActions.Item
              disabled={!isPrintable(version?.type)}
              icon
              onSelect={() => onPrint()}
            >
              <Icon name={IconName.print} />
              Print
            </EllipsesActions.Item>
            {onShare ? (
              <EllipsesActions.Item
                icon
                disabled={isLocked}
                onSelect={() => onShare()}
              >
                <Icon name={IconName.share} />
                {document.isShared ? 'Unshare' : 'Share'}
              </EllipsesActions.Item>
            ) : (
              <></>
            )}
            <EllipsesActions.Item
              icon
              disabled={isLocked || !onSignature}
              onSelect={onSignature ? () => onSignature(false) : () => {}}
            >
              <Icon name={IconName.pen} />
              E-signature
            </EllipsesActions.Item>
            <EllipsesActions.Item
              icon
              disabled={isLocked}
              onSelect={() => onDelete()}
              className="text-red-100"
            >
              <Icon name={IconName.delete} />
              Delete
            </EllipsesActions.Item>
          </EllipsesActions>
        </div>
      )}
    </div>
  )
}

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import {
  addAddress,
  getBorrower,
  updateAddress,
  updateBorrower,
  updateOwners,
} from 'admin/services/api/borrowers'
import { pathTo } from 'borrower/path-to'
import { KEY_BORROWERS } from 'constants/query-keys'
import { useSession } from 'hooks/use-session'
import {
  createCompanyAccount,
  createPersonalAccount,
  switchContext,
} from 'services/api/borrower'
import { handleErrorResponse } from 'services/request'
import { message } from 'utils/message'

const useCreateCompanyAccount = () => {
  return useMutation({
    mutationFn: ({
      entity = 'borrower',
      name,
    }: {
      entity?: 'borrower' | 'investor'
      name: string
    }) => createCompanyAccount({ entity, name }),
    onSuccess: () => {
      message.success('Company account created')
    },
    onError: handleErrorResponse,
  })
}

const useSwitchAccount = () => {
  const { refreshUser } = useSession()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  return useMutation({
    mutationFn: ({ personId }: { personId: string }) =>
      switchContext({ personId }),
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: [] })
      }, 1)
      refreshUser()
      navigate(pathTo('dashboard'))
      message.success('Context switched')
    },
    onError: handleErrorResponse,
  })
}

const useCreatePersonalAccount = () => {
  return useMutation({
    mutationFn: ({ userId }: { userId: string }) =>
      createPersonalAccount({ userId }),
    onSuccess: () => {
      message.success('Personal account created')
    },
    onError: handleErrorResponse,
  })
}

const useUpdateBorrower = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateBorrower,
    onError: handleErrorResponse,
    onSuccess: (borrower) => {
      queryClient.invalidateQueries({ queryKey: [KEY_BORROWERS, borrower.id] })
    },
  })
}

const useBorrower = (
  {
    id,
    params,
  }: {
    id: string
    params?: { secure?: boolean }
  },
  options?: { enabled: boolean }
) => {
  return useQuery({
    queryKey: [KEY_BORROWERS, id, params?.secure],
    queryFn: () => getBorrower(id, params),
    ...options,
  })
}

const useAddBorrowerAddress = (borrowerId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: addAddress,
    onError: handleErrorResponse,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_BORROWERS, borrowerId] })
    },
  })
}
const useUpdateBorrowerAddress = (borrowerId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateAddress,
    onError: handleErrorResponse,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_BORROWERS, borrowerId] })
    },
  })
}

const useUpdateBorrowerOwners = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ id, owners }: { id: string; owners: string[] }) =>
      updateOwners({ id, owners }),
    onError: handleErrorResponse,
    onSuccess: (borrower) => {
      queryClient.invalidateQueries({ queryKey: [KEY_BORROWERS, borrower?.id] })
    },
  })
}

export {
  useCreateCompanyAccount,
  useCreatePersonalAccount,
  useSwitchAccount,
  useBorrower,
  useUpdateBorrower,
  useAddBorrowerAddress,
  useUpdateBorrowerAddress,
  useUpdateBorrowerOwners,
}

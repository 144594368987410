import { Icon, IconName } from 'components/Icon'
import { Text } from 'components/Text'
import { TextLink } from 'components/TextLink'
import styles from './styles.module.scss'

interface Props {
  title: string
  subtitle: string
  onAdd: () => void
}

const NoEntities = ({ title, subtitle, onAdd }: Props) => {
  return (
    <div className={styles.main} onClick={onAdd}>
      <div className={styles.blueContainer}>
        <Icon
          name={IconName.documentEdit}
          size="xxxl"
          className={styles.icon}
        />
        <Text className={styles.title}>{title}</Text>
        <div className={styles.subContainer}>
          <TextLink className={styles.subtitle}>
            {subtitle}
            <Icon name={IconName.arrowRightLong} size="md" />
          </TextLink>
        </div>
      </div>
    </div>
  )
}

export { NoEntities }

import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useApplicationDocuments } from 'borrower/hooks/use-application'
import { pathTo } from 'borrower/path-to'
import { Grid } from 'components/Grid'
import { PageLoader } from 'components/LoaderOverlay'
import { Panel } from 'components/Panel'
import { TableDocuments } from 'components/TableDocuments'
import { useUploadDocument } from 'hooks/use-document'
import { LoanDocument, Application } from 'types'
import { openBrowseFile } from 'utils/file'
import styles from './styles.module.scss'

interface Section {
  name: string
  section: string
  guarantorId?: string
  borrowerId?: string
  documents: LoanDocument[]
}
interface Props {
  application: Application
}

export const TabDocuments = ({ application }: Props) => {
  const navigate = useNavigate()
  const { data: documents, isPending } = useApplicationDocuments({
    id: application.id,
  })
  const { mutate: uploadDocument } = useUploadDocument()
  const handleUpload = (id: string) => {
    const doc = documents?.find(({ id: docId }) => id === docId)
    openBrowseFile({
      onChoose: (files) => {
        uploadDocument({ id, name: doc?.name, file: files[0] })
      },
    })
  }
  const handleDropRow = (id: string, files: FileList) => {
    const doc = documents?.find(({ id: docId }) => id === docId)
    uploadDocument({ id, name: doc?.name, file: files[0] })
  }
  const handleView = (id: string) => {
    navigate(pathTo('applicationDocument', application.id, id))
  }

  const sections: Section[] = useMemo(() => {
    return [
      ...(application.borrowers?.map(({ id, name }) => ({
        name: `Borrower${application.borrowers.length > 1 ? ` - ${name}` : ''}`,
        section: 'Borrower',
        borrowerId: id,
        documents: (documents || []).filter(
          ({ section, borrowerId }) =>
            section === 'Borrower' && borrowerId === id
        ),
      })) || []),
      ...(application.guarantors?.map(({ id, name }) => ({
        name: `Guarantor${
          application.guarantors.length > 1 ? ` - ${name}` : ''
        }`,
        section: 'Guarantor',
        guarantorId: id,
        documents: (documents || []).filter(
          ({ section, guarantorId }) =>
            section === 'Guarantor' && guarantorId === id
        ),
      })) || []),
      {
        name: 'Collateral',
        section: 'Collateral',
        documents: (documents || []).filter(
          ({ section }) => section === 'Collateral'
        ),
      },
      {
        name: 'Loan',
        section: 'Loan',
        documents: (documents || []).filter(
          ({ section }) => section === 'Loan'
        ),
      },
      {
        name: 'Closing',
        section: 'Closing',
        documents: (documents || []).filter(
          ({ section }) => section === 'Closing'
        ),
      },
      {
        name: 'Other',
        section: 'Other',
        documents: (documents || []).filter(
          ({ section }) =>
            ![
              'Borrower',
              'Guarantor',
              'Collateral',
              'Loan',
              'Closing',
            ].includes(section)
        ),
      },
    ].filter(({ documents }) => documents.length > 0)
  }, [application, documents])
  return isPending ? (
    <PageLoader />
  ) : (
    <Grid gap={16}>
      <Grid.Item sm={12} className={styles.panels}>
        {sections.length ? (
          sections.map(({ name, documents }) => (
            <Panel key={name} title={name}>
              <TableDocuments
                disabled={false}
                hideEllipsesMenu
                data={documents}
                onUpload={handleUpload}
                onFileDrop={handleDropRow}
                onView={handleView}
                minWidth={600}
              />
            </Panel>
          ))
        ) : (
          <div className={styles.emptyDocuments}>
            None have been created yet
          </div>
        )}
      </Grid.Item>
    </Grid>
  )
}

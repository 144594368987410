import { ColumnDef } from '@tanstack/react-table'
import { compact } from 'lodash'
import { useMemo } from 'react'
import { DocumentStatusBadge, Badge } from 'components/Badge'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Table } from 'components/Table'
import { TextLink } from 'components/TextLink'
import { useMobile } from 'hooks/use-mobile'
import { LoanDocument } from 'types'
import { friendlyDate } from 'utils/date'
import { friendlyMime, friendlySize } from 'utils/file'
import styles from './styles.module.scss'

interface Props {
  data?: LoanDocument[]
  disabled?: boolean
  hideEllipsesMenu?: boolean
  minWidth?: number
  loading?: boolean
  hideStatus?: boolean
  showDownload?: boolean
  onOpen?: (id: string) => void
  onEdit?: (id: string) => void
  onRequest?: (id: string) => void
  onDownload?: (id: string) => void
  onUpload?: (id: string) => void
  onFileDrop?: (id: string, files: FileList) => void
  onView?: (id: string) => void
  onDelete?: (id: string) => void
}

const documentStatesWhenCanUpload = ['requested', 'rejected', 'in_review']

function TableDocuments({
  data = [],
  disabled,
  minWidth = 0,
  hideStatus,
  hideEllipsesMenu,
  showDownload,
  loading,
  onOpen,
  onEdit,
  onDownload = () => {},
  onUpload,
  onFileDrop,
  onView,
  onDelete,
  onRequest,
}: Props) {
  const { isTablet } = useMobile()

  const columns: ColumnDef<LoanDocument>[] = useMemo(
    () =>
      compact([
        {
          header: 'Name',
          accessorKey: 'name',
          cell: ({ row, getValue }) => {
            const name = getValue() as string
            return onView && row.original.isShared ? (
              <TextLink onClick={() => onView(row.original.id)}>
                {name}
              </TextLink>
            ) : (
              <>
                {name}
                {row.original.isShared && !onView ? (
                  <>
                    &nbsp;&nbsp;
                    <Badge color="blue">
                      <Flex alignItems="center" gap={8}>
                        <Icon
                          className={styles.sharedIcon}
                          name={IconName.share}
                          size="sm"
                        />
                        <div className={styles.badge}>Shared</div>
                      </Flex>
                    </Badge>
                  </>
                ) : null}
              </>
            )
          },
        },
        {
          header: 'Type',
          accessorKey: 'type',
          size: 100,
          cell: (cell) => {
            const meme = (cell.getValue() as string) || ''
            return friendlyMime(meme)
          },
        },
        {
          header: 'Size',
          accessorKey: 'size',
          size: 100,
          cell: (cell) => {
            const size = cell.getValue() as number
            return friendlySize(size)
          },
        },
        {
          header: 'Uploaded Date',
          accessorKey: 'uploadedDate',
          size: 160,
          cell: (cell) => {
            const date = cell.getValue() as string
            return friendlyDate(date)
          },
        },
        hideStatus
          ? null
          : {
              header: 'Status',
              accessorKey: 'status',
              size: 110,
              cell: (cell) => {
                const status = cell.getValue() as LoanDocument['status']
                return <DocumentStatusBadge status={status} />
              },
            },
        {
          id: 'actions',
          header: '',
          size: hideEllipsesMenu ? 120 : 80,
          cell: ({ row }) => {
            const id = row.original.id
            return disabled ? null : (
              <div className={`${isTablet ? '' : 'justify-content-end flex'}`}>
                {documentStatesWhenCanUpload.includes(row.original.status) &&
                  onUpload && (
                    <TextLink
                      className={
                        hideEllipsesMenu
                          ? styles.iconUploadHideEllipses
                          : styles.iconUpload
                      }
                      onClick={(e) => {
                        e.stopPropagation()
                        onUpload(id)
                      }}
                    >
                      <Icon name={IconName.upload} size="md" />
                      {hideEllipsesMenu && 'Upload'}
                    </TextLink>
                  )}
                {row.original.isShared &&
                  !documentStatesWhenCanUpload.includes(row.original.status) &&
                  onView && (
                    <TextLink
                      className={
                        hideEllipsesMenu
                          ? styles.iconUploadHideEllipses
                          : styles.iconUpload
                      }
                      onClick={(e) => {
                        e.stopPropagation()
                        onView(id)
                      }}
                    >
                      <Icon name={IconName.view} size="md" />
                      {hideEllipsesMenu && 'View'}
                    </TextLink>
                  )}
                {showDownload && (
                  <TextLink
                    className={
                      hideEllipsesMenu
                        ? styles.iconUploadHideEllipses
                        : styles.iconUpload
                    }
                    onClick={(e) => {
                      e.stopPropagation()
                      onDownload(id)
                    }}
                  >
                    <Icon name={IconName.download} size="md" />
                    {hideEllipsesMenu && 'Download'}
                  </TextLink>
                )}
                {hideEllipsesMenu ? null : (
                  <EllipsesActions>
                    {onEdit ? (
                      <EllipsesActions.Item icon onSelect={() => onEdit(id)}>
                        <Icon name={IconName.edit} />
                        Edit
                      </EllipsesActions.Item>
                    ) : (
                      <></>
                    )}
                    {onRequest ? (
                      <EllipsesActions.Item icon onSelect={() => onRequest(id)}>
                        <Icon name={IconName.requestDocument} />
                        Request
                      </EllipsesActions.Item>
                    ) : (
                      <></>
                    )}
                    {onUpload ? (
                      <EllipsesActions.Item icon onSelect={() => onUpload(id)}>
                        <Icon name={IconName.upload} />
                        Upload
                      </EllipsesActions.Item>
                    ) : (
                      <></>
                    )}
                    <EllipsesActions.Item
                      icon
                      disabled={!row.original.size}
                      onSelect={() => onDownload(id)}
                    >
                      <Icon name={IconName.download} />
                      Download
                    </EllipsesActions.Item>
                    {onDelete ? (
                      <EllipsesActions.Item
                        icon
                        onSelect={() => onDelete(id)}
                        className="text-red-100"
                      >
                        <Icon name={IconName.delete} />
                        Delete
                      </EllipsesActions.Item>
                    ) : (
                      <></>
                    )}
                  </EllipsesActions>
                )}
              </div>
            )
          },
        },
      ]),
    [isTablet]
  )

  return (
    <Table
      loading={loading}
      minWidth={minWidth}
      className={styles.tableDocuments}
      columns={columns}
      data={data.map((doc) => ({
        ...doc,
        rowClassName: doc.isCustom ? styles.rowCustom : undefined,
      }))}
      onFileDrop={
        disabled || !onFileDrop
          ? undefined
          : (row, files) => onFileDrop((row as LoanDocument).id, files)
      }
      onClick={onOpen ? (row) => onOpen((row as LoanDocument).id) : undefined}
    />
  )
}

export default TableDocuments

import { useMutation } from '@tanstack/react-query'
import { flatten } from 'lodash'
import { useState } from 'react'
import { BorrowerDetails, inviteEmail } from 'admin/services/api/borrowers'
import { Button } from 'components/Button'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'
import { ModalAddress } from 'components/Modal/Address'
import { ModalPersonInfo } from 'components/Modal/PersonInfo'
import { Panel } from 'components/Panel'
import PanelEmail from 'components/Panel/PanelEmail'
import PanelEntityInformation from 'components/Panel/PanelEntityInformation'
import PanelPersonalInformation from 'components/Panel/PanelPersonalInformation'
import { AddressSummary } from 'components/Summary'
import { TextLink } from 'components/TextLink'
import {
  useAddBorrowerAddress,
  useUpdateBorrower,
  useUpdateBorrowerAddress,
} from 'hooks/use-borrower'
import { handleErrorResponse } from 'services/request'
import { Address } from 'types/address'
import { isAddressDefined } from 'utils/address'
import { message } from 'utils/message'
import PanelLinkedAccounts from './PanelLinkedAccounts'
import styles from './styles.module.scss'

interface Props {
  borrower: BorrowerDetails
  onSecure: (secure: boolean) => void
}

function TabGeneral({ borrower, onSecure }: Props) {
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false)
  const [isAddressModalVisible, setIsAddressModalVisible] = useState(false)
  const [sentInvite, setSentInvite] = useState(false)
  const { mutate: updateBorrower, isPending: isUpdatingBorrower } =
    useUpdateBorrower()
  const resetPassword = useMutation({
    mutationFn: inviteEmail,
    onError: handleErrorResponse,
    onSuccess: () => {
      message.success('Password reset')
    },
  })
  const inviteBorrower = useMutation({
    mutationFn: inviteEmail,
    onError: handleErrorResponse,
    onSuccess: () => {
      setSentInvite(true)
      message.success('Invitation sent')
    },
  })
  const { mutate: addAddress, isPending: addingAddress } =
    useAddBorrowerAddress(borrower.id)
  const { mutate: updateAddress, isPending: updatingAddress } =
    useUpdateBorrowerAddress(borrower.id)
  const saveAddresses = (addresses: Record<'primary' | 'mailing', Address>) => {
    const currentPrimary = borrower.addresses.find(
      ({ type }) => type === 'primary'
    )
    const currentMailing = borrower.addresses.find(
      ({ type }) => type === 'mailing'
    )
    const options = {
      onSuccess: () => {
        message.success('Address saved')
        setIsAddressModalVisible(false)
      },
    }

    if (currentPrimary) {
      updateAddress(
        {
          id: borrower.id,
          addressId: currentPrimary.id,
          address: addresses.primary,
        },
        options
      )
    } else {
      addAddress(
        {
          id: borrower.id,
          address: { ...addresses.primary, type: 'primary' },
        },
        options
      )
    }

    if (currentMailing) {
      updateAddress(
        {
          id: borrower.id,
          addressId: currentMailing.id,
          address: addresses.mailing,
        },
        options
      )
    } else {
      addAddress(
        {
          id: borrower.id,
          address: { ...addresses.mailing, type: 'mailing' },
        },
        options
      )
    }
  }

  const addresses = borrower.addresses
    .sort((a) => (a.type === 'primary' ? -1 : 1))
    .filter((address) => isAddressDefined(address))

  const isIndividual = !borrower.type || borrower.type === 'individual'

  return (
    <div className={styles.tabContent}>
      {isIndividual &&
        ['draft', 'invited', 'active'].includes(borrower.status) && (
          <Button
            className={styles.tabButton}
            onClick={() => inviteBorrower.mutate({ id: borrower.id })}
            loading={inviteBorrower.isPending}
            iconLeft={<Icon name={IconName.send} />}
          >
            {borrower.status !== 'draft' || sentInvite
              ? 'Re-invite User'
              : 'Invite User'}
          </Button>
        )}

      <Grid gap={16}>
        <Grid.Item sm={12} md={6} className={styles.panels}>
          {isIndividual ? (
            <PanelPersonalInformation
              person={borrower}
              onSecure={onSecure}
              onEdit={() => {
                onSecure(true)
                setIsInfoModalVisible(true)
              }}
              onResetPassword={resetPassword.mutate}
              isPasswordResetting={resetPassword.isPending}
              personType="borrower"
            />
          ) : (
            <PanelEntityInformation
              person={borrower}
              onEdit={() => {
                onSecure(true)
                setIsInfoModalVisible(true)
              }}
              onSecure={onSecure}
            />
          )}
          <Panel title="Address" onEdit={() => setIsAddressModalVisible(true)}>
            <Grid>
              {addresses.length ? (
                addresses.map((address) => (
                  <Grid.Item sm={6} key={address.id}>
                    <AddressSummary address={address} />
                  </Grid.Item>
                ))
              ) : (
                <Grid.Item sm={12}>
                  <div className={styles.link}>
                    <TextLink onClick={() => setIsAddressModalVisible(true)}>
                      <Icon name={IconName.plus} size="sm" />
                      Add Address
                    </TextLink>
                  </div>
                </Grid.Item>
              )}
            </Grid>
          </Panel>
        </Grid.Item>
        <Grid.Item sm={12} md={6} className={styles.panels}>
          <PanelEmail
            id={borrower.id}
            type={isIndividual ? 'individual' : 'entity'}
            emails={borrower.emails}
            isInvited={['invited', 'active'].includes(borrower.status)}
            readonlyEmails={flatten(
              borrower.managers?.map((m) => m.emails || [])
            )}
          />
          <PanelLinkedAccounts borrower={borrower} />
        </Grid.Item>
        {isInfoModalVisible && (
          <ModalPersonInfo
            personType="borrower"
            person={borrower}
            saving={isUpdatingBorrower}
            onSave={(values) =>
              updateBorrower(
                { ...values, id: borrower.id },
                {
                  onSuccess: () => {
                    message.success('Information saved')
                    setIsInfoModalVisible(false)
                  },
                }
              )
            }
            onCancel={() => setIsInfoModalVisible(false)}
          />
        )}
        {isAddressModalVisible && (
          <ModalAddress
            addresses={borrower.addresses}
            saving={addingAddress || updatingAddress}
            onSave={(addresses) => saveAddresses(addresses)}
            onCancel={() => setIsAddressModalVisible(false)}
          />
        )}
      </Grid>
    </div>
  )
}

export default TabGeneral

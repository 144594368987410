import { pathTo } from 'admin/path-to'
import { IconName } from 'components/Icon'
import SideNavigation from 'components/SideNavigation/SideNavigation'
import SideNavigationLink from 'components/SideNavigation/SideNavigationLink'
import SideNavigationSupportItem from 'components/SideNavigation/SideNavigationSupportItem'
import { useIntercom } from 'hooks/use-intercom'
import { useMobile } from 'hooks/use-mobile'
import { useSession } from 'hooks/use-session'
import styles from './styles.module.scss'

interface Props {
  collapsed?: boolean
  setIsMobileNavVisible: (visible: boolean) => void
}

function AdminSideNavigation({ collapsed, setIsMobileNavVisible }: Props) {
  const { user, isIndividual } = useSession()
  const { toggleIntercom } = useIntercom()
  const { isTablet } = useMobile()

  const isOrigination = user?.client.origination
  const isServicing = user?.client.servicing

  return (
    <SideNavigation
      collapsed={collapsed}
      pathTo={pathTo}
      setIsMobileNavVisible={setIsMobileNavVisible}
    >
      <div>
        {!collapsed && <div className={styles.subHeader}>GENERAL</div>}
        <SideNavigationLink
          to={pathTo('tasks', 'tasks')}
          onClick={() => setIsMobileNavVisible(false)}
          label="Tasks"
          icon={IconName.task}
          collapsed={collapsed}
        />
        <SideNavigationLink
          to={pathTo('borrowers')}
          onClick={() => setIsMobileNavVisible(false)}
          label="Borrowers"
          icon={IconName.borrower}
          collapsed={collapsed}
        />
        <SideNavigationLink
          to={pathTo('investors')}
          onClick={() => setIsMobileNavVisible(false)}
          label="Investors"
          icon={IconName.investor}
          collapsed={collapsed}
        />

        <SideNavigationLink
          to={pathTo('funds')}
          onClick={() => setIsMobileNavVisible(false)}
          label="Funds"
          icon={IconName.fund}
          collapsed={collapsed}
        />
        <SideNavigationLink
          to={pathTo('vendors')}
          onClick={() => setIsMobileNavVisible(false)}
          label="Vendors"
          icon={IconName.vendor}
          collapsed={collapsed}
        />
        {!isIndividual && (
          <SideNavigationLink
            to={pathTo('reports')}
            onClick={() => setIsMobileNavVisible(false)}
            label="Reports"
            icon={IconName.report}
            collapsed={collapsed}
          />
        )}
        {!isIndividual && (
          <SideNavigationLink
            to={pathTo('marketplace')}
            onClick={() => setIsMobileNavVisible(false)}
            label="Marketplace"
            icon={IconName.marketplace}
            collapsed={collapsed}
          />
        )}
        {isOrigination && (
          <>
            {!collapsed && <div className={styles.subHeader}>ORIGINATION</div>}
            <SideNavigationLink
              to={pathTo('loans')}
              onClick={() => setIsMobileNavVisible(false)}
              label="Pipeline"
              icon={IconName.pipeline}
              collapsed={collapsed}
            />
            <SideNavigationLink
              to={pathTo('quotes')}
              onClick={() => setIsMobileNavVisible(false)}
              label="Quotes"
              icon={IconName.quote}
              collapsed={collapsed}
            />{' '}
          </>
        )}
        {isServicing && (
          <>
            {!collapsed && <div className={styles.subHeader}>SERVICING</div>}
            <SideNavigationLink
              to={pathTo('servicing')}
              onClick={() => setIsMobileNavVisible(false)}
              label="Loans"
              icon={IconName.documentDollar}
              collapsed={collapsed}
            />
            <SideNavigationLink
              to={pathTo('payments', 'due')}
              onClick={() => setIsMobileNavVisible(false)}
              label="Payments"
              icon={IconName.loan}
              collapsed={collapsed}
            />
            <SideNavigationLink
              to={pathTo('payouts')}
              onClick={() => setIsMobileNavVisible(false)}
              label="Payouts"
              icon={IconName.payout}
              collapsed={collapsed}
            />
          </>
        )}
        <SideNavigationLink
          to={pathTo('offerings')}
          onClick={() => setIsMobileNavVisible(false)}
          label="Offerings"
          icon={IconName.offering}
          collapsed={collapsed}
        />
      </div>
      {isTablet && (
        <div>
          <SideNavigationLink
            to={pathTo('settings')}
            onClick={() => setIsMobileNavVisible(false)}
            label="Settings"
            icon={IconName.settings}
            collapsed={collapsed}
          />
          {user && (
            <SideNavigationSupportItem
              onClick={() => {
                setIsMobileNavVisible(false)
                toggleIntercom()
              }}
              label="Support"
              icon={IconName.support}
              collapsed={collapsed}
            />
          )}
          <SideNavigationLink
            to={pathTo('logout')}
            onClick={() => setIsMobileNavVisible(false)}
            label="Log out"
            icon={IconName.logout}
            collapsed={collapsed}
          />
        </div>
      )}
    </SideNavigation>
  )
}

export default AdminSideNavigation

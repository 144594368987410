import clsx from 'clsx'
import { take, drop, intersection } from 'lodash'
import { Avatar } from 'components/Avatar'
import { Checkbox } from 'components/Checkbox'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import theme from 'styles/theme'
import { Tooltip } from '../Tooltip'
import styles from './styles.module.scss'

interface Props {
  options: { id: string; name: string }[]
  selected: string[]
  onSelect: (id: string) => void
}

const VISIBLE_COUNT = 3

export const UserSelector = ({ options, selected, onSelect }: Props) => {
  const visibleOptions = take(options, VISIBLE_COUNT)
  const dropdownOptions = drop(options, VISIBLE_COUNT)

  return (
    <Flex gap={0}>
      {visibleOptions.map(({ id, name }) => (
        <div
          key={id}
          onClick={() => onSelect(id)}
          className={clsx(
            styles.avatarWrapper,
            selected.includes(id) && styles.selected
          )}
        >
          <Tooltip content={name} placement="bottom">
            <Avatar id={id} name={name} className={styles.avatar} />
          </Tooltip>
        </div>
      ))}
      {dropdownOptions.length === 1 &&
        dropdownOptions.map(({ id, name }) => (
          <div
            key={id}
            onClick={() => onSelect(id)}
            className={clsx(
              styles.avatarWrapper,
              selected.includes(id) && styles.selected
            )}
          >
            <Tooltip content={name} placement="bottom">
              <Avatar id={id} name={name} className={styles.avatar} />
            </Tooltip>
          </div>
        ))}
      {dropdownOptions.length > 1 && (
        <EllipsesActions
          placement="bottom-start"
          hideOnSelect={false}
          trigger={
            <div
              className={clsx(
                styles.avatarWrapper,
                intersection(
                  selected,
                  dropdownOptions.map(({ id }) => id)
                ).length && styles.selected
              )}
            >
              <Avatar
                name={`+${dropdownOptions.length}`}
                exactName
                color={theme.color.blue200}
                background={theme.color.blue25}
                className={styles.avatar}
              />
            </div>
          }
        >
          {dropdownOptions.map(({ id, name }) => (
            <EllipsesActions.Item key={id} icon onSelect={() => onSelect(id)}>
              <Flex gap={8} alignItems="center" className="-ml-1">
                <Checkbox checked={selected.includes(id)} onChange={() => {}} />
                <Avatar id={id} name={name} />
                <div>{name}</div>
              </Flex>
            </EllipsesActions.Item>
          ))}
        </EllipsesActions>
      )}
    </Flex>
  )
}

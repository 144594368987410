import { Form, Formik } from 'formik'
import { SelectFieldType } from 'admin/pages/CustomFields/SelectFieldType'
import { Button } from 'components/Button'
import { Field, Textarea } from 'components/Form'
import { Grid } from 'components/Grid'
import { Modal } from 'components/Modal'
import { BaseField, FieldType } from 'types'

type FormValues = {
  name: string
  type: FieldType
  description: string
}

interface Props {
  field?: BaseField
  saving: boolean
  onSave: (values: FormValues) => void
  onCancel: () => void
}

function ModalField({ field, saving, onSave, onCancel }: Props) {
  const initialValue: FormValues = {
    name: field?.name || '',
    type: field?.type[0] || 'text',
    description: field?.description || '',
  }
  return (
    <Modal
      title={field ? 'Edit Custom Product Field' : 'Add Custom Product Field'}
      onClose={onCancel}
    >
      <Formik initialValues={initialValue} onSubmit={onSave}>
        <Form>
          <Grid columnGap={16}>
            <Grid.Item xs={12}>
              <Field
                label="Field Label"
                type="name"
                name="name"
                placeholder="Field name"
              />
            </Grid.Item>
            <Grid.Item xs={12}>
              <SelectFieldType />
            </Grid.Item>
            <Grid.Item xs={12}>
              <Textarea
                name="description"
                label="Description"
                placeholder="Description"
              />
            </Grid.Item>
            <Grid.Item xs={12} className="flex justify-end gap-4">
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button loading={saving} type="submit">
                Save
              </Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  )
}

export { ModalField }

import { request } from 'services/request'
import { Filter, Meta, Pagination, Search, Transaction, Sort } from 'types'
import { updateRequestTable } from 'utils/api/table-parser'

export type InvestorPayment = {
  amount: number
  type: 'Deposit' | 'Withdrawal'
  date: string
  description: string
}

const getInvestorFundingSources = async ({
  investorId,
  filter,
  from,
  date,
  sort,
  hideOldLiquidated,
}: {
  investorId: string
  filter?: Filter
  from?: string
  date?: string
  sort?: Sort
  hideOldLiquidated?: boolean
}): Promise<any[]> => {
  const {
    data: { fundingSources },
  } = await request.get(`/investor/${investorId}/funding_source`, {
    params: updateRequestTable({
      filter,
      from,
      date,
      sort,
      hideOldLiquidated: hideOldLiquidated || '',
    }),
  })

  return (
    fundingSources.map((investment) => ({
      ...investment,
      rate: investment.rate * 100,
    })) || []
  )
}

const getInvestorTransactions = async ({
  id,
  isBorrower,
  ...params
}: {
  id: string
  isBorrower?: boolean
  search?: Search
  filter?: Filter
  page?: Pagination
}): Promise<{ transactions: Transaction[]; meta: Meta }> => {
  const userRole = isBorrower ? 'borrower' : 'investor'
  const {
    data: { transactions, pagination },
  } = await request.get(`/${userRole}/${id}/transaction`, { params })
  return { transactions, meta: pagination }
}

const addInvestorPayment = (
  investorId: string,
  payload: InvestorPayment
): Promise<void> => {
  return request.post(`investor/${investorId}/payment`, payload)
}

const deleteInvestorPayment = (paymentId: string): Promise<void> => {
  return request.delete(`/payment/${paymentId}`)
}

export {
  getInvestorTransactions,
  getInvestorFundingSources,
  addInvestorPayment,
  deleteInvestorPayment,
}

import { format } from 'date-fns'
import { Formik } from 'formik'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Form, Date as DateField } from 'components/Form'
import { Grid } from 'components/Grid'
import { Modal } from 'components/Modal'
import { formatUsd } from 'utils/currency'
import { createScheme, required } from 'utils/schemas'
import styles from './styles.module.scss'

interface Props {
  count: number
  amount: number
  saving: boolean
  onSave: (data: FormValues) => void
  onCancel: () => void
}

const FormSchema = createScheme({
  date: required,
})

type FormValues = {
  date: string
}

function Payout({ count, amount, saving, onSave, onCancel }: Props) {
  const initialValues: FormValues = {
    date: format(new Date(), 'yyyy-MM-dd'),
  }

  return (
    <Modal
      title="Payout"
      onClose={onCancel}
      className={styles.modal}
      crossClose
    >
      <Formik
        initialValues={initialValues}
        validationSchema={FormSchema}
        onSubmit={onSave}
      >
        <Form>
          <Flex stack>
            <p>
              Confirm releasing {count} payouts for a total of{' '}
              {formatUsd(amount)}.
            </p>
            <Grid>
              <Grid.Item xs={6}>
                <DateField name="date" label="Effective Payout Date" />
              </Grid.Item>
            </Grid>

            <div className={styles.buttons}>
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit" loading={saving}>
                Release
              </Button>
            </div>
          </Flex>
        </Form>
      </Formik>
    </Modal>
  )
}

export default Payout

import clsx from 'clsx'
import { ReactNode, useEffect, useRef } from 'react'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import Portal from 'components/Modal/Portal'
import { useModal } from 'hooks/use-modal'

interface ModalProps {
  children: ReactNode
  onClose: () => void
  className?: string
  loading?: boolean
}

function ModalFullSize({ children, onClose, loading, className }: ModalProps) {
  const modal = useRef<HTMLInputElement>(null)
  useModal({ onClose, closeOnClickOutside: true })

  // focus first input in the modal
  useEffect(() => {
    const { current: modalEl } = modal
    if (modalEl && !loading) {
      const inputEl = modalEl.querySelector(
        'input:not([placeholder="MM/DD/YYYY"]), textarea'
      ) as HTMLInputElement
      setTimeout(() => inputEl?.focus(), 1)
    }
  }, [loading])

  return (
    <Portal className="fixed inset-0 left-[210px] z-[5]">
      {loading ? (
        <div>
          <Icon name={IconName.loaderSpinner} size="xl" className="spinner" />
        </div>
      ) : (
        <div
          ref={modal}
          className={clsx(
            className,
            'h-full bg-white-100 px-10 py-7 overflow-auto'
          )}
        >
          <div className="max-w-[1200px] mx-auto">
            <Flex alignItems="center" justifyContent="flex-end">
              <div className="p-3 cursor-pointer" onClick={onClose}>
                <Icon name={IconName.close} size="md" className="w-4 h-4" />
              </div>
            </Flex>
            {children}
          </div>
        </div>
      )}
    </Portal>
  )
}

export { ModalFullSize }

import clsx from 'clsx'
import {
  ChangeEvent,
  FocusEvent,
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from 'react'
import { IMaskInput } from 'react-imask'
import { mergeRefs } from 'react-merge-refs'
import styles from '../Form/styles.module.scss'

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & {
  decimal?: boolean
  mask?: string | null
}

const InputNumber = forwardRef(
  (
    {
      className,
      value,
      onChange,
      autoComplete = 'off',
      mask,
      decimal,
      placeholder,
      onBlur,
      ...rest
    }: Props,
    ref: ForwardedRef<HTMLInputElement | null>
  ) => {
    const [inputValue, setInputValue] = useState(value)
    const imaskRef = useRef<any>(null)
    useEffect(() => {
      if (
        imaskRef.current &&
        imaskRef.current.maskRef.unmaskedValue !== value
      ) {
        imaskRef.current.element.value = value
        imaskRef.current.maskRef.updateValue()
        imaskRef.current.maskRef.updateControl()
      }
    }, [value])

    return (
      <IMaskInput
        ref={mergeRefs([imaskRef, ref])}
        mask={[
          {
            mask: '',
          },
          {
            mask: mask?.replaceAll('a', '\\a') || '#',
            lazy: false,
            blocks: {
              '#': {
                mask: Number,
                scale: decimal ? 20 : 0,
                lazy: false,
                thousandsSeparator: ',',
                radix: '.',
              },
            },
          },
        ]}
        unmask="typed"
        type="text"
        defaultValue={value}
        className={clsx(styles.input, className)}
        autoComplete={autoComplete}
        onAccept={(value, mask, event) => {
          setInputValue(mask.unmaskedValue as string)
          if (event) {
            const target = event.target as HTMLInputElement
            const currentValue = target.value
            target.value = mask.unmaskedValue as string
            onChange &&
              onChange(event as unknown as ChangeEvent<HTMLInputElement>)
            target.value = currentValue
          }
        }}
        onBlur={(event) => {
          if (event) {
            const target = event.target as HTMLInputElement
            const currentValue = target.value
            target.value = inputValue as string
            onBlur && onBlur(event as unknown as FocusEvent<HTMLInputElement>)
            target.value = currentValue
          }
        }}
        placeholder={placeholder ? placeholder : mask || ''}
        {...rest}
      />
    )
  }
)
InputNumber.displayName = 'InputNumber'

export default InputNumber

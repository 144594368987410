import clsx from 'clsx'
import { useState, useEffect } from 'react'
import { useReport } from 'admin/hooks/use-report'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { Text } from 'components/Text'
import { Tooltip } from 'components/Tooltip'
import {
  ReportCashBalancesType,
  ReportNumberType,
  ReportCashBalancesData,
} from 'types'
import { formatUsd } from 'utils/currency'
import { formatPercent } from 'utils/percent'
import styles from './styles.module.scss'

interface KPIs {
  id: ReportNumberType | ReportCashBalancesType
  type: 'cash-balances' | 'percentage' | 'currency' | 'number'
  title: 'Total Cash' | 'Invested' | 'Investments' | 'Portfolio Yield'
}

interface Params {
  loaded: number
  onLoad: () => void
  className?: string
  kpis: KPIs[]
}

const format = (value, type) => {
  switch (type) {
    case 'percentage':
      return formatPercent(value * 100)
    case 'currency':
      return formatUsd(value)
    default:
      return value
  }
}

function WidgetMultiKPIs({ loaded, onLoad, kpis, className }: Params) {
  const [countOfLoadedData, setCountOfLoadedData] = useState(0)

  const data = kpis.map(({ id }, index) =>
    useReport(id, {
      enabled: loaded >= index + 1,
    })
  )
  const isPending = data.some(({ isPending }) => isPending)

  useEffect(() => {
    const successfulDownloadCounter = data.filter((kpi) => kpi.isSuccess).length
    if (successfulDownloadCounter > countOfLoadedData) {
      onLoad()
      setCountOfLoadedData((count) => count + 1)
    }
  }, [data])

  return (
    <div className={clsx(styles.widget, styles.kpis, 'normal-nums', className)}>
      {isPending ? (
        <div className={styles.loader}>
          <Icon name={IconName.loaderSpinner} className="spinner" size="xl" />
        </div>
      ) : (
        <>
          <Header variant="h4">My Personal Account Info</Header>
          <Flex stack gap={12} className="mt-4">
            {kpis.map(({ id, type, title }, index) => {
              switch (type) {
                case 'cash-balances': {
                  const balance = data[index]
                    .data as unknown as ReportCashBalancesData
                  return (
                    <Flex stack gap={4} key={id}>
                      <Text className="text-grey-700 font-normal">{title}</Text>
                      <Header
                        variant="h3"
                        className="overflow-hidden text-ellipsis whitespace-nowrap"
                      >
                        {formatUsd(balance.cash)}
                      </Header>
                      {balance.cash !== balance.availableCash && (
                        <Flex alignItems="center" gap={4}>
                          <Text className="text-grey-700 font-normal text-sm">
                            {formatUsd(balance.availableCash)} Available
                          </Text>
                          <div className="flex-shrink-0">
                            <Tooltip content="Cash that isn’t pending investment in a loan">
                              <Icon
                                name={IconName.info}
                                size="sm"
                                className="text-grey-700"
                              />
                            </Tooltip>
                          </div>
                        </Flex>
                      )}
                    </Flex>
                  )
                }
                default:
                  return (
                    <Flex stack gap={4} key={id}>
                      <Text className="text-grey-700 font-normal">{title}</Text>
                      <Header
                        variant="h3"
                        className="overflow-hidden text-ellipsis whitespace-nowrap"
                      >
                        {format(data[index].data, type)}
                      </Header>
                    </Flex>
                  )
              }
            })}
          </Flex>
        </>
      )}
    </div>
  )
}

export { WidgetMultiKPIs }

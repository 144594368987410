import { DocumentStatusBadge } from 'components/Badge'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Header as TextHeader } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { DocumentVersion, LoanDocument } from 'types'
import { friendlyDate } from 'utils/date'
import { isPrintable } from 'utils/file'
import styles from './styles.module.scss'

interface Props {
  document: LoanDocument
  version?: DocumentVersion
  onBack: () => void
  onDownload: () => void
  onPrint: () => void
}

export function Header({
  document,
  version,
  onBack,
  onDownload,
  onPrint,
}: Props) {
  return (
    <div className={styles.header}>
      <div className={styles.details}>
        <a className={styles.back} onClick={onBack}>
          <Icon name={IconName.arrowLeft} />
        </a>
        <div className={styles.fileInfo}>
          <Flex alignItems="center">
            <TextHeader variant="h3">{document.name}</TextHeader>
          </Flex>
          {document.uploadedDate && (
            <div className={styles.metadata}>
              <div>Uploaded {friendlyDate(document.uploadedDate)}</div>
            </div>
          )}
        </div>
      </div>
      <div className={styles.actions}>
        <DocumentStatusBadge
          className={styles.btnStatus}
          status={document.status}
        />

        {!document?.esignatureSlug && (
          <>
            <Button
              variant="secondary"
              onClick={() => onPrint()}
              disabled={!isPrintable(version?.type)}
              iconLeft={<Icon name={IconName.print} size="sm" />}
            >
              Print
            </Button>
            <Button
              variant="primary"
              onClick={() => onDownload()}
              disabled={!version}
              iconLeft={<Icon name={IconName.download} size="sm" />}
            >
              Download
            </Button>
          </>
        )}
      </div>
    </div>
  )
}

import clsx from 'clsx'
import { ReactNode } from 'react'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import styles from './styles.module.scss'

interface Props {
  severity?: 'info' | 'warning'
  children: ReactNode
  onClose?: () => void
}

export const Alert = ({ severity = 'info', children, onClose }: Props) => {
  const classNames = clsx(styles.alert, {
    [styles.info]: severity === 'info',
    [styles.warning]: severity === 'warning',
  })

  return (
    <Flex className={classNames} alignItems="center" gap={8}>
      <Icon className={styles.icon} name={IconName.info} size="md" />
      <div>{children}</div>
      {onClose && (
        <div className="absolute right-2 cursor-pointer p-1" onClick={onClose}>
          <Icon className={styles.icon} name={IconName.close} size="sm" />
        </div>
      )}
    </Flex>
  )
}

import clsx from 'clsx'
import { DragEvent, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useLoanType } from 'admin/hooks/use-loan-type'
import { useLoanContext } from 'admin/pages/Loan/LoanContext'
import { pathTo } from 'admin/path-to'
import { Button } from 'components/Button'
import { DragDropFile } from 'components/DragDropFile'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'
import { PanelDrawRequests } from 'components/Panel/PanelDrawRequests'
import { PanelRehabBudget } from 'components/Panel/PanelRehabBudget'
import { PanelScopeOfWorkStructuralChanges } from 'components/Panel/PanelScopeOfWorkStructuralChanges'
import { PanelLoanFields } from 'components/PanelLoanFields'
import { AddressSummary } from 'components/Summary'
import { Tooltip } from 'components/Tooltip'
import { useUploadLoanAddressRehabBudget } from 'hooks/use-loan-address'
import { getLoanAddressRehabUrl } from 'services/api/loan-address'
import { Loan, Address } from 'types'
import { openBrowseFile } from 'utils/file'
import { message } from 'utils/message'
import styles from './styles.module.scss'

interface Props {
  loan: Loan
}

export default function TabScopeOfWork({ loan }: Props) {
  const navigate = useNavigate()
  const { isOrigination } = useLoanType()
  const { entityId: addressId = loan.addresses[0]?.id } = useParams()
  const { settings } = useLoanContext()

  const [isDragActive, setIsDragActive] = useState(false)
  const address = loan.addresses.find(({ id }) => id === addressId) as Address
  const { mutate: upload } = useUploadLoanAddressRehabBudget({
    loanId: loan.id,
    addressId,
    uploadUrl: address.rehabUploadUrl,
  })
  const handleDownload = async () => {
    const hideToast = message.progress('Downloading template...', 20000)
    const url = await getLoanAddressRehabUrl(loan.id, addressId)
    hideToast()
    window.location.href = url
  }
  const handleUpload = () => {
    openBrowseFile({
      onChoose: (files) => upload(files[0]),
      accept: '.xlsx',
    })
  }
  const handleDrag = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setIsDragActive(true)
    } else if (e.type === 'dragleave') {
      setIsDragActive(false)
    }
  }
  const handleDrop = (files: FileList) => {
    setIsDragActive(false)
    upload(files[0])
  }

  return (
    <div
      className="mt-4 border border-solid border-grey-200 rounded bg-white-100 relative"
      onDragEnter={loan.lock ? () => {} : handleDrag}
    >
      <div>
        <EllipsesActions
          trigger={
            <div className={styles.tabButton}>
              <Button iconRight={<Icon name={IconName.arrowDown} />}>
                Actions
              </Button>
            </div>
          }
        >
          <EllipsesActions.Item
            icon
            disabled={loan.lock}
            onSelect={() => handleUpload()}
          >
            <Icon name={IconName.upload} />
            Upload Worksheet
          </EllipsesActions.Item>
          <EllipsesActions.Item icon onSelect={() => handleDownload()}>
            <Icon name={IconName.download} />
            Download Worksheet
          </EllipsesActions.Item>
        </EllipsesActions>
      </div>

      {isDragActive && !loan.lock && (
        <div className={styles.dropZone}>
          <DragDropFile
            onLeave={() => setIsDragActive(false)}
            onDrop={(files) => handleDrop(files)}
            accept={['xlsx']}
          />
        </div>
      )}

      <Flex gap={0}>
        <Flex
          stack
          className="border-0 border-solid border-r border-grey-200 p-2.5 bg-grey-25 rounded-l relative"
          gap={10}
        >
          {loan.addresses.map((address) => (
            <Flex alignItems="center" key={address.id}>
              <Tooltip content={address.street1}>
                <div
                  className={clsx(
                    'w-8 h-8 text-grey-600 cursor-pointer border border-solid border-grey-200 rounded flex items-center justify-center',
                    address.id !== addressId && 'hover:bg-grey-75',
                    address.id === addressId &&
                      'bg-blue-200 text-white-100 border-blue-200'
                  )}
                  onClick={() =>
                    navigate(
                      pathTo(
                        isOrigination
                          ? 'loanTabEntityId'
                          : 'servicingLoanTabEntityId',
                        loan.id,
                        'scopeOfWork',
                        address.id
                      )
                    )
                  }
                >
                  <Icon name={IconName.address} />
                </div>
              </Tooltip>
              {address.id === addressId && (
                <div className="absolute h-[13px] w-[3px] bg-blue-200 right-0" />
              )}
            </Flex>
          ))}
        </Flex>
        <Flex className="p-6 flex-grow">
          <Grid gap={16}>
            <Grid.Item sm={6} className={styles.panels}>
              <PanelLoanFields
                title="Rehab Overview"
                sections={['Rehab Overview']}
                loan={loan}
                address={address}
                includeAtTop={
                  <Grid.Item sm={6} key={address.id}>
                    <AddressSummary address={address} />
                  </Grid.Item>
                }
              />
            </Grid.Item>
            <Grid.Item sm={6} className={styles.panels}>
              <PanelScopeOfWorkStructuralChanges
                loanId={loan.id}
                address={address}
              />
            </Grid.Item>
            <Grid.Item sm={12}>
              <PanelRehabBudget loan={loan} address={address} />
            </Grid.Item>
            {settings.showDrawRequests ? (
              <Grid.Item sm={12}>
                <PanelDrawRequests loan={loan} address={address} />
              </Grid.Item>
            ) : null}
          </Grid>
        </Flex>
      </Flex>
    </div>
  )
}

import {
  useMutation,
  useQuery,
  useQueryClient,
  keepPreviousData,
} from '@tanstack/react-query'
import { size } from 'lodash'
import { KEY_INVESTORS, KEY_REPORT } from 'constants/query-keys'
import {
  getInvestorTransactions,
  getInvestorFundingSources,
  addInvestorPayment,
  deleteInvestorPayment,
  InvestorPayment,
} from 'services/api/investor-payment'
import { handleErrorResponse } from 'services/request'
import { Filter, Pagination, Sort } from 'types'
import { message } from 'utils/message'

const useInvestorFundingSources = ({
  investorId,
  params,
}: {
  investorId: string
  params?: {
    from?: string
    date?: string
    filter?: Filter
    sort?: Sort
    hideOldLiquidated?: boolean
  }
}) => {
  const nextFilter = size(params?.filter) ? params?.filter : undefined
  return useQuery({
    queryKey: [
      KEY_INVESTORS,
      investorId,
      'funding_sources',
      nextFilter,
      params?.date,
      params?.from,
      params?.sort,
      params?.hideOldLiquidated,
    ],
    queryFn: () =>
      getInvestorFundingSources({ investorId, filter: nextFilter, ...params }),
    placeholderData: keepPreviousData,
  })
}

const useInvestorTransactions = ({
  id,
  isBorrower,
  search,
  filter,
  pagination,
}: {
  id: string
  isBorrower?: boolean
  search?: string
  filter?: Filter
  pagination?: Pagination
}) => {
  const nextFilter = size(filter) ? filter : undefined
  return useQuery({
    queryKey: [
      KEY_INVESTORS,
      id,
      'transactions',
      search,
      nextFilter,
      pagination,
    ],
    queryFn: () =>
      getInvestorTransactions({
        id,
        isBorrower,
        search,
        filter: nextFilter,
        page: pagination,
      }),
    placeholderData: keepPreviousData,
  })
}

const useAddInvestorPayment = (investorId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (investorPayment: InvestorPayment) =>
      addInvestorPayment(investorId, investorPayment),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [KEY_INVESTORS, investorId, 'transactions'],
      })
      queryClient.invalidateQueries({
        queryKey: [KEY_REPORT, `investor/${investorId}/cash-balances`],
      })
      queryClient.invalidateQueries({
        queryKey: [
          KEY_REPORT,
          `investor/${investorId}/principal-invested-balance`,
        ],
      })
      message.success('Transaction created')
    },
    onError: handleErrorResponse,
  })
}

const useDeleteInvestorPayment = (investorId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (paymentId: string) => deleteInvestorPayment(paymentId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [KEY_INVESTORS, investorId, 'transactions'],
      })
      queryClient.invalidateQueries({
        queryKey: [KEY_REPORT, `investor/${investorId}/cash-balances`],
      })
      queryClient.invalidateQueries({
        queryKey: [
          KEY_REPORT,
          `investor/${investorId}/principal-invested-balance`,
        ],
      })
      message.success('Payment deleted')
    },
    onError: handleErrorResponse,
  })
}

export {
  useInvestorFundingSources,
  useInvestorTransactions,
  useAddInvestorPayment,
  useDeleteInvestorPayment,
}

import { useEffect, useReducer } from 'react'
import {
  createSession,
  endSession,
  getCurrentUser,
  CurrentUser,
  Credentials,
} from 'services/api/session'

type State =
  | { value: 'valid'; context: { user: CurrentUser } }
  | { value: 'invalid'; context: { errors?: Array<string> } }
  | { value: 'pending.idle'; context: Record<string, unknown> }
  | { value: 'pending.refresh'; context: Record<string, unknown> }
  | { value: 'pending.signIn'; context: { credentials: Credentials } }
  | { value: 'pending.signOut'; context: Record<string, unknown> }

type Action =
  | { type: 'SIGN_IN'; payload: Credentials }
  | { type: 'SIGN_IN.IN_PROCESS' }
  | { type: 'SIGN_IN.SUCCESS' }
  | { type: 'SIGN_IN.ERROR'; payload?: { isSSO?: boolean } }
  | { type: 'REFRESH' }
  | { type: 'REFRESH.SUCCESS'; payload: CurrentUser }
  | { type: 'REFRESH.ERROR' }
  | { type: 'START' }
  | { type: 'SIGN_OUT' }
  | { type: 'SIGN_OUT.SUCCESS' }
  | { type: 'SIGN_OUT.ERROR' }
  | { type: 'INVALIDATE' }

const getUserIsManager = (state: any) => {
  const { user } = state.context
  if (state.value === 'valid' && user.actor) {
    return user.actor.id && user.actor.permission?.role === 'manager'
  }
  return false
}

const getBorrowerAccounts = (state: any) => {
  const { user } = state.context
  if (state.value === 'valid') {
    return user?.managing || []
  }
  return []
}

const action = (type: string, payload?: unknown) =>
  ({ type, payload }) as Action

const reducer = (state: State, action: Action): State => {
  switch (state.value) {
    case 'invalid':
      switch (action.type) {
        case 'SIGN_IN':
          return {
            value: 'pending.signIn',
            context: { credentials: action.payload },
          }
      }
      break
    case 'pending.idle':
      switch (action.type) {
        case 'SIGN_IN':
          return {
            value: 'pending.signIn',
            context: { credentials: action.payload },
          }
        case 'START':
          return {
            value: 'pending.refresh',
            context: {},
          }
      }
      break
    case 'valid':
      switch (action.type) {
        case 'SIGN_IN':
          return {
            value: 'pending.signIn',
            context: { credentials: action.payload },
          }
        case 'REFRESH':
          return {
            value: 'pending.refresh',
            context: {},
          }
        case 'SIGN_OUT':
          return {
            value: 'pending.signOut',
            context: {},
          }
        case 'INVALIDATE':
          return {
            value: 'invalid',
            context: {},
          }
      }
      break
    case 'pending.signIn':
      switch (action.type) {
        case 'SIGN_IN.SUCCESS':
          return {
            value: 'pending.refresh',
            context: {},
          }
        case 'SIGN_IN.ERROR': {
          return {
            value: 'invalid',
            context: {
              errors: [
                action.payload?.isSSO
                  ? 'Could not find a user with that email address'
                  : 'The email or password is incorrect',
              ],
            },
          }
        }
      }
      break
    case 'pending.refresh':
      switch (action.type) {
        case 'REFRESH.SUCCESS':
          return {
            value: 'valid',
            context: { user: action.payload },
          }
        case 'REFRESH.ERROR':
          return {
            value: 'invalid',
            context: {},
          }
      }
      break
    case 'pending.signOut':
      switch (action.type) {
        case 'SIGN_OUT.SUCCESS':
          return {
            value: 'pending.refresh',
            context: {},
          }
        case 'SIGN_OUT.ERROR':
          return {
            value: 'pending.refresh',
            context: {},
          }
      }
      break
  }
  return { ...state }
}

export const useSessionManager = (
  defaultState: State = {
    value: 'pending.idle',
    context: {},
  }
) => {
  const [state, dispatch] = useReducer<typeof reducer>(reducer, defaultState)

  useEffect(() => {
    switch (state.value) {
      case 'pending.refresh':
        getCurrentUser()
          .then((user) => dispatch(action('REFRESH.SUCCESS', user)))
          .catch(() => dispatch(action('REFRESH.ERROR')))
        break
      case 'pending.signOut':
        endSession()
          .then(() => dispatch(action('SIGN_OUT.SUCCESS')))
          .catch(() => dispatch(action('SIGN_OUT.ERROR')))
        break
      case 'pending.signIn':
        createSession(state.context.credentials)
          .then(() => dispatch(action('SIGN_IN.SUCCESS')))
          .catch(() =>
            dispatch(
              action('SIGN_IN.ERROR', {
                isSSO: !!state.context.credentials.jwt,
              })
            )
          )
        break
    }
  }, [state.value])

  return {
    isLoading: state.value.includes('pending.'),
    isSigningIn: state.value.includes('pending.signIn'),
    isSignedIn: state.value === 'valid',
    isSignedOut: state.value === 'invalid',
    user: state.value === 'valid' ? state.context.user : null,
    isAdmin:
      state.value === 'valid' &&
      ['admin', 'member', 'individual'].includes(
        state.context.user.admin?.permission || ''
      ),
    isIndividual:
      state.value === 'valid' &&
      ['individual'].includes(state.context.user.admin?.permission || ''),
    isAdminManager:
      state.value === 'valid' &&
      ['admin'].includes(state.context.user.admin?.permission || ''),
    isManager: getUserIsManager(state),
    borrowerAccounts: getBorrowerAccounts(state),
    errors: state.value === 'invalid' ? state.context.errors : [],
    signIn: (credentials: Credentials) =>
      dispatch(action('SIGN_IN', credentials)),
    refreshUser: () => dispatch(action('REFRESH')),
    signOut: () => dispatch(action('SIGN_OUT')),
    invalidate: () => dispatch(action('INVALIDATE')),
    start: () => dispatch(action('START')),
  }
}

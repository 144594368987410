import clsx from 'clsx'
import {
  compact,
  isNull,
  isUndefined,
  maxBy,
  cloneDeep,
  toString,
  toNumber,
} from 'lodash'
import { Fragment, useEffect, useState } from 'react'
import { Button } from 'components/Button'
import formStyles from 'components/Form/styles.module.scss'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'
import { IconInput } from 'components/IconInput'
import { Input } from 'components/Input'
import { TextLink } from 'components/TextLink'
import { Class } from 'types'
import { Modal } from '../index'
import styles from './styles.module.scss'

interface ModalLoanTranchesProps {
  classes: Class[]
  onSave: (values: Partial<Class>[]) => Promise<unknown>
  onCancel: () => void
}

const validate = (type: 'currency' | 'percentage', value: string) => {
  switch (type) {
    case 'currency':
      if (parseFloat(value) < 0) {
        return 'Amount must be equal or greater than 0'
      }
      return null
    case 'percentage':
      if (parseFloat(value) < 0) {
        return 'Percentage must be equal or greater than 0'
      }
      if (parseFloat(value) >= 100) {
        return 'Percentage must be less than 100'
      }
      return null
  }
}

function ModalLoanTranches({
  classes,
  onSave,
  onCancel,
}: ModalLoanTranchesProps) {
  const [saving, setSaving] = useState(false)
  const [tranches, setTranches] = useState<Partial<Class>[]>(
    classes.length
      ? cloneDeep(classes)
      : [
          {
            name: '',
            editable: true,
            order: 1,
            amount: 0,
            rate: 0,
          },
        ]
  )
  const [hasError, setHasError] = useState(false)
  useEffect(() => {
    const errorTranches = tranches.filter(
      ({ amount, rate }) =>
        compact([
          validate('currency', toString(amount)),
          validate('percentage', toString(rate)),
        ]).length > 0
    )
    setHasError(errorTranches.length > 0)
  }, [tranches])

  const handleInputChange = (
    index: number,
    amount: null | string,
    rate: null | string,
    name?: string
  ) => {
    const tranche = tranches[index]
    if (!isNull(amount)) {
      tranche.amount = toNumber(amount)
    }
    if (!isNull(rate)) {
      tranche.rate = toNumber(rate)
    }
    if (!isUndefined(name)) {
      tranche.name = name
    }
    setTranches([...tranches])
  }

  const handleAddTranche = () => {
    const maxOrderField = maxBy(tranches, ({ order }) => order || 0)
    setTranches([
      ...tranches,
      {
        name: '',
        editable: true,
        order: maxOrderField?.order ? maxOrderField?.order + 1 : 1,
        amount: 0,
        rate: 0,
      },
    ])
  }

  const handleSaveClick = async () => {
    if (!hasError) {
      setSaving(true)
      await onSave(tranches)
      setSaving(false)
      onCancel()
    }
  }

  return (
    <Modal title="Classes" onClose={onCancel} className={styles.modal}>
      <div>
        <table className={styles.tranchesTable}>
          <thead>
            <tr>
              <th className={styles.header}>Name</th>
              <th
                className={clsx(
                  styles.header,
                  styles.amount,
                  styles.alignRight
                )}
              >
                Amount
              </th>
              <th
                className={clsx(styles.header, styles.rate, styles.alignRight)}
              >
                Rate
              </th>
            </tr>
          </thead>
          <tbody>
            {tranches.map(({ editable, name, amount, rate }, index) => {
              const errors = compact([
                validate('currency', toString(amount)),
                validate('percentage', toString(rate)),
              ])
              return (
                <Fragment key={index}>
                  <tr className={errors.length > 0 ? styles.rowWithError : ''}>
                    <td>
                      {editable ? (
                        <Input
                          className={styles.input}
                          type="text"
                          value={name}
                          onChange={(e: any) =>
                            handleInputChange(index, null, null, e.target.value)
                          }
                        />
                      ) : (
                        name
                      )}
                    </td>
                    <td>
                      <IconInput
                        fieldClassName={styles.input}
                        className={
                          validate('currency', toString(amount))
                            ? formStyles.errorField
                            : ''
                        }
                        type="currency"
                        name={name}
                        value={toString(amount)}
                        onChange={(e) =>
                          handleInputChange(index, e.target.value, null)
                        }
                      />
                    </td>
                    <td>
                      <IconInput
                        fieldClassName={styles.input}
                        className={
                          validate('percentage', toString(rate))
                            ? formStyles.errorField
                            : ''
                        }
                        type="percentage"
                        name={name}
                        value={toString(rate)}
                        onChange={(e) =>
                          handleInputChange(index, null, e.target.value)
                        }
                      />
                    </td>
                  </tr>
                  {errors.length > 0 && (
                    <tr>
                      <td className={styles.rowError} colSpan={3}>
                        {errors.map((error) => (
                          <div key={error}>{error}</div>
                        ))}
                      </td>
                    </tr>
                  )}
                </Fragment>
              )
            })}
          </tbody>
        </table>
        <div className={styles.addTrancheLink}>
          <TextLink onClick={() => handleAddTranche()}>
            <Icon name={IconName.plus} size="sm" />
            Add Class
          </TextLink>
        </div>
      </div>
      <Grid className={styles.form} columnGap={16}>
        <Grid.Item xs={12} className={styles.buttons}>
          <Button variant="tertiary" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" loading={saving} onClick={handleSaveClick}>
            Save
          </Button>
        </Grid.Item>
      </Grid>
    </Modal>
  )
}

export default ModalLoanTranches

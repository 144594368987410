import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { useClient } from 'hooks/use-client'
import { useSession } from 'hooks/use-session'
import { Quote } from 'types'
import { friendlyDateTime } from 'utils/date'
import { QuoteDetails } from './QuoteDetails'
import { QuoteHeader } from './QuoteHeader'
import { QuoteOfferedTerms } from './QuoteOfferedTerms'

interface Props {
  quote: Quote
  onLogoLoaded?: () => void
}

const QuoteTermSheetPage = ({ quote, onLogoLoaded }: Props) => {
  const { user } = useSession()
  const { data: client, isFetching } = useClient({
    enabled: !user,
  })
  const data = user?.client || client
  const footer = data?.settings?.quoteFooter
  const isProductHidden = data?.settings?.quoteShowProduct === 'No'

  return isFetching ? null : (
    <Flex
      stack
      gap={20}
      className="mx-auto my-0 max-w-print bg-white-100 px-4 pb-4 pt-0 sm:px-6 sm:pb-6"
    >
      <QuoteHeader quote={quote} onLogoLoaded={onLogoLoaded} />
      <QuoteDetails quote={quote} />
      <Flex stack gap={16} className="mt-10">
        <Header className="text-blue-200">Offered Terms</Header>
        {quote.options.map((option) => (
          <QuoteOfferedTerms
            key={option.id}
            option={option}
            isHeaderVisible={!isProductHidden}
          />
        ))}
      </Flex>
      <div className="mt-6">
        {footer && <p dangerouslySetInnerHTML={{ __html: footer }}></p>}
        <p>
          This document was produced on{' '}
          {friendlyDateTime(new Date().toString())}
        </p>
      </div>
    </Flex>
  )
}

export { QuoteTermSheetPage }

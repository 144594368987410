import { compact, get } from 'lodash'
import { useState } from 'react'
import { BorrowerDashboard } from 'borrower/components/BorrowerDashboard'
import { MainContent } from 'borrower/components/layout/MainContent'
import { ActiveTab } from 'borrower/pages/Loans/ActiveTab'
import { WidgetChartCurrency, WidgetMultiKPIs } from 'components/Dashboard'
import { Flex } from 'components/Flex'
import { PageTop } from 'components/PageTop'
import { useLoans } from 'hooks/use-loans'
import { useSession } from 'hooks/use-session'
import { InvestmentsWidget } from './InvestmentsWidget'
import styles from './styles.module.scss'

function Dashboard() {
  const [loaded, setLoaded] = useState(0)
  const { user } = useSession()
  const settings = get(user, 'client.settings', {
    autoInvestorAccounting: undefined,
  })
  const isInvestor = user?.actor?.isInvestor
  const personId = user?.actor?.id as string

  const { data } = useLoans({
    filter: { status: ['closed', 'servicing'] },
  })

  const showTotalCash = settings.autoInvestorAccounting !== 'Yes'

  // @todo rewrite it as user can be borrower and/or investor !isInvestor -> isBorrower
  // @todo the initial idea was to combine widgets depends on role

  if (!isInvestor) {
    return <BorrowerDashboard />
  } else {
    return (
      <MainContent>
        <Flex stack gap={24}>
          <PageTop title="Dashboard" hideTopMenu={true} />
          {(!isInvestor && data?.loans.length && <ActiveTab />) || undefined}
          {isInvestor && (
            <Flex stack gap={24}>
              <Flex gap={24} className={styles.kpisAndChart}>
                <WidgetMultiKPIs
                  kpis={compact([
                    {
                      id: `investor/${personId}/principal-invested-balance`,
                      type: 'currency',
                      title: 'Invested',
                    },
                    showTotalCash && {
                      id: `investor/${personId}/cash-balances`,
                      type: 'cash-balances',
                      title: 'Total Cash',
                    },
                    {
                      id: `investor/${personId}/investments-total`,
                      type: 'number',
                      title: 'Investments',
                    },
                    {
                      id: `investor/${personId}/portfolio-yield`,
                      type: 'percentage',
                      title: 'Portfolio Yield',
                    },
                  ])}
                  className={styles.multiKpi}
                  loaded={loaded}
                  onLoad={() => setLoaded((i) => i + 1)}
                />
                <WidgetChartCurrency
                  id={`investor/${user?.actor?.id as string}/portfolio-value`}
                  title="Portfolio Value"
                  valueGetter={({ portfolioValue }) => portfolioValue}
                  className={styles.portfolioValue}
                  onLoad={() => setLoaded((i) => i + 1)}
                />
              </Flex>
              <InvestmentsWidget
                investorId={user?.actor?.id as string}
                onLoad={() => setLoaded((i) => i + 1)}
              />
            </Flex>
          )}
        </Flex>
      </MainContent>
    )
  }
}

export { Dashboard }

import { useMutation } from '@tanstack/react-query'
import { flatten } from 'lodash'
import { useState } from 'react'
import { PanelEntityBanking } from 'admin/components/PanelEntityBanking'
import { useUpdateInvestorBanking } from 'admin/hooks/use-banking'
import { InvestorDetails, inviteEmail } from 'admin/services/api/investors'
import { Button } from 'components/Button'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'
import { ModalAddress } from 'components/Modal/Address'
import { ModalPersonInfo } from 'components/Modal/PersonInfo'
import { Panel } from 'components/Panel'
import PanelEmail from 'components/Panel/PanelEmail'
import PanelEntityInformation from 'components/Panel/PanelEntityInformation'
import PanelPersonalInformation from 'components/Panel/PanelPersonalInformation'
import { AddressSummary } from 'components/Summary'
import { TextLink } from 'components/TextLink'
import {
  useAddInvestorAddress,
  useUpdateInvestor,
  useUpdateInvestorAddress,
} from 'hooks/use-investor'
import { handleErrorResponse } from 'services/request'
import { Address } from 'types/address'
import { message } from 'utils/message'
import PanelLinkedAccounts from './PanelLinkedAccounts'
import styles from './styles.module.scss'

interface Props {
  investor: InvestorDetails
  onSecure: (secure: boolean) => void
}

const isAddressDefined = (address: Address): boolean => {
  return Boolean(
    address.street1 ||
      address.street2 ||
      address.city ||
      address.state ||
      address.zipcode ||
      address.country
  )
}

function TabGeneral({ investor, onSecure }: Props) {
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false)
  const [isAddressModalVisible, setIsAddressModalVisible] = useState(false)
  const [sentInvite, setSentInvite] = useState(false)
  const { mutate: updateInvestor, isPending: isUpdatingInvestor } =
    useUpdateInvestor()
  const { mutate: addAddress, isPending: addingAddress } =
    useAddInvestorAddress(investor.id)
  const { mutate: updateAddress, isPending: updatingAddress } =
    useUpdateInvestorAddress(investor.id)

  const inviteInvestor = useMutation({
    mutationFn: inviteEmail,
    onError: handleErrorResponse,
    onSuccess: () => {
      message.success('Invitation sent')
      setSentInvite(true)
    },
  })

  const saveAddresses = (addresses: Record<'primary' | 'mailing', Address>) => {
    const currentPrimary = investor.addresses.find(
      ({ type }) => type === 'primary'
    )
    const currentMailing = investor.addresses.find(
      ({ type }) => type === 'mailing'
    )
    const options = {
      onSuccess: () => {
        message.success('Address saved')
        setIsAddressModalVisible(false)
      },
    }

    if (currentPrimary) {
      updateAddress(
        {
          id: investor.id,
          addressId: currentPrimary.id,
          address: addresses.primary,
        },
        options
      )
    } else {
      addAddress(
        {
          id: investor.id,
          address: { ...addresses.primary, type: 'primary' },
        },
        options
      )
    }

    if (currentMailing) {
      updateAddress(
        {
          id: investor.id,
          addressId: currentMailing.id,
          address: addresses.mailing,
        },
        options
      )
    } else {
      addAddress(
        {
          id: investor.id,
          address: { ...addresses.mailing, type: 'mailing' },
        },
        options
      )
    }
  }

  const { mutate: updateBanking, isPending: savingBanking } =
    useUpdateInvestorBanking(investor.id)

  const addresses = investor.addresses
    .sort((a) => (a.type === 'primary' ? -1 : 1))
    .filter((address) => isAddressDefined(address))

  const isIndividual = !investor.type || investor.type === 'individual'

  return (
    <div className={styles.tabContent}>
      {isIndividual &&
        ['draft', 'invited', 'active'].includes(investor.status) && (
          <Button
            className={styles.tabButton}
            onClick={() => inviteInvestor.mutate({ id: investor.id })}
            loading={inviteInvestor.isPending}
            iconLeft={<Icon name={IconName.send} />}
          >
            {investor.status !== 'draft' || sentInvite
              ? 'Re-invite User'
              : 'Invite User'}
          </Button>
        )}

      <Grid gap={16}>
        <Grid.Item sm={12} md={6} className={styles.panels}>
          {isIndividual ? (
            <PanelPersonalInformation
              person={investor}
              onSecure={onSecure}
              onEdit={() => {
                onSecure(true)
                setIsInfoModalVisible(true)
              }}
              personType="investor"
            />
          ) : (
            <PanelEntityInformation
              person={investor}
              onEdit={() => {
                onSecure(true)
                setIsInfoModalVisible(true)
              }}
              onSecure={onSecure}
            />
          )}
          <Panel title="Address" onEdit={() => setIsAddressModalVisible(true)}>
            <Grid>
              {addresses.length ? (
                addresses.map((address) => (
                  <Grid.Item sm={6} key={address.id}>
                    <AddressSummary address={address} />
                  </Grid.Item>
                ))
              ) : (
                <Grid.Item sm={12}>
                  <div className={styles.link}>
                    <TextLink onClick={() => setIsAddressModalVisible(true)}>
                      <Icon name={IconName.plus} size="sm" />
                      Add Address
                    </TextLink>
                  </div>
                </Grid.Item>
              )}
            </Grid>
          </Panel>
          <PanelEntityBanking
            banking={investor.banking}
            title="Bank Account"
            updateBanking={updateBanking}
            isSaving={savingBanking}
          />
        </Grid.Item>
        <Grid.Item sm={12} md={6} className={styles.panels}>
          <PanelEmail
            id={investor.id}
            type={isIndividual ? 'individual' : 'entity'}
            emails={investor.emails}
            personType="investor"
            isInvited={['invited', 'active'].includes(investor.status)}
            readonlyEmails={flatten(
              investor.managers?.map((m) => m.emails || [])
            )}
          />

          <PanelLinkedAccounts investor={investor} />
        </Grid.Item>
        {isInfoModalVisible && (
          <ModalPersonInfo
            personType="investor"
            person={investor}
            saving={isUpdatingInvestor}
            onSave={(values) =>
              updateInvestor(
                { ...values, id: investor.id },
                {
                  onSuccess: () => {
                    message.success('Information saved')
                    setIsInfoModalVisible(false)
                  },
                }
              )
            }
            onCancel={() => setIsInfoModalVisible(false)}
          />
        )}
        {isAddressModalVisible && (
          <ModalAddress
            addresses={investor.addresses}
            saving={addingAddress || updatingAddress}
            onSave={(addresses) => saveAddresses(addresses)}
            onCancel={() => setIsAddressModalVisible(false)}
          />
        )}
      </Grid>
    </div>
  )
}

export default TabGeneral
